import React, { useRef, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./sellerForm.css";
import Swal from "sweetalert2";
import CategoriesSelector from "./categoriesSelector";
import "./categoriesSelector.css";
import { AuthContext } from "../../contexts";
import { registerSwal, loginSwal, sendEmail } from "../../../api/handleData";
import store from "../../redux/store.js";
import { fetchData } from "../../redux/dataActions.js";

function SellerForm() {
  const { user, setUser, token, setToken } = useContext(AuthContext);

  const shopLogoInputRef = useRef();
  const resetCategoriesFuncRef = useRef(null);

  const validationSchema = Yup.object().shape({
    shopName: Yup.string().required("Shop Name is required"),
    shopLogo: Yup.string().required("Shop Logo is required"),
    socialLinks: Yup.object()
      .shape({
        facebookLink: Yup.string().url("Invalid Facebook URL"),
        instagramLink: Yup.string().url("Invalid Instagram URL"),
        linkedinLink: Yup.string().url("Invalid LinkedIn URL"),
      })
      .test(
        "at-least-one-link",
        "At least one social media link is required",
        (value) => {
          return (
            value.facebookLink || value.instagramLink || value.linkedinLink
          );
        }
      ),
    catalogLink: Yup.string()
      .url("Invalid Catalog URL")
      .required("Catalog Link is required"),
    category: Yup.string().required("Category is required"),
    agreed: Yup.bool().oneOf(
      [true],
      "You must agree to the terms and conditions"
    ),
  });

  const formik = useFormik({
    initialValues: {
      shopName: "",
      shopLogo: "",
      socialLinks: {
        facebookLink: "",
        instagramLink: "",
        linkedinLink: "",
      },
      googleMapsAddress: "",
      catalogLink: "",
      category: "",
      agreed: false,
    },
    validationSchema: validationSchema,
    onSubmit: function(values) {
      if (!user) {
        Swal.fire({
          title: "Do you have an account with us?",
          allowOutsideClick: false,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
            denyButton: "order-3",
          },
        })
          .then((result) => {
            if (result.isConfirmed) {
              loginSwal(setUser, setToken);
            } else if (result.isDenied) {
              registerSwal(setUser, setToken);
            }
          })
          .finally(() => {
            formik.setSubmitting(false);
          });
        return;
      }

      const formData = new FormData();
      formData.append("name", values.shopName);
      formData.append("logo", values.shopLogo);

      const socials = {};
      if (values.socialLinks.facebookLink)
        socials.facebook = values.socialLinks.facebookLink;
      if (values.socialLinks.instagramLink)
        socials.instagram = values.socialLinks.instagramLink;
      if (values.socialLinks.linkedinLink)
        socials.linkedin = values.socialLinks.linkedinLink;
      formData.append("socials", JSON.stringify(socials));

      formData.append("location", values.googleMapsAddress || "NOT SET");
      formData.append("website", values.catalogLink);
      formData.append("categoryLink", values.catalogLink);
      formData.append("category", values.category);
      formData.append("posted_by", user.id);

      axios
        .post(
          `${process.env.REACT_APP_BACKEND}/api/become-a-seller`,
          formData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Visit your dashboard to complete your business profile",
          });

          formik.resetForm();
          shopLogoInputRef.current.value = "";
          if (resetCategoriesFuncRef.current) resetCategoriesFuncRef.current();

          const { dispatch } = store;
          dispatch(fetchData("sellers"));

          sendEmail(user.first_name, user.phone, "Store Application", "");
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          Swal.fire({
            icon: "error",
            title: "An error occured",
            text: "Please try again later",
          });
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <div className="col-12">
      <p style={{ display: "block" }}>&#9432; * means its a required field</p>

      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label htmlFor="shopName">Your shop name*</label>
          <input
            type="text"
            className="form-control sellerFormInput"
            id="shopName"
            name="shopName"
            placeholder="Enter the name to appear on shop listing"
            maxLength={50}
            value={formik.values.shopName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.shopName && formik.errors.shopName && (
            <div className="sellerFormError">{formik.errors.shopName}</div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="shopLogo">
            Upload transparent shop logo*
            <small style={{ display: "block" }}>
              &#9432; Accepted file types: .png, .jpg, .jpeg, .webp
            </small>
            <small style={{ display: "block" }}>
              &#9432; Resolution: 720px
            </small>
          </label>
          <input
            type="file"
            className="form-control sellerFormInput"
            id="shopLogo"
            name="shopLogo"
            ref={shopLogoInputRef}
            accept=".png, .jpeg, .jpg, .webp"
            onChange={(event) => {
              formik.setFieldValue("shopLogo", event.currentTarget.files[0]);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.shopLogo && formik.errors.shopLogo && (
            <div className="sellerFormError">{formik.errors.shopLogo}</div>
          )}
        </div>

        <div className="form-group">
          <label>
            Enter your social media links*
            <small style={{ display: "block" }}>
              &#9432; At least one must be entered
            </small>
          </label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-facebook"></i>
              </span>
            </div>
            <input
              type="url"
              className="form-control sellerFormInput"
              id="facebookLink"
              name="socialLinks.facebookLink"
              placeholder="Facebook link"
              value={formik.values.socialLinks.facebookLink}
              onChange={(event) => {
                formik.handleChange(event);
                formik.setFieldValue(
                  "socialLinks.facebookLink",
                  event.target.value
                );
              }}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="input-group mt-2">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-instagram"></i>
              </span>
            </div>
            <input
              type="url"
              className="form-control sellerFormInput"
              id="instagramLink"
              name="socialLinks.instagramLink"
              placeholder="Instagram link"
              value={formik.values.socialLinks.instagramLink}
              onChange={(event) => {
                formik.handleChange(event);
                formik.setFieldValue(
                  "socialLinks.instagramLink",
                  event.target.value
                );
              }}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="input-group mt-2">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-linkedin"></i>
              </span>
            </div>
            <input
              type="url"
              className="form-control sellerFormInput"
              id="linkedinLink"
              name="socialLinks.linkedinLink"
              placeholder="LinkedIn link"
              value={formik.values.socialLinks.linkedinLink}
              onChange={(event) => {
                formik.handleChange(event);
                formik.setFieldValue(
                  "socialLinks.linkedinLink",
                  event.target.value
                );
              }}
              onBlur={formik.handleBlur}
            />
          </div>

          {formik.touched.socialLinks &&
            formik.errors.socialLinks &&
            (formik.errors.socialLinks.facebookLink ||
              formik.errors.socialLinks.instagramLink ||
              formik.errors.socialLinks.linkedinLink) && (
              <div className="sellerFormError mt-2">
                {formik.errors.socialLinks.facebookLink ||
                  formik.errors.socialLinks.instagramLink ||
                  formik.errors.socialLinks.linkedinLink}
              </div>
            )}
          {formik.touched.socialLinks &&
            !formik.values.socialLinks.facebookLink &&
            !formik.values.socialLinks.instagramLink &&
            !formik.values.socialLinks.linkedinLink && (
              <div className="sellerFormError mt-2">
                At least one social media link is required
              </div>
            )}
        </div>

        <div className="form-group">
          <label htmlFor="googleMapsAddress">Google Maps address</label>
          <input
            type="text"
            className="form-control sellerFormInput"
            id="googleMapsAddress"
            name="googleMapsAddress"
            placeholder="Enter your shop address or a landmark near you"
            value={formik.values.googleMapsAddress}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.googleMapsAddress &&
            formik.errors.googleMapsAddress && (
              <div className="sellerFormError">
                {formik.errors.googleMapsAddress}
              </div>
            )}
        </div>

        <div className="form-group">
          <label htmlFor="catalogLink">Catalog link*</label>
          <input
            type="url"
            className="form-control sellerFormInput"
            id="catalogLink"
            name="catalogLink"
            placeholder="Enter whatsapp or website link"
            value={formik.values.catalogLink}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.catalogLink && formik.errors.catalogLink && (
            <div className="sellerFormError">{formik.errors.catalogLink}</div>
          )}
        </div>

        <CategoriesSelector
          formik={formik}
          resetCategoriesFuncRef={resetCategoriesFuncRef}
        />

        <div className="form-group">
          <input
            type="checkbox"
            className="sellerFormCheckbox"
            id="agreed"
            name="agreed"
            checked={formik.values.agreed}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <label style={{ margin: 0 }} htmlFor="agreed">
            I agree to <i>Minkmall's</i> terms of service and privacy policy*
          </label>

          {formik.touched.agreed && formik.errors.agreed && (
            <div className="sellerFormError">{formik.errors.agreed}</div>
          )}
        </div>

        <div
          style={{
            marginTop: "1em",
            display: "flex",
            columnGap: "1em",
            alignItems: "center",
          }}
        >
          <button
            type="submit"
            className="btn btn-solid"
            disabled={formik.isSubmitting || !formik.values.agreed}
          >
            Submit
          </button>
          {formik.isSubmitting && <div className="minkmall-spinner-loader" />}
        </div>
      </form>
    </div>
  );
}

export default SellerForm;
