import React, { useEffect, useState, useContext } from "react";
import {
  getAllStores,
  getProductsByUserId,
  getStoreCategory,
} from "../../api/handleData";
import axios from "axios";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { uploadFile, uploadGallery } from "../../api/uploadFiles";
import Select from "react-select";
import { connect } from "react-redux";
import { prdSchema } from "../../api/fromSchemas";
import { error } from "../../api/toast";
import { AuthContext } from "../contexts";

const UploadProduct = ({ categories }) => {
  const [images, setImages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [sale, setSale] = useState(false);
  const [sellers, setSellers] = useState(null);
  const [categor, setCategories] = useState([]);
  const [selected, setSelected] = useState(0);
  const [myprods, setMyProds] = useState(null);
  const navigate = useHistory();
  const { user, token } = useContext(AuthContext);

  const handleBox = (e) => {
    setCheck(!check);
  };
  const handleNew = (e) => {
    setSale(!sale);
  };

  //var myShop = [];
  if (sellers && user) {
    var myShop = sellers.filter((sl) => sl.posted_by == user.id);
    //myShop.push(selVar);
    //console.log("WHATS MYSHOP:", selVar[0])
  }

  const submitProduct = async (e) => {
    //e.preventDefault()

    let prodForm = new FormData();

    if (myprods.length >= 5) {
      swal(
        "Error",
        "Oops sorry you can only upload 5 products with free version, please upgrade to add more",
        "error"
      );
    } else if (!images) {
      swal("Error", "Please upload your product images", "error");
    } else if (!categor) {
      swal("Error", "Please select a product category", "error");
    } else {
      prodForm.append("name", e.name);
      prodForm.append("category", selected ? selected : null);

      const imageSrcs = images ? images : null;

      for (let i = 0; i < imageSrcs.length; i++)
        prodForm.append("imageSrcs[]", imageSrcs[i]);

      //prodForm.append('color', e.target.color.value);
      prodForm.append("seller", myShop.length > 0 ? myShop[0].id : "");
      prodForm.append("price", e.price);
      prodForm.append("sale", check);
      prodForm.append("new", sale);
      prodForm.append("category", selected);
      prodForm.append("shortDetails", e.shortDetails);
      prodForm.append("link", e.link);
      setLoading(true);
      console.log("FORM SENTING:", e, "CATEG:", selected);

      await axios
        .post(`${process.env.REACT_APP_BACKEND}/api/products`, prodForm, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data === "DONE") {
            swal("Success", "Product uploaded successfully", "success");
            setLoading(false);
            navigate.push("/admin/dashboard");
            e.name = "";
            e.price = "";
            e.shortDetails = "";
            e.link = "";
            setImages(null);
            setCheck(false);
            setSale(false);
            setCategories([]);
          } else if (res.data === "NO PICS") {
            swal("Error", "Please Upload Product Images");
            setLoading(false);
          } else {
            setLoading(false);
            error("Oops an error occured");
            console.log("OTHER ERROR:", res.data);
          }
        })
        .catch((err) => {
          swal("Error", "Internal Server Error", "error");
          console.log(err);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getAllStores(setSellers);
    //get categories
    getProductsByUserId(setMyProds, user.id);
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     getStoreCategory(setCategories, user)
  //   }
  // }, [categories.length])

  useEffect(() => {
    if (user) {
      getStoreCategory(setCategories, user.id);
    }
  }, [user]);
  // const callit = () => {
  //   if (user) {
  //     getStoreCategory(setCategories, user)
  //   }
  // }

  //console.log("DATA:", myShop)
  return (
    <div>
      <section className="section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard-left">
                <div className="collection-mobile-back">
                  <span className="filter-back">
                    <i className="" aria-hidden="true"></i> back
                  </span>
                </div>
                <div className="block-content">
                  <div className="theme-card">
                    <Formik
                      initialValues={{
                        name: "",
                        price: "",
                        link: "",
                        shortDetails: "",
                      }}
                      onSubmit={submitProduct}
                      validationSchema={prdSchema}
                      validateOnChange
                    >
                      <Form className="theme-form">
                        <div className="form-row">
                          <div className="col-md-6">
                            <label htmlFor="fname"> Product Name</label>
                            <ErrorMessage
                              className="validator"
                              name="name"
                              component="div"
                            />
                            <Field
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <i
                              className="icon-image"
                              onClick={() =>
                                uploadGallery(
                                  setImages,
                                  "Upload product images"
                                )
                              }
                            >
                              {images ? (
                                "IMAGES UPLOADED"
                              ) : (
                                <h4 className="validator">
                                  Upload Product Images
                                </h4>
                              )}
                            </i>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="instagram">Product Price</label>
                            <ErrorMessage
                              className="validator"
                              name="price"
                              component="div"
                            />
                            <Field
                              type="number"
                              className="form-control"
                              name="price"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="instagram">Product Category</label>
                            <select
                              name="categories"
                              onChange={(e) => setSelected(e.target.value[0])}
                            >
                              <option>Click to Choose Your Category</option>
                              {categories
                                .filter((cat) =>
                                  categor.some((categor2) => categor2 == cat.id)
                                )
                                .map((category, idx) => (
                                  <option key={idx} value={category.id}>
                                    {category.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-md-6">
                            <h4>Choose if the product is on sale </h4>
                            <input
                              type="checkbox"
                              id="sale"
                              name="sale"
                              checked={check}
                              onChange={handleBox}
                            />
                            <label htmlFor="review">{`${
                              check ? "ON SALE" : "NOT ON SALE"
                            }`}</label>
                          </div>
                          <div className="col-md-6">
                            <h4>Choose if the product is new or used </h4>
                            <input
                              type="checkbox"
                              id="new"
                              name="new"
                              checked={sale}
                              onChange={handleNew}
                            />
                            <label htmlFor="review">{`${
                              sale ? "NEW" : "USED"
                            }`}</label>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="stock">
                              Product Link on your website / Social Media
                            </label>
                            <ErrorMessage
                              className="validator"
                              name="link"
                              component="div"
                            />
                            <Field
                              type="url"
                              className="form-control"
                              name="link"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="email">Short Description</label>
                            <ErrorMessage
                              className="validator"
                              name="shortDetails"
                              component="div"
                            />
                            <Field
                              type="text"
                              className="form-control"
                              id="shortDetails"
                              name="shortDetails"
                            />
                          </div>
                        </div>

                        {loading ? (
                          <h3>Sending...</h3>
                        ) : (
                          <button type="submit" className="btn btn-solid mt-3">
                            Send For Approval
                          </button>
                        )}
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default connect((state) => ({ categories: state.data.categories }))(
  UploadProduct
);
