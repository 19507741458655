import React, { useContext, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import {
  getAllProducts,
  getProductsByUserId,
  logout,
} from "../../../api/handleData";
import UploadProduct from "../../pages/UploadProduct";
// import Tab from '../components/Tab';
// import TabPanel from '../components/TabsPanel';
import SingleProduct from "./SingleProduct";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { AuthContext } from "../../contexts";

const Supplier = ({ stores }) => {
  const [prods, setProds] = useState();
  const [selectedRows, setSelectedRows] = useState(false);

  const { user, setUser, setToken } = useContext(AuthContext);
  const navigate = useHistory();

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  //for shop
  if (selectedRows.length === 1) {
    swal({
      title: "Confirmation",
      text: "Do you wish to edit your shop?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willEdit) => {
      if (willEdit) {
        const usrID = Object.assign({}, selectedRows);
        const theID = usrID[0].id.toString();
        navigate.push(`/stores/${theID}`);
      } else {
        console.log("Cancelled");
      }
    });
  }
  //for product
  //console.log("SELECTED:", selected)

  const seller = [
    {
      name: "My Shop Name",
      selector: (row) => row.name,
    },
    {
      name: "My Shop Phone",
      selector: (row) => row.telephone,
    },
    {
      name: "Shop Email",
      selector: (row) => row.email,
    },
    {
      name: "Shop Location",
      selector: (row) => row.location,
    },
  ];

  let myShop = [];
  if (stores) myShop = stores.filter((st) => st.posted_by == user.id);
  //console.log("STORESSSSSS:", myShop)

  useEffect(() => {
    getProductsByUserId(setProds, user.id);
  }, []);

  return (
    <Tabs
      onSelect={(index, label) => console.log(label + "selected")}
      selected={1}
    >
      <Tab label="Account Info">
        <div>
          <div className="dashboard-right">
            <div className="dashboard">
              <div className="page-title">
                <h2>My Dashboard</h2>
              </div>
              <div className="welcome-msg">
                <p>Hello, {user ? user.first_name : "Loading.."}</p>
                <p>
                  From your My Account Dashboard you have the ability to view a
                  snapshot of your recent account activity and update your
                  account information. Select a link below to view or edit
                  information.
                </p>
              </div>
              <div className="box-account box-info">
                <div className="box-head">
                  <h2>Account Information</h2>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="box">
                      <div className="box-title">
                        <h3>Contact Information</h3>
                        <Link to={`/users/${user.id}`}>Edit</Link>
                      </div>
                      <div className="box-content">
                        <h6>
                          {user
                            ? user.first_name + " " + user.last_name
                            : "Loading.."}
                        </h6>
                        <h6>{user ? user.email : "Loading.."}</h6>
                        <h6 className="validator">
                          {user ? user.phone : "Loading.."}
                        </h6>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                    <div className="box">
                      <div className="box-title">
                        <h3>Profile Picture</h3>
                        <Link to={`/users/${user.id}`}>Edit</Link>
                      </div>
                      <div className="box-content">
                        <img src={user ? user.profile_image : ""} />
                      </div>
                    </div>
                  </div> */}
                </div>
                <div>
                  {/* <div className="box">
                    <div className="box-title">
                      <h3>Reset Password</h3>
                      <Link to="#">My Addresses</Link>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <h6>Change Your Password</h6>
                        <address>
                          <Link to="#">Edit Password</Link>
                        </address>
                      </div>
                      <div className="col-sm-6">
                        <h6>My Address</h6>
                        <address>
                          <br />
                          <Link to="#">Edit Address</Link>
                        </address>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tab>
      {myShop.length !== 0 ? (
        <Tab label="My Shops">
          <div>
            <div className="dashboard-right">
              <div className="dashboard">
                <div className="page-title">
                  <h2>My Shop</h2>
                </div>
                <div className="welcome-msg">
                  <p> {myShop.length !== 0 ? myShop.name : "Loading..."}</p>
                  <p>
                    From this Dashboard you have the ability to view a snapshot
                    of your shop information. Select a link below to view or
                    edit information.
                  </p>
                </div>
                <DataTable
                  direction="auto"
                  columns={seller}
                  data={myShop}
                  onSelectedRowsChange={handleChange}
                  fixedHeaderScrollHeight="300px"
                  pagination
                  selectableRows
                  responsive
                  subHeaderAlign="right"
                  subHeaderWrap
                />
              </div>
            </div>
          </div>
        </Tab>
      ) : (
        <Tab label="No Shop Registered">
          <h4>
            Sorry there is no Shop Registered on this account. Contact us for
            support.
          </h4>
        </Tab>
      )}

      {/* <Tab label="My Subscriptions">
        <h3>You are not yet subscribed</h3>
      </Tab> */}
      <Tab label="My Products">
        {myShop[0] && myShop[0].id ? (
          <SingleProduct userID={myShop[0].id} />
        ) : (
          ""
        )}
      </Tab>
      <Tab label="Upload Product">
        <UploadProduct />
      </Tab>
      <Tab label="Logout">
        <div>
          <button
            onClick={() => logout(navigate, setUser, setToken)}
            className="btn btn-solid"
          >
            Press to Logout
          </button>
        </div>
      </Tab>
    </Tabs>
  );
};

export default Supplier;
