import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { getAllProducts } from "../../api/handleData";
import swal from "sweetalert";
import { useHistory, useParams, Link } from "react-router-dom";
import { Field, Form, Formik, ErrorMessage, useField } from "formik";
import Thumb from "./components/Thumb";
import { AuthContext } from "../contexts";

const EditProduct = () => {
  const [products, setProducts] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useHistory();
  //get all products
  const { id } = useParams();

  if (products)
    var singleProduct = products.find((pr) => pr.id.toString() === id);

  const deleteProduct = async () => {
    swal({
      title: "Confirmation",
      text: "Are you sure? This action is permanent",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let del = await axios.delete(
          `${process.env.REACT_APP_BACKEND}/api/products/${id}`
        );
        if (del.data == "DEL") {
          swal("Success", "Deleted Successfully", "success");
        }
        navigate.push("/admin/dashboard");
      } else {
        console.log("Cancelled");
      }
    });
  };

  //submit the form
  const submitForm = async (form) => {
    //set loader
    setLoading(true);
    //send to backend
    await axios
      .put(`${process.env.REACT_APP_BACKEND}/api/products/${id}`, form)
      .then((res) => {
        if (res.data == "Product Updated") {
          swal("Success", "Product updated Successfully", "success");
        } else {
          swal("Error", "Oops an error occured updating the product", "error");
        }
        //console.log("EDIT SELL RES:", res)
      })
      .catch((err) => {
        console.log("EDIT PRD ERR:", err);
      });
    //update the user too
    // await axios.put(`${process.env.REACT_APP_BACKEND}/users/${user.id}`, usr, { headers: { Authorization: `Bearer ${details.token}` } })
    //   .then(res => {
    //     console.log("RESULTTT:", res)
    //   })
    //   .catch(err => {
    //     console.log("ERROR EDIT:", err)
    //   })
    //console.log("EDIT FORM:", form);

    setLoading(false);
  };

  useEffect(() => {
    getAllProducts(setProducts);
  }, []);

  return singleProduct ? (
    <section className="register-page section-b-space">
      <div className="container">
        <Link to="/admin/dashboard">
          {" "}
          <i className="icon-back-left" /> Back to Dashboard
        </Link>
        <div className="row">
          <div className="col-lg-12">
            <div className="md-20" />
            <h3>Edit Product Details</h3>
            <button
              onClick={() => deleteProduct()}
              className="btn btn-solid"
              type="submit"
            >
              Delete This Product
            </button>
            <div className="theme-card">
              <Formik
                initialValues={{
                  name: singleProduct.name,
                  color: singleProduct.color,
                  category: singleProduct.category,
                  price: singleProduct.price,
                  link: singleProduct.link,
                  quantity: singleProduct.quantity,
                  is_featured: singleProduct.is_featured,
                  new: singleProduct.new,
                  sale: singleProduct.sale,
                  shortDetails: singleProduct.shortDetails,
                }}
                onSubmit={submitForm}
                validateOnChange
              >
                {({ values }) => (
                  <Form className="theme-form">
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="business_name">Product Name</label>
                        <ErrorMessage
                          className="validator"
                          name="name"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      {user && user.is_admin ? (
                        <div className="col-md-6">
                          <h4>Click to Set As Featured </h4>
                          <Field
                            type="checkbox"
                            id="is_featured"
                            name="is_featured"
                          />
                          <label htmlFor="review">{`${
                            values.is_featured ? "FEATURED" : "NOT FEATURED"
                          }`}</label>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="col-md-6">
                        <label htmlFor="review">Category</label>
                        <ErrorMessage
                          className="validator"
                          name="category"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="category"
                          name="category"
                        />
                      </div>
                      {/* <div className="col-md-6">
                        <label htmlFor="review">Color</label>
                        <ErrorMessage
                          className="validator"
                          name="color"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="color"
                          name="color"
                        />
                      </div> */}
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <h4>Click to Set As New Or Used </h4>
                        <Field type="checkbox" id="new" name="new" />
                        <label htmlFor="review">{`${
                          values.new ? "NEW" : "USED"
                        }`}</label>
                      </div>
                      <div className="col-md-6">
                        <h4>Product on Sale? </h4>
                        <Field type="checkbox" id="sale" name="sale" />
                        <label htmlFor="review">{`${
                          values.sale ? "SALE" : "NOT ON SALE"
                        }`}</label>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="email">Price</label>
                        <ErrorMessage
                          className="validator"
                          name="price"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="price"
                          name="price"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="review">Product Link</label>
                        <ErrorMessage
                          className="validator"
                          name="link"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="link"
                          name="link"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="review">Short Details</label>
                        <ErrorMessage
                          className="validator"
                          name="shortDetails"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="shortDetails"
                          value=""
                          name="shortDetails"
                        />
                      </div>
                      <div>
                        {loading ? (
                          <h3>Editing...</h3>
                        ) : (
                          <button className="btn btn-solid" type="submit">
                            Edit
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div>
      <h4>Loading....</h4>
    </div>
  );
};

export default EditProduct;
