import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./sellerCardSlider.css";

export default function SellerCardSlider(props) {
  const { imageSrcs, showDots, imageMaxHeight } = props;
  const settings = {
    dots: showDots,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <Slider {...settings}>
      {imageSrcs.map((imageSrc, idx) => (
        <img
          key={idx}
          className="img-fluid"
          src={`${process.env.PUBLIC_URL}${imageSrc}`}
          alt="product"
          style={imageMaxHeight ? { maxHeight: imageMaxHeight } : null}
        />
      ))}
    </Slider>
  );
}
