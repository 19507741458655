import React, { useState } from "react";
import SellerCard from "../components/sellerCard";

export default function Sellers(props) {
  const { sellersData, category, increaseBy } = props;
  const [limit, setLimit] = useState(increaseBy);

  const handleLoadMore = () => {
    setLimit((prevLimit) =>
      Math.min(prevLimit + increaseBy, sellersData.length)
    );
  };

  return sellersData.length > 0 ? (
    <section className="section-b-space">
      <div className="container">
        <div
          className="row"
          style={{
            justifyContent:
              sellersData.length === 1 ? "space-between" : "space-evenly",
            rowGap: "3em",
            paddingLeft: "2em",
            paddingRight: "2em",
          }}
        >
          {sellersData.slice(0, limit).map((sellerData, idx) => (
            <SellerCard key={idx} category={category} {...sellerData} />
          ))}
          {limit < sellersData.length && (
            <div className="col-12" style={{ textAlign: "center" }}>
              {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
              <a className="btn btn-solid" onClick={handleLoadMore}>
                <i
                  className="fa fa-refresh"
                  aria-hidden="true"
                  style={{ marginRight: "2ch" }}
                ></i>
                load more
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  ) : (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="error-section">
            <h2>please check back later</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
