import React from "react";
import Breadcrumb from "../components/breadcrumb";
import { useParams, Redirect } from "react-router-dom";
import CategoryBanners from "../components/categoryBanners";
import Sellers from "../components/sellers";
import {
  getAssociatedSellersByName,
  buildSubCategoryLinksByName,
  buildCategoryLinksByName,
} from "../utils/extract.js";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

function sentenceCase(str) {
  if (typeof str !== "string" || str.length === 0) return "";

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function Category(props) {
  const { data } = props;
  let { mainCategory, subCategory, category } = useParams();

  mainCategory = mainCategory && decodeURIComponent(mainCategory);
  subCategory = subCategory && decodeURIComponent(subCategory);
  category = category && decodeURIComponent(category);

  const title = category ? category : subCategory ? subCategory : mainCategory,
    parent = category
      ? `${mainCategory}/${subCategory}`
      : subCategory
      ? mainCategory
      : "",
    helmetTitle = category
      ? `${sentenceCase(mainCategory)} / ${sentenceCase(
          subCategory
        )} / ${sentenceCase(category)}`
      : subCategory
      ? `${sentenceCase(mainCategory)} / ${sentenceCase(subCategory)}`
      : sentenceCase(mainCategory);

  let bannersData, sellersData;

  if (category) {
    sellersData = getAssociatedSellersByName(
      category,
      subCategory,
      mainCategory,
      data.mainCategories,
      data.subCategories,
      data.categories,
      data.sellers
    ).filter((seller) => seller.is_approved);
    if (!sellersData) return <Redirect to="/pages/404" />;
  } else if (subCategory) {
    bannersData = buildCategoryLinksByName(
      subCategory,
      mainCategory,
      data.categories,
      data.subCategories,
      data.mainCategories
    );
    if (!bannersData) return <Redirect to="/pages/404" />;
  } else if (mainCategory) {
    bannersData = buildSubCategoryLinksByName(
      mainCategory,
      data.subCategories,
      data.mainCategories
    );
    if (!bannersData) return <Redirect to="/pages/404" />;
  }

  return (
    <div>
      <Helmet>
        <title>{`Minkall - ${helmetTitle}`}</title>
      </Helmet>

      <Breadcrumb title={title} parent={parent} categoryPage />

      {category ? (
        <Sellers category={category} sellersData={sellersData} increaseBy={6} />
      ) : (
        <section className="banner-furniture ratio_45 section-b-space">
          <CategoryBanners bannersData={bannersData} increaseBy={12} />
        </section>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps)(Category);
