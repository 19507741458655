import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import { useMediaQuery } from "react-responsive";
import { getSeller } from "../utils/extract";
import { connect } from "react-redux";
import SellerCardSlider from "./sellerCardSlider";

function ProductItem(props) {
  const { product, seller, search } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const isTablet = useMediaQuery({ maxWidth: 768 });

  const onOpenModal = () => {
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="product-box">
      <div className="img-wrapper">
        <div className="lable-block">
          {product.new && <span className="lable3">new</span>}
          {product.sale && <span className="lable4">on sale</span>}
        </div>
        <div
          style={{ aspectRatio: "2/3", cursor: "pointer" }}
          onClick={onOpenModal}
          title="View Product"
        >
          <img src={product.imageSrcs[0]} className="img-fluid" alt="" />
        </div>
      </div>
      <div className="product-detail">
        <div>
          <h6 style={search ? { textTransform: "capitalize" } : null}>
            {product.name}
          </h6>
          <h4>{`Ksh ${product.price}`}</h4>
        </div>
      </div>
      <Modal open={modalOpen} onClose={onCloseModal} center>
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
          style={useMediaQuery({ maxWidth: 575 }) ? { maxWidth: "80vw" } : null}
        >
          <div className="modal-content quick-view-modal">
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6  col-xs-12 sellerCardSliderParent">
                  <SellerCardSlider
                    imageSrcs={product.imageSrcs}
                    showDots={useMediaQuery({ maxWidth: 575 })}
                  />
                </div>
                <div
                  className="col-lg-6 rtl-text"
                  style={
                    useMediaQuery({ maxWidth: 991 })
                      ? { marginTop: "2em" }
                      : null
                  }
                >
                  <div className="product-right">
                    <img
                      style={{ maxHeight: "5em", marginBottom: "0.25em" }}
                      src={`${process.env.PUBLIC_URL}${seller.logoImageSrc}`}
                      alt="seller logo"
                    />
                    <h3>{seller.name}</h3>
                    <h4> {product.name} </h4>
                    <h5>{`Ksh ${product.price}`}</h5>

                    <div className="border-product">
                      <h6 className="product-title">product details</h6>
                      <p>{product.shortDetails}</p>
                    </div>

                    <div className="border-product">
                      <h6 className="product-title">Seller social media</h6>
                      <div className="product-icon">
                        <ul className="product-social">
                          {Object.keys(seller.socials).map(
                            (socialMedia, idx) => (
                              <li key={idx}>
                                <a
                                  href={seller.socials[socialMedia]}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i
                                    style={{ fontSize: "2em" }}
                                    className={`fa fa-${socialMedia}`}
                                  />
                                </a>
                              </li>
                            )
                          )}
                          <li>
                            <a
                              href={seller.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                style={{ fontSize: "2em" }}
                                className={"fa fa-globe"}
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="border-product">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: "1em",
                          justifyContent: isTablet ? "center" : "normal",
                        }}
                      >
                        <a
                          style={{
                            maxWidth: "fit-content",
                            padding: ".75em",
                            fontSize: ".75rem",
                          }}
                          href={product.link}
                          className="btn btn-solid"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Shop now
                        </a>
                        <Link
                          style={{
                            maxWidth: "fit-content",
                            padding: ".75em",
                            fontSize: ".75rem",
                          }}
                          to={`/sellers/${seller.id}`}
                          className="btn btn-solid"
                        >
                          Visit Seller Profile
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const seller = getSeller(ownProps.product.seller, state.data.sellers);
  return {
    seller,
  };
};

export default connect(mapStateToProps)(ProductItem);
