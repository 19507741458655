export function buildMainCategoriesTrees(
  mainCategoriesArray,
  subCategoriesArray,
  categoriesArray
) {
  return mainCategoriesArray.map((mainCategory) => {
    const associatedSubCategories = getAssociatedSubCategories(
      mainCategory.id,
      subCategoriesArray
    ).map((subCategory) => {
      const associatedCategories = buildCategoryLinks(
        subCategory.id,
        categoriesArray,
        subCategoriesArray,
        mainCategoriesArray
      );

      return {
        ...subCategory,
        builtCategories: associatedCategories,
        link: `/c/${encodeURIComponent(
          mainCategory.name.toLowerCase()
        )}/${encodeURIComponent(subCategory.name.toLowerCase())}`,
      };
    });

    return {
      ...mainCategory,
      builtSubCategories: associatedSubCategories,
      link: `/c/${encodeURIComponent(mainCategory.name.toLowerCase())}`,
    };
  });
}

export function buildMainCategoryTreeByName(
  mainCategoryName,
  mainCategoriesArray,
  subCategoriesArray,
  categoriesArray
) {
  const mainCategory = mainCategoriesArray.find(
    (mainCategory) =>
      mainCategory.name.toLowerCase() == mainCategoryName.toLowerCase()
  );

  if (!mainCategory) return null;

  const associatedSubCategories = getAssociatedSubCategories(
    mainCategory.id,
    subCategoriesArray
  ).map((subCategory) => {
    const associatedCategories = buildCategoryLinks(
      subCategory.id,
      categoriesArray,
      subCategoriesArray,
      mainCategoriesArray
    );

    return {
      ...subCategory,
      builtCategories: associatedCategories,
    };
  });

  return {
    ...mainCategory,
    builtSubCategories: associatedSubCategories,
    link: `/c/${encodeURIComponent(mainCategory.name.toLowerCase())}`,
  };
}

export function buildSellerCategoriesNamesAndLinks(
  sellerCategoriesArray,
  categoriesArray,
  subCategoriesArray,
  mainCategoriesArray
) {
  return sellerCategoriesArray.map((sellerCategory) => {
    const category = categoriesArray.find(
      (category) => category.id == sellerCategory.category
    );

    if (!category) {
      return null;
    }

    const subCategory = subCategoriesArray.find(
      (subCategory) => subCategory.id == category.subCategory
    );

    if (!subCategory) {
      return null;
    }

    const mainCategory = mainCategoriesArray.find(
      (mainCategory) => mainCategory.id == subCategory.mainCategory
    );

    if (!mainCategory) {
      return null;
    }

    const mainCategoryLink = `/c/${encodeURIComponent(
      mainCategory.name.toLowerCase()
    )}`;
    const subCategoryLink = `${mainCategoryLink}/${encodeURIComponent(
      subCategory.name.toLowerCase()
    )}`;
    const categoryLink = `${subCategoryLink}/${encodeURI(
      category.name.toLowerCase()
    )}`;

    return { ...sellerCategory, categoryName: category.name, categoryLink };
  });
}

export function getSellerProducts(sellerId, productsArray) {
  return productsArray.filter((product) => product.seller == sellerId);
}

export function getFeaturedProducts(productsArray) {
  return productsArray.filter((product) => product.is_featured == true);
}

export function getFeaturedSellers(sellersArray) {
  return sellersArray.filter((seller) => seller.is_featured);
}

export function getSeller(sellerId, sellersArray) {
  return sellersArray.find((seller) => seller.id == sellerId);
}

export function getAssociatedMainCategory(
  subCategoryId,
  subCategoriesArray,
  mainCategoriesArray
) {
  const subCategory = subCategoriesArray.find(
    (subCategory) => subCategory.id == subCategoryId
  );

  if (!subCategory) {
    return null; // Return null if the subCategory with the given ID is not found
  }

  const mainCategory = mainCategoriesArray.find(
    (mainCategory) => mainCategory.id == subCategory.mainCategory
  );

  if (!mainCategory) {
    return null; // Return null if the mainCategory for the subCategory is not found
  }

  return mainCategory;
}

export function getAssociatedCategories(subCategoryId, categoriesArray) {
  return categoriesArray.filter(
    (category) => category.subCategory == subCategoryId
  );
}

export function getAssociatedSubCategories(mainCategoryId, subCategoriesArray) {
  return subCategoriesArray.filter(
    (subCategory) => subCategory.mainCategory == mainCategoryId
  );
}

export function getCategoryParents(
  categoryId,
  subCategoriesArray,
  mainCategoriesArray
) {
  const subCategory = subCategoriesArray.find(
    (subCategory) => subCategory.id == categoryId
  );

  if (!subCategory) {
    return null; // Return null if the category ID does not match any subCategory
  }

  const mainCategory = mainCategoriesArray.find(
    (mainCategory) => mainCategory.id == subCategory.mainCategory
  );

  if (!mainCategory) {
    return null; // Return null if the subCategory's mainCategory ID does not match any mainCategory
  }

  return { subCategory, mainCategory };
}

export function getSubCategoryParent(
  subCategoryId,
  subCategoriesArray,
  mainCategoriesArray
) {
  const subCategory = subCategoriesArray.find(
    (subCategory) => subCategory.id == subCategoryId
  );

  if (!subCategory) {
    return null; // Return null if the subCategory ID does not match any subCategory
  }

  const mainCategory = mainCategoriesArray.find(
    (mainCategory) => mainCategory.id == subCategory.mainCategory
  );

  if (!mainCategory) {
    return null; // Return null if the subCategory's mainCategory ID does not match any mainCategory
  }

  return mainCategory;
}

export function buildMainCategoriesLinks(mainCategoriesArray) {
  const mainCategoriesWithLinks = mainCategoriesArray.map((mainCategory) => {
    const link = `/c/${encodeURIComponent(mainCategory.name.toLowerCase())}`;
    return { ...mainCategory, link };
  });

  return mainCategoriesWithLinks;
}

export function buildSubCategoryLinks(
  mainCategoryId,
  subCategoriesArray,
  mainCategoriesArray
) {
  const associatedSubCategories = subCategoriesArray.filter(
    (subCategory) => subCategory.mainCategory == mainCategoryId
  );

  const mainCategory = mainCategoriesArray.find(
    (category) => category.id == mainCategoryId
  );

  if (!mainCategory) {
    return null; // Return null if the mainCategory ID does not match any mainCategory
  }

  const mainCategoryLink = `/c/${encodeURIComponent(
    mainCategory.name.toLowerCase()
  )}`;

  const links = associatedSubCategories.map((subCategory) => ({
    ...subCategory,
    link: `${mainCategoryLink}/${encodeURIComponent(
      subCategory.name.toLowerCase()
    )}`,
  }));

  return links;
}

export function buildCategoryLinks(
  subCategoryId,
  categoriesArray,
  subCategoriesArray,
  mainCategoriesArray
) {
  const associatedCategories = categoriesArray.filter(
    (category) => category.subCategory == subCategoryId
  );

  const subCategory = subCategoriesArray.find(
    (category) => category.id == subCategoryId
  );

  if (!subCategory) {
    return null; // Return null if the subCategory ID does not match any subCategory
  }

  const mainCategory = mainCategoriesArray.find(
    (category) => category.id == subCategory.mainCategory
  );

  if (!mainCategory) {
    return null; // Return null if the mainCategory ID does not match any mainCategory
  }

  const mainCategoryLink = `/c/${encodeURIComponent(
    mainCategory.name.toLowerCase()
  )}`;
  const subCategoryLink = `${mainCategoryLink}/${encodeURIComponent(
    subCategory.name.toLowerCase()
  )}`;

  const links = associatedCategories.map((category) => ({
    ...category,
    link: `${subCategoryLink}/${encodeURIComponent(
      category.name.toLowerCase()
    )}`,
  }));

  return links;
}

export function getSubCategoryParentByName(
  subCategoryName,
  subCategoriesArray,
  mainCategoriesArray
) {
  const subCategory = subCategoriesArray.find(
    (subCategory) =>
      subCategory.name.toLowerCase() == subCategoryName.toLowerCase()
  );

  if (!subCategory) {
    return null; // Return null if the subCategory with the given name is not found
  }

  const mainCategory = mainCategoriesArray.find(
    (mainCategory) => mainCategory.id == subCategory.mainCategory
  );

  if (!mainCategory) {
    return null; // Return null if the mainCategory for the subCategory is not found
  }

  return mainCategory;
}

export function getCategoryParentByName(
  categoryName,
  categoriesArray,
  subCategoriesArray
) {
  const category = categoriesArray.find(
    (category) => category.name.toLowerCase() == categoryName.toLowerCase()
  );

  if (!category) {
    return null; // Return null if the category with the given name is not found
  }

  const subCategory = subCategoriesArray.find(
    (subCategory) => subCategory.id == category.subCategory
  );

  if (!subCategory) {
    return null; // Return null if the subCategory for the category is not found
  }

  return subCategory;
}

export function getAssociatedMainCategoryByName(
  subCategoryName,
  subCategoriesArray,
  mainCategoriesArray
) {
  const subCategory = subCategoriesArray.find(
    (subCategory) =>
      subCategory.name.toLowerCase() == subCategoryName.toLowerCase()
  );

  if (!subCategory) {
    return null; // Return null if the subCategory with the given name is not found
  }

  const mainCategory = mainCategoriesArray.find(
    (mainCategory) => mainCategory.id == subCategory.mainCategory
  );

  if (!mainCategory) {
    return null; // Return null if the mainCategory for the subCategory is not found
  }

  return mainCategory;
}

export function getAssociatedSubCategoriesByName(
  mainCategoryName,
  subCategoriesArray,
  mainCategoriesArray
) {
  const mainCategory = mainCategoriesArray.find(
    (mainCategory) =>
      mainCategory.name.toLowerCase() == mainCategoryName.toLowerCase()
  );

  if (!mainCategory) {
    return null; // Return null if the mainCategory with the given name is not found
  }

  const associatedSubCategories = subCategoriesArray.filter(
    (subCategory) => subCategory.mainCategory == mainCategory.id
  );

  return associatedSubCategories;
}

export function getAssociatedCategoriesByName(
  subCategoryName,
  categoriesArray,
  subCategoriesArray
) {
  const subCategory = subCategoriesArray.find(
    (subCategory) =>
      subCategory.name.toLowerCase() == subCategoryName.toLowerCase()
  );

  if (!subCategory) {
    return null; // Return null if the subCategory with the given name is not found
  }

  const associatedCategories = categoriesArray.filter(
    (category) => category.subCategory == subCategory.id
  );

  return associatedCategories;
}

export function getCategoryParentsByName(
  categoryName,
  categoriesArray,
  subCategoriesArray,
  mainCategoriesArray
) {
  const category = categoriesArray.find(
    (category) => category.name.toLowerCase() == categoryName.toLowerCase()
  );

  if (!category) {
    return null; // Return null if the category with the given name is not found
  }

  const subCategory = subCategoriesArray.find(
    (subCategory) => subCategory.id == category.subCategory
  );
  const mainCategory = mainCategoriesArray.find(
    (mainCategory) => mainCategory.id == subCategory.mainCategory
  );

  if (!subCategory || !mainCategory) {
    return null; // Return null if the subCategory or mainCategory for the category is not found
  }

  return { subCategory, mainCategory };
}

export function buildSubCategoryLinksByName(
  mainCategoryName,
  subCategoriesArray,
  mainCategoriesArray
) {
  const associatedSubCategories = getAssociatedSubCategoriesByName(
    mainCategoryName,
    subCategoriesArray,
    mainCategoriesArray
  );

  if (!associatedSubCategories) {
    return null;
  }

  const mainCategoryLink = `/c/${encodeURIComponent(
    mainCategoryName.toLowerCase()
  )}`;

  const links = associatedSubCategories.map((subCategory) => ({
    ...subCategory,
    link: `${mainCategoryLink}/${encodeURIComponent(
      subCategory.name.toLowerCase()
    )}`,
  }));

  return links;
}

export function buildSubCategoryLink(
  subCategoryId,
  subCategoriesArray,
  mainCategoriesArray
) {
  const subCategory = subCategoriesArray.find(
    (subCategory) => subCategory.id == subCategoryId
  );

  if (!subCategory) return "";

  const mainCategory = mainCategoriesArray.find(
    (mainCategory) => mainCategory.id == subCategory.mainCategory
  );

  if (!mainCategory) return "";

  const mainCategoryName = mainCategory.name.toLowerCase();
  const subCategoryLink = `/c/${mainCategoryName}/${encodeURIComponent(
    subCategory.name.toLowerCase()
  )}`;

  return subCategoryLink;
}

export function buildCategoryLink(
  categoryId,
  categoriesArray,
  subCategoriesArray,
  mainCategoriesArray
) {
  const category = categoriesArray.find(
    (category) => category.id == categoryId
  );

  if (!category) return "";

  const subCategory = subCategoriesArray.find(
    (subCategory) => subCategory.id == category.subCategory
  );

  if (!subCategory) return "";

  const subCategoryName = buildSubCategoryLink(
    subCategory.id,
    subCategoriesArray,
    mainCategoriesArray
  );
  const categoryLink = `${subCategoryName}/${encodeURIComponent(
    category.name.toLowerCase()
  )}`;

  return categoryLink;
}

export function buildCategoryLinksByName(
  subCategoryName,
  mainCategoryName, // New argument for main category name
  categoriesArray,
  subCategoriesArray,
  mainCategoriesArray
) {
  const subCategories = subCategoriesArray.filter(
    (subCategory) =>
      subCategory.name.toLowerCase() === subCategoryName.toLowerCase()
  );

  if (subCategories.length === 0) {
    return null; // Return null if no subCategory with the given name is found
  }

  const mainCategory = mainCategoriesArray.find(
    (mainCategory) =>
      mainCategory.name.toLowerCase() === mainCategoryName.toLowerCase()
  );

  if (!mainCategory) {
    return null; // Return null if the mainCategory for the subCategory is not found
  }

  const subCategory = subCategories.find(
    (subCategory) => subCategory.mainCategory === mainCategory.id
  );

  if (!subCategory) {
    return null; // Return null if no matching subCategory for the mainCategory is found
  }

  const mainCategoryNameLower = mainCategory.name.toLowerCase();
  const subCategoryLink = `/c/${mainCategoryNameLower}/${encodeURIComponent(
    subCategory.name.toLowerCase()
  )}`;

  const associatedCategories = categoriesArray.filter(
    (category) => category.subCategory === subCategory.id
  );

  const links = associatedCategories.map((category) => ({
    ...category,
    link: `${subCategoryLink}/${encodeURIComponent(
      category.name.toLowerCase()
    )}`,
  }));

  return links;
}

export function getAssociatedSellers(
  categoryId,
  categoriesArray,
  sellersArray
) {
  const category = categoriesArray.find(
    (category) => category.id == categoryId
  );

  if (!category) {
    return null; // Return null if the category with the given ID is not found
  }

  const associatedSellers = sellersArray.filter((seller) =>
    seller.categories.some((category) => category.category == categoryId)
  );

  return associatedSellers;
}

export function getAssociatedSellersByName(
  categoryName,
  subCategoryName,
  mainCategoryName,
  mainCategoriesArray,
  subCategoriesArray,
  categoriesArray,
  sellersArray
) {
  const mainCategory = mainCategoriesArray.find(
    (mainCategory) =>
      mainCategory.name.toLowerCase() === mainCategoryName.toLowerCase()
  );

  if (!mainCategory) {
    return null; // Return null if the mainCategory with the given name is not found
  }

  const subCategory = subCategoriesArray.find(
    (subCategory) =>
      subCategory.name.toLowerCase() === subCategoryName.toLowerCase() &&
      subCategory.mainCategory === mainCategory.id
  );

  if (!subCategory) {
    return null; // Return null if the subCategory for the mainCategory is not found
  }

  const category = categoriesArray.find(
    (category) =>
      category.name.toLowerCase() === categoryName.toLowerCase() &&
      category.subCategory === subCategory.id
  );

  if (!category) {
    return null; // Return null if the category with the given name is not found
  }

  const associatedSellers = sellersArray.filter((seller) =>
    seller.categories.some(
      (sellerCategory) => sellerCategory.category == category.id
    )
  );

  return associatedSellers;
}

export function getSellerParentsNames(
  sellerId,
  mainCategoriesArray,
  subCategoriesArray,
  categoriesArray,
  sellersArray
) {
  const seller = sellersArray.find((seller) => seller.id == sellerId);

  if (!seller) {
    return null; // Return null if the seller with the given ID is not found
  }

  const categoryIds = seller.categories.map(
    (categoryObj) => categoryObj.category
  );

  const parentCategories = [];

  for (const categoryId of categoryIds) {
    const category = categoriesArray.find(
      (category) => category.id == categoryId
    );

    if (!category) {
      continue; // Skip if the category with the given ID is not found
    }

    const subCategory = subCategoriesArray.find(
      (subCategory) => subCategory.id == category.subCategory
    );

    if (!subCategory) {
      continue; // Skip if the subCategory for the category is not found
    }

    const mainCategory = mainCategoriesArray.find(
      (mainCategory) => mainCategory.id == subCategory.mainCategory
    );

    if (!mainCategory) {
      continue; // Skip if the mainCategory for the subCategory is not found
    }

    const parentObject = {
      mainCategoryName: mainCategory.name,
      subCategoryName: subCategory.name,
      categoryName: category.name,
    };

    parentCategories.push(parentObject);
  }

  return parentCategories;
}

export function getCategoryObjectFromSeller(categoryId, seller) {
  const categoryObj = seller.categories.find(
    (category) => category.category == categoryId
  );

  return categoryObj || null;
}

export function getCategoryObjectFromSellerByName(
  categoryName,
  categoriesArray,
  seller
) {
  if (!seller || !seller.categories) {
    return null; // Return null if the seller or seller.categories is not found
  }

  const category = categoriesArray.find(
      (category) => category.name.toLowerCase() == categoryName.toLowerCase()
    ),
    categoryId = category.id;

  if (!categoryId) {
    return null; // Return null if the category with the given name is not found
  }

  const categoryObj = seller.categories.find(
    (category) => category.category == categoryId
  );

  return categoryObj || null;
}
