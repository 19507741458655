import React, { useState, useContext } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import Breadcrumb from "../components/breadcrumb";
import { AuthContext } from "../contexts";

export default function Login() {
  const { setUser, setToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const navigate = useHistory();

  const handleLogin = async (form) => {
    setLoading(true);
    const loginDetails = {
      email: form.email,
      password: form.password,
    };
    const loggedIn = await axios.post(
      `${process.env.REACT_APP_BACKEND}/login`,
      loginDetails
    );

    if (loggedIn.data === "INC_PASS") {
      setLoading(false);
      swal("Oops", "Incorrect credentials", "error");
    } else if (loggedIn.data === "NO_ACC") {
      setLoading(false);
      swal("Sorry", "We couldn't find your account. Please register", "error");
    } else {
      Cookies.set("LOGGEDIN", JSON.stringify(loggedIn.data), { expires: 1 });

      let user,
        token = loggedIn.data.token;
      try {
        const usrData = await axios.get(
          `${process.env.REACT_APP_BACKEND}/users/${loggedIn.data.userID}`,
          { headers: { Authorization: `Bearer ${loggedIn.data.token}` } }
        );
        user = usrData.data[0];
      } catch (e) {
        user = {
          ...JSON.parse(window.atob(token.split(".")[1])),
          id: loggedIn.data.userID,
        };
      }

      setUser(user);
      setToken(token);

      form.email = "";
      form.password = "";
      setLoading(false);
      swal("Success!", "Login Successful", "success");
      navigate.push("/admin/dashboard");
    }
  };

  const logSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required.")
      .min(10, "Minimum of 10 characters")
      .max(30, "Maximum of 30 characters"),
    password: Yup.string()
      .required("Password is required")
      .min(5, "Password should be a minimum of 5 characters.")
      .max(50, "Password should have a maximum of 50characters."),
  });

  return (
    <div>
      <Helmet>
        <title>Minkmall - Log In</title>
      </Helmet>

      <Breadcrumb title={"Login"} />
      {/* Login section */}
      <section className="login-page section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3>Login</h3>
              <div className="theme-card">
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validateOnChange
                  onSubmit={handleLogin}
                  validationSchema={logSchema}
                >
                  <Form className="theme-form">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <Field
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="Enter your password"
                        required
                      />
                    </div>
                    {loading ? (
                      <h3>Logging In...</h3>
                    ) : (
                      <button type="submit" className="btn btn-solid">
                        Login
                      </button>
                    )}
                  </Form>
                </Formik>
              </div>
            </div>
            <div className="col-lg-6 right-login">
              <h3>New Customer</h3>
              <div className="theme-card authentication-right">
                <h6 className="title-font">Create An Account</h6>
                <p>
                  Register a free account on our app. Registration is quick and
                  easy. Your account gives you an opportunity to browse top
                  ranking shops and a wide variety of products that suit your
                  needs.
                </p>
                <Link to="/pages/register" className="btn btn-solid">
                  Create an Account
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
