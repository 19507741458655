import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import NavBar from "./navbar";
import TopBar from "./topBar";
import LogoImage from "./logo";
import { useMediaQuery } from "react-responsive";
import "./header.css";

function Spacer() {
  const [showSpacer, setShowSpacer] = useState(false);
  const isPhone = useMediaQuery({ maxWidth: 577 });

  useEffect(() => {
    const handleScroll = () => {
      let number =
        window.pageXOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;

      if (number >= 100) {
        document.getElementById("sticky").classList.add("fixed");
        setShowSpacer(true);
      } else {
        document.getElementById("sticky").classList.remove("fixed");
        setShowSpacer(false);
      }
    };

    const tID = setTimeout(() => {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearTimeout(tID);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        height: !isPhone ? "11em" : "6em",
        width: "100%",
        display: showSpacer ? "block" : "none",
      }}
    />
  );
}

function SearchOverlay(props) {
  const { searchOverlayDiv } = props;

  const history = useHistory();
  const searchQueryInput = useRef(null);

  const closeSearch = () => {
    searchOverlayDiv.current.style.display = "none";
    searchQueryInput.current.value = "";
  };

  const handleSearch = (event) => {
    event.preventDefault();

    const searchQuery = searchQueryInput.current.value;

    closeSearch();
    history.push(`/pages/search?q=${searchQuery}`);
  };

  return (
    <div ref={searchOverlayDiv} id="search-overlay" className="search-overlay">
      <div>
        <span className="closebtn" onClick={closeSearch} title="Close Overlay">
          ×
        </span>
        <div className="overlay-content">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <form onSubmit={handleSearch}>
                  <div className="form-group">
                    <input
                      ref={searchQueryInput}
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSearch}
                  >
                    <i className="fa fa-search" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Header() {
  const isPhone = useMediaQuery({ maxWidth: 577 });
  const searchOverlayDiv = useRef(null);

  const openSearch = () => {
    searchOverlayDiv.current.style.display = "block";
  };

  return (
    <>
      <Spacer />

      <div
        style={{
          height: !isPhone ? "2em" : "4em",
          width: "100%",
          padding: "0.5em 1em",
          color: !isPhone ? "#222222" : "#fff",
          display: "grid",
          placeContent: "center",
          textAlign: "center",
          backgroundColor: isPhone ? "#333" : "#ffd9c7",
        }}
      >
        An Online Business Directory For Exclusively Made-In-Kenya Brands
      </div>

      <div>
        <header id="sticky" className="sticky">
          {!isPhone && <TopBar />}

          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="main-menu">
                  <div className="menu-left category-nav-right">
                    <div
                      style={{
                        position: "relative",
                        padding: "0",
                        display: "inline-block",
                      }}
                    >
                      <LogoImage logo="/assets/minkmall/logo.webp" />
                    </div>
                  </div>

                  <div className="menu-right pull-right">
                    <NavBar />

                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          marginLeft: "1em",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`}
                          onClick={openSearch}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <SearchOverlay searchOverlayDiv={searchOverlayDiv} />
      </div>
    </>
  );
}
