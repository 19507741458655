import React, { useState } from "react";
import axios from "axios";
import Breadcrumb from "../components/breadcrumb";
import { Field, Form, Formik, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Helmet } from "react-helmet";
import { sendEmail } from "../../api/handleData";

export default function Contact(props) {
  const [loading, setLoading] = useState(false);
  // const [formData, setFormData] = useState({
  //   firstName: "",
  //   lastName: "",
  //   phoneNumber: "",
  //   email: "",
  //   message: "",
  // });
  // const handleChange = (event) => {
  //   setFormData({
  //     ...formData,
  //     [event.target.name]: event.target.value,
  //   });
  // };
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   axios
  //     .post("https://api.example.com/endpoint", formData)
  //     .then((response) => {
  //       console.log(response);
  //       // Handle the response if needed
  //       // Reset the form fields
  //       setFormData({
  //         firstName: "",
  //         lastName: "",
  //         phoneNumber: "",
  //         email: "",
  //         message: "",
  //       });
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       // Handle the error if needed
  //     });
  // };

  const submitContact = (form) => {
    setLoading(true);
    const data = {
      first_name: form.first_name,
      last_name: form.last_name,
      phone: form.phone,
      email: form.email,
      message: form.message,
    };
    sendEmail(form.first_name, form.phone, "Enquiry", form.message);

    //console.log("CONTACT:", data);
    form.first_name = "";
    form.last_name = "";
    form.phone = "";
    form.email = "";
    form.message = "";
    setLoading(false);
    //alert("Thanks. We'll be in touch shortly.");
  };

  const contactSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is required.")
      .min(4, "Field should be at least 4 characters.")
      .max(15, "Oops that's too long maximum is 15 letters"),
    last_name: Yup.string()
      .required("Last Name is required.")
      .min(4, "Field should be at least 4 characters.")
      .max(15, "Oops that's too long maximum is 15 letters"),
    email: Yup.string()
      .required("Email is required.")
      .min(10, "Minimum of 10characters")
      .max(30, "Maximum of 30 characters"),
    phone: Yup.string()
      .matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/,
        "Invalid phone number, use numbers please: 07..."
      )
      .required("Phone number is required please")
      .min(10, "Please type at least 10 characters. Use 07... or 01.. format")
      .max(10, "Sorry, thats too long. Please use 07.. or 01.. format"),
    message: Yup.string()
      .required("Message")
      .min(5, "Message should be a minimum of 5 characters.")
      .max(50, "Message should have a maximum of 50characters."),
  });

  return (
    <div>
      <Helmet>
        <title>Minkmall - Contact Us</title>
      </Helmet>

      <Breadcrumb title={"Contact Us"} />

      {/*Forget Password section*/}
      <section className="contact-page section-b-space">
        <div className="container">
          <div className="row section-b-space">
            <div className="col-lg-7 map">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255282.32335424496!2d36.84739685!3d-1.3032089500000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1172d84d49a7%3A0xf7cf0254b297924c!2sNairobi!5e0!3m2!1sen!2ske!4v1690565875654!5m2!1sen!2ske"
                width="600"
                height="450"
                style={{ border: 0 }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="col-lg-5">
              <div className="contact-right">
                <ul>
                  <li>
                    <div className="contact-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icon/phone.png`}
                        alt="Generic placeholder image"
                      />
                      <h6>Contact Us</h6>
                    </div>
                    <div className="media-body">
                      <p>Nairobi Kenya</p>
                    </div>
                  </li>
                  <li>
                    <div className="contact-icon">
                      <i className="fa fa-map-marker" aria-hidden="true" />
                      <h6>Address</h6>
                    </div>
                    <div className="media-body">
                      <p>Nairobi</p>
                      <p>Kenya</p>
                    </div>
                  </li>
                  <li>
                    <div className="contact-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icon/email.png`}
                        alt="Generic placeholder image"
                      />
                      <h6>Email</h6>
                    </div>
                    <div className="media-body">
                      <p>support@minkmall.co.ke</p>
                      <p>info@minkmall.co.ke</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  phone: "",
                  email: "",
                  message: "",
                }}
                validateOnChange
                onSubmit={submitContact}
                validationSchema={contactSchema}
              >
                <Form className="theme-form">
                  <div className="form-row">
                    <div className="col-md-6">
                      <label htmlFor="name">First Name</label>
                      <ErrorMessage
                        className="validator"
                        name="first_name"
                        component="div"
                      />
                      <Field
                        type="text"
                        className="form-control"
                        id="name"
                        name="first_name"
                        placeholder="Enter Your name"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">Last Name</label>
                      <ErrorMessage
                        className="validator"
                        name="last_name"
                        component="div"
                      />
                      <Field
                        type="text"
                        className="form-control"
                        id="last_name"
                        name="last_name"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="review">Phone number</label>
                      <ErrorMessage
                        className="validator"
                        name="phone"
                        component="div"
                      />
                      <Field
                        type="tel"
                        className="form-control"
                        id="review"
                        name="phone"
                        placeholder="Enter your number"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">Email</label>
                      <ErrorMessage
                        className="validator"
                        name="email"
                        component="div"
                      />
                      <Field
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="review">Write Your Message</label>
                      <ErrorMessage
                        className="validator"
                        name="message"
                        component="div"
                      />
                      <Field
                        className="form-control"
                        placeholder="Write Your Message"
                        id="exampleFormControlTextarea1"
                        name="message"
                        rows="6"
                      />
                    </div>
                    <div className="col-md-12">
                      {loading ? (
                        <h3>Sending...</h3>
                      ) : (
                        <button className="btn btn-solid" type="submit">
                          Send Your Message
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
