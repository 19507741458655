const initialState = {
  mainCategories: [],
  subCategories: [],
  categories: [],
  sellers: [],
  products: [],
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_DATA_SUCCESS":
      return {
        ...state,
        [action.api]: action.data,
      };
    case "FETCH_FAKE_DATA":
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default dataReducer;
