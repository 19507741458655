import * as Yup from 'yup';


export const prdSchema = Yup.object().shape({
  name: Yup.string()
    .required("Product Name is required.")
    .min(4, "Field should be at least 4 characters.")
    .max(255, "Oops that's too long maximum is 255 letters"),
  price: Yup.string()
    .required("Product Price is required."),
  shortDetails: Yup.string()
    .required("Short Description is required.")
    .min(10, "Minimum of 10characters is required")
    .max(1000, "Maximum of 1000 characters is required"),
  link: Yup.string()
    .required("Product link is required(Link to your product on your website/social media)")
    .min(3, "Add more than 3 characters")
    .max(1000, "Maximum is 300 characters")
});


export const storeSchema = Yup.object().shape({
  name: Yup.string()
    .required("Store Name is required.")
    .min(4, "Field should be at least 4 characters.")
    .max(60, "Oops that's too long maximum is 60 letters"),
  website: Yup.string()
    .required("Shop Link is required.")
    .min(4, "Field should be at least 4 characters.")
    .max(50, "Oops this field only accepts up to 50 characters"),
  telephone: Yup.string()
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
      "Invalid phone number, use numbers please: 07..."
    )
    .required("Shop Phone Number is required")
    .min(
      10,
      "Please type at least 10 characters. Use 07... format"
    )
    .max(
      10,
      "Sorry, thats too long. Please use 07... format"
    ),
  facebook: Yup.string()
    .min(4, "Field should be at least 4 characters.")
    .max(100, "Oops this field only accepts up to 100 characters"),
  instagram: Yup.string()
    .min(4, "Field should be at least 4 characters.")
    .max(100, "Oops this field only accepts up to 100 characters"),
  email: Yup.string().email(),
  location: Yup.string()
    .required("Your Shop location is required.")
    .min(4, "Field should be at least 4 characters.")
    .max(50, "Oops this field only accepts up to 50 characters"),
  motto: Yup.string()
    .required("Please include a short shop bio.")
    .min(4, "Field should be at least 4 characters.")
    .max(100, "Oops this field only accepts up to 100 characters"),
  description: Yup.string()
    .required("Please describe what your shop deals with")
    .min(4, "Field should be at least 4 characters.")
    .max(300, "Oops this field only accepts up to 300 characters"),
  whatsapp: Yup.string()
    .required("Whatsapp Link is required.")
    .min(4, "Field should be at least 4 characters.")
    .max(100, "Oops this field only accepts up to 100 characters"),
});

export const regSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First Name is required.")
    .min(4, "Field should be at least 4 characters.")
    .max(15, "Oops that's too long maximum is 15 letters"),
  last_name: Yup.string()
    .required("Last Name is required.")
    .min(4, "Field should be at least 4 characters.")
    .max(15, "Oops that's too long maximum is 15 letters"),
  email: Yup.string()
    .required("Email is required.")
    .min(10, "Minimum of 10characters")
    .max(30, "Maximum of 30 characters"),
  phone: Yup.string()
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/,
      "Invalid phone number, use numbers please: 07..."
    )
    .required("Phone number is required please")
    .min(10, "Please type at least 10 characters. Use 07... or 01.. format")
    .max(10, "Sorry, thats too long. Please use 07.. or 01.. format"),
});