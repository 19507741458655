import axios from "axios";
import fake_data from "../harmonised_data.json";

export const fetchData = (api) => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/${api}`)
      .then((response) => {
        dispatch(fetchDataSuccess(api, response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const fetchFakeData = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_FAKE_DATA", data: fake_data });
  };
};

export const fetchDataSuccess = (api, data) => {
  return {
    type: "FETCH_DATA_SUCCESS",
    api,
    data,
  };
};
