import React, { useState, useEffect, useContext } from "react";
import { getAllStores, getUserById, sendEmail } from "../../api/handleData";
import { Link, useParams, useHistory } from "react-router-dom";
import { Field, Form, Formik, ErrorMessage, useField } from "formik";
import axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
import Thumb from "./components/Thumb";
import { error, info } from "../../api/toast";
import { AuthContext } from "../contexts";

const EditSeller = () => {
  const [allsellers, setAllSellers] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);

  var checkforCookie = Cookies.get("LOGGEDIN");
  const details = JSON.parse(checkforCookie);
  const navigate = useHistory();
  const { id } = useParams();

  if (allsellers)
    var singleSeller = allsellers.find((sl) => sl.id.toString() === id);

  let usr = { is_supplier: true };

  //submit the form
  const submitForm = async (form) => {
    //set loader
    setLoading(true);
    let social = {
      facebook: form.facebook,
      instagram: form.instagram,
      whatsapp: form.whatsapp,
    };
    form.socials = JSON.stringify(social);
    console.log("FORM UPDATE:", form);
    //send to backend
    await axios
      .put(`${process.env.REACT_APP_BACKEND}/api/sellers/${id}`, form, {
        headers: { Authorization: `Bearer ${details.token}` },
      })
      .then((res) => {
        if (res.data == "Store Updated") {
          swal("Success", "Store updated Successfully", "success");
        } else {
          //swal("Error", "Oops an error occured updating seller", "error")
          error("An Error Occured Updating This Seller");
        }
        //console.log("EDIT SELL RES:", res)
      })
      .catch((err) => {
        console.log("EDIT SELL ERR:", err);
      });
    //update the user account
    if (form.is_approved) {
      await axios
        .put(`${process.env.REACT_APP_BACKEND}/users/${user.id}`, usr, {
          headers: { Authorization: `Bearer ${details.token}` },
        })
        .then((res) => {
          if (res.data == "User Updated") {
            console.log("TICK");
          }
          //console.log("RESULTTT:", res)
        })
        .catch((err) => {
          console.log("ERROR EDIT:", err);
        });
      //console.log("EDIT FORM:", form);
    }

    setLoading(false);
  };

  const deleteStore = async () => {
    swal({
      title: "Confirmation",
      text: "Are you sure? This action is permanent",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let del = await axios.delete(
          `${process.env.REACT_APP_BACKEND}/api/sellers/${id}`,
          { headers: { Authorization: `Bearer ${details.token}` } }
        );
        if (del.data == "DEL") {
          swal("Success", "Deleted Successfully", "success");
        }
        navigate.push("/admin/dashboard");
      } else {
        console.log("Cancelled");
      }
    });
  };
  const deactivate = () => {
    swal({
      title: "Confirmation",
      text: "Are you sure? This action is permanent",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDeactivate) => {
      if (willDeactivate) {
        sendEmail(
          singleSeller.name,
          singleSeller.telephone,
          "Store Deactivation Request",
          "I would like to deactivate my Seller account.."
        );
      } else {
        console.log("Cancelled");
        info("You have cancelled your request");
      }
    });
  };

  useEffect(() => {
    getAllStores(setAllSellers);
  }, []);

  //console.log("ALL SELLERS:", singleSeller);
  if (singleSeller) {
    var social = singleSeller.socials;
  }
  //console.log("ALL SELLERS:", social);

  return singleSeller ? (
    <section className="register-page section-b-space">
      <div className="container">
        <Link to="/admin/dashboard">
          {" "}
          <i className="icon-back-left" /> Back to Dashboard
        </Link>
        <div className="row">
          <div className="col-lg-12">
            <div className="md-20" />
            <h3>Edit Seller Account Details</h3>
            {user.is_admin ? (
              <button
                onClick={() => deleteStore()}
                className="btn btn-solid"
                type="submit"
              >
                Delete This Store
              </button>
            ) : (
              <button
                onClick={() => deactivate()}
                className="btn btn-solid"
                type="submit"
              >
                Deactivate My Store
              </button>
            )}
            <div className="theme-card">
              <Formik
                initialValues={{
                  name: singleSeller.name,
                  is_featured: user.is_admin && singleSeller.is_featured,
                  telephone: singleSeller.telephone,
                  email: singleSeller.email,
                  motto: singleSeller.motto,
                  description: singleSeller.description,
                  location: singleSeller.location,
                  //categories: singleSeller.categories,
                  facebook: social.facebook,
                  instagram: social.instagram,
                  posted_by: singleSeller.posted_by,
                  whatsapp: social.whatsapp,
                  website: singleSeller.website,
                  is_approved: user.is_admin && singleSeller.is_approved,
                  verification_docs:
                    user.is_admin && singleSeller.verification_docs,
                }}
                onSubmit={submitForm}
                validateOnChange
              >
                {({ values }) => (
                  <Form className="theme-form">
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="business_name">Seller Name</label>
                        <ErrorMessage
                          className="validator"
                          name="name"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                        />
                      </div>
                      {user.is_admin && (
                        <div className="col-md-6">
                          <label htmlFor="business_name">Posted By</label>
                          <ErrorMessage
                            className="validator"
                            name="posted_by"
                            component="div"
                          />
                          <Field
                            type="text"
                            className="form-control"
                            id="posted_by"
                            name="posted_by"
                          />
                        </div>
                      )}
                      <div className="col-md-6">
                        <label htmlFor="business_name">Telephone</label>
                        <ErrorMessage
                          className="validator"
                          name="telephone"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="telephone"
                          name="telephone"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="business_name">Email</label>
                        <ErrorMessage
                          className="validator"
                          name="email"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="business_name">Location</label>
                        <ErrorMessage
                          className="validator"
                          name="location"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="location"
                          name="location"
                        />
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="business_name">Categories</label>
                        <ErrorMessage
                          className="validator"
                          name="categories"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="categories"
                          name="categories"
                          value={singleSeller.categories}
                        />
                      </div>

                      <div className="col-md-12">
                        <label htmlFor="business_name">
                          Website / Shop Link
                        </label>
                        <ErrorMessage
                          className="validator"
                          name="website"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="website"
                          name="website"
                        />
                      </div>
                    </div>
                    {user.is_admin && (
                      <div className="form-row">
                        <div className="col-md-6">
                          <h4>Click to Set As Featured </h4>
                          <Field
                            type="checkbox"
                            id="is_featured"
                            name="is_featured"
                          />
                          <label htmlFor="review">{`${
                            values.is_featured ? "FEATURED" : "NOT FEATURED"
                          }`}</label>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="review">Facebook</label>
                          <ErrorMessage
                            className="validator"
                            name="facebook"
                            component="div"
                          />
                          <Field
                            type="text"
                            className="form-control"
                            id="facebook"
                            name="facebook"
                          />
                        </div>
                      </div>
                    )}
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="email">Instagram</label>
                        <ErrorMessage
                          className="validator"
                          name="instagram"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="instagram"
                          name="instagram"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="review">Whatsapp</label>
                        <ErrorMessage
                          className="validator"
                          name="whatsapp"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="whatsapp"
                          name="whatsapp"
                        />
                      </div>
                      {user.is_admin && (
                        <div className="col-md-6">
                          <h4>Click to Approve Seller </h4>
                          <Field
                            type="checkbox"
                            id="is_approved"
                            name="is_approved"
                          />
                          <label htmlFor="review">{`${
                            values.is_approved ? "APPROVED" : "NOT APPROVED"
                          }`}</label>
                        </div>
                      )}
                      {user.is_admin && (
                        <div className="col-md-6">
                          <h4>Uploaded file </h4>
                          <Thumb
                            link={
                              values.verification_docs
                                ? values.verification_docs
                                : "assets/images/dog.png"
                            }
                          />
                        </div>
                      )}

                      <div>
                        {loading ? (
                          <h3>Updating...</h3>
                        ) : (
                          <button className="btn btn-solid" type="submit">
                            Update
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div>
      <h4>Loading....</h4>
    </div>
  );
};

export default EditSeller;
