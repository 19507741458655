import React, { useState } from "react";
import { connect } from "react-redux";
import { getFeaturedSellers } from "../utils/extract";
import SellerItem from "./sellerItem";

function ListedSellers(props) {
  const { sellers, increaseBy } = props;
  const [limit, setLimit] = useState(increaseBy);

  const handleLoadMore = () => {
    setLimit((prevLimit) => Math.min(prevLimit + increaseBy, sellers.length));
  };

  return (
    <div className="container">
      {sellers.length ? (
        <div className="row" style={{ rowGap: "3em" }}>
          {sellers.slice(0, limit).map((seller, index) => (
            <div key={index} className="col-xl-2 col-md-4 col-sm-6">
              <SellerItem seller={seller} key={index} />
            </div>
          ))}
          {limit < sellers.length && (
            <div className="col-12" style={{ textAlign: "center" }}>
              {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
              <a className="btn btn-solid" onClick={handleLoadMore}>
                <i
                  className="fa fa-refresh"
                  aria-hidden="true"
                  style={{ marginRight: "2ch" }}
                ></i>
                load more
              </a>
            </div>
          )}
        </div>
      ) : (
        <div className="row">
          <div className="col-sm-12">
            <div style={{ padding: 0 }} className="error-section">
              <h2>please check back later</h2>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  sellers: getFeaturedSellers(state.data.sellers),
});

export default connect(mapStateToProps)(ListedSellers);
