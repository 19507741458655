import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";

function Slide(props) {
  const { landscapeImageSrc, portraitImageSrc, title, subtitle, link } = props;

  const imageSrc = useMediaQuery({ maxWidth: 1023 })
    ? portraitImageSrc
    : landscapeImageSrc;

  return (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}${imageSrc})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "center",
        backgroundColor: "bisque",
      }}
      className="home12 home"
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="slider-contain">
              <div>
                {subtitle ? <h4>{subtitle}</h4> : null}
                {title ? <h1>{title}</h1> : null}
                {link ? (
                  <Link to={link} target='_blank' className="btn btn-solid">
                    shop now
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Hero(props) {
  const slidesData = [
    {
      landscapeImageSrc: "/assets/minkmall/main.jpg",
      portraitImageSrc: "/assets/minkmall/intro.jpg",
    },
    {
      landscapeImageSrc: "/assets/minkmall/brands.jpg",
      portraitImageSrc: "/assets/minkmall/second.jpg",
    },
  ];

  return (
    <section className="p-0 small-slider">
      <Slider className="slide-1 home-slider">
        {slidesData.map((slide, index) => (
          <div key={index}>
            <Slide {...slide} />
          </div>
        ))}
      </Slider>
    </section>
  );
}
