import React, { useState } from "react";
import CategoryBanner from "./categoryBanner";

export default function CategoryBanners(props) {
  const { bannersData, home, increaseBy } = props;
  const [limit, setLimit] = useState(increaseBy);

  const handleLoadMore = () => {
    setLimit((prevLimit) =>
      Math.min(prevLimit + increaseBy, bannersData.length)
    );
  };

  return bannersData.length > 0 ? (
    <div className="container">
      <div
        className="row"
        style={{
          rowGap: "2em",
        }}
      >
        {bannersData.slice(0, limit).map((banner, index) => (
          <div key={index} className="col-md-4">
            <CategoryBanner category={banner} home={home} />
          </div>
        ))}
        {limit < bannersData.length && (
          <div className="col-12" style={{ textAlign: "center" }}>
            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a className="btn btn-solid" onClick={handleLoadMore}>
              <i
                className="fa fa-refresh"
                aria-hidden="true"
                style={{ marginRight: "2ch" }}
              ></i>
              load more
            </a>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div style={{ padding: 0 }} className="error-section">
            <h2>please check back later</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
