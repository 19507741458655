import React, { useState, createContext, useContext, useCallback } from "react";
import { connect } from "react-redux";
import { buildMainCategoriesTrees } from "../../utils/extract";
import "./categoriesSelector.css";

const SelectorContext = createContext(null);

function MainCategoryDropDown({ mainCategory, cnt }) {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((prevShow) => !prevShow);
  };

  return (
    <li style={cnt === 0 ? { marginTop: "0.25em" } : null}>
      <span className="categoryDropDown" onClick={toggleShow}>
        {mainCategory.name}
        <i
          style={{ paddingLeft: "1ch" }}
          className={`fa fa-caret-${show ? "up" : "down"}`}
          aria-hidden="true"
        ></i>
      </span>
      <ul
        style={{
          marginLeft: "2ch",
          display: show ? "flex" : "none",
          flexDirection: "column",
          gap: "0.25em",
        }}
      >
        {mainCategory.builtSubCategories.map((subCategory, idx) => (
          <SubCategoryDropDown
            key={idx}
            subCategory={subCategory}
            mainCategory={mainCategory}
            cnt={idx}
          />
        ))}
      </ul>
    </li>
  );
}

function SubCategoryDropDown({ subCategory, mainCategory, cnt }) {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((prevShow) => !prevShow);
  };

  return (
    <li style={cnt === 0 ? { marginTop: "0.25em" } : null}>
      <span className="categoryDropDown" onClick={toggleShow}>
        {subCategory.name}
        <i
          style={{ paddingLeft: "1ch" }}
          className={`fa fa-caret-${show ? "up" : "down"}`}
          aria-hidden="true"
        ></i>
      </span>
      <ul
        style={{
          marginLeft: "2ch",
          display: show ? "flex" : "none",
          flexDirection: "column",
          gap: "0.25em",
        }}
      >
        {subCategory.builtCategories.map((category, idx) => (
          <li key={idx} style={idx === 0 ? { marginTop: "0.25em" } : null}>
            <CategoryCheck
              category={category}
              subCategory={subCategory}
              mainCategory={mainCategory}
            />
          </li>
        ))}
      </ul>
    </li>
  );
}

function CategoryCheck({ category, subCategory, mainCategory }) {
  const [categoryChecked, setCategoryChecked] = useState(false);
  const { setSelected, setSelectedId, selectedId, formik } = useContext(
    SelectorContext
  );

  const handleCategoryCheck = (event) => {
    setCategoryChecked((prevCheck) => {
      const arrow = "   \u25B8   ";

      const selectedCategory = `${mainCategory.name}${arrow}${subCategory.name}${arrow}${category.name}`,
        selectedCategoryId = category.id;

      if (!prevCheck) {
        setSelected(selectedCategory);
        setSelectedId(selectedCategoryId);
        formik.setFieldValue("category", `${selectedCategoryId}`);
      } else {
        setSelected(null);
        setSelectedId(null);
        formik.setFieldValue("category", "");
      }

      return !prevCheck;
    });
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input
        className="categoriesSelectorCheckbox"
        style={{ cursor: "pointer", marginRight: "1ch" }}
        type="checkbox"
        checked={categoryChecked && selectedId === category.id}
        onChange={handleCategoryCheck}
        name={category.name}
        value={category.id}
      />
      <span style={{ lineHeight: "18px" }}>{category.name}</span>
    </div>
  );
}

function CategoriesSelector(props) {
  const { mainCategories, formik, resetCategoriesFuncRef } = props;

  const [selected, setSelected] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  resetCategoriesFuncRef.current = useCallback(() => {
    setSelected(null);
    setSelectedId(null);
  }, [setSelected, setSelectedId]);

  const selectorContextValue = {
    selected,
    setSelected,
    selectedId,
    setSelectedId,
    formik,
  };

  return (
    <div className="form-group">
      <SelectorContext.Provider value={selectorContextValue}>
        <label htmlFor="category">
          Choose category*
          <small style={{ display: "block" }}>
            &#9432; Use the drop downs below to select the category of the items
            you sell
          </small>
        </label>
        <input
          className="form-control sellerFormInput"
          type="text"
          value={selected || "NONE"}
          name="category"
          onBlur={formik.handleBlur}
          readOnly
        />

        <ul
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.25em",
            padding: "0 1em 0",
          }}
        >
          {mainCategories.map((mainCategory, idx) => (
            <MainCategoryDropDown
              key={idx}
              mainCategory={mainCategory}
              cnt={idx}
            />
          ))}
        </ul>
      </SelectorContext.Provider>

      {formik.touched.category && formik.errors.category && (
        <div className="sellerFormError">{formik.errors.category}</div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  mainCategories: buildMainCategoriesTrees(
    state.data.mainCategories,
    state.data.subCategories,
    state.data.categories
  ),
});

export default connect(mapStateToProps)(CategoriesSelector);
