import React from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../components/breadcrumb";
import SellerForm from "./sellerForm";

function BecomeASeller(props) {
  return (
    <div>
      <Helmet>
        <title>Minkmall - Become A Seller, Register Your Business</title>
      </Helmet>

      <Breadcrumb title={"Become A Seller"} />

      <section className="register-page section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>register your Business</h3>
            </div>

            <SellerForm />
          </div>
        </div>
      </section>
    </div>
  );
}

export default BecomeASeller;
