import React from "react";
import { Link } from "react-router-dom";

export default function EmptyResult(props) {
  const { showBackToHomeButton } = props;
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="error-section" style={{ padding: 0 }}>
          <h2>your search didn't return any results</h2>
          {showBackToHomeButton && (
            <Link to="/" className="btn btn-solid">
              back to home
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
