import React, { useContext, useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { Form, Formik, ErrorMessage, useField, Field } from "formik";
//import Upload from '../admin/components/Upload';
import axios from "axios";
//import DropDownMenuSelect from "react-nested-menu-selector";
import swal from "sweetalert";
import {
  categories,
  colors,
  getTheUser,
  maincategs,
  sendEmail,
} from "../../api/handleData";
// import CreatableSelect from 'react-select/creatable';
// import makeAnimated from 'react-select/animated';
// import Select from 'react-select'
// import DropdownTreeSelect from 'react-dropdown-tree-select'
import "react-dropdown-tree-select/dist/styles.css";
import { uploadFile, uploadGallery } from "../../api/uploadFiles";
import CategoriesSelector from "../components/categoriesSelector";
import { AuthContext } from "../contexts";

const Apply = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [hero, setHero] = useState(null);
  const [cert, setCert] = useState(null);
  const [categories, setCategories] = useState(null);
  const { user } = useContext(AuthContext);
  const navigate = useHistory();

  const submitApplication = async (fm) => {
    setLoading(true);
    let imgForm = new FormData();
    imgForm.append("logoImageSrc", image);
    const heroImageSrcs = hero ? hero : null;
    for (let i = 0; i < heroImageSrcs.length; i++)
      imgForm.append("heroImageSrcs[]", heroImageSrcs[i]);
    imgForm.append("verification_docs", cert ? cert : null);
    imgForm.append("data", JSON.stringify(fm));
    imgForm.append("posted_by", user.id);
    imgForm.append(
      "categories",
      categories ? JSON.stringify(categories) : null
    );

    //validate images
    if (!image) {
      setLoading(false);
      swal("Error", "Please upload your shop logo", "error");
    } else if (!cert) {
      setLoading(false);
      swal("Error", "Please upload atleast one of your certificates", "error");
    } else if (!hero) {
      setLoading(false);
      swal("Error", "Please upload atleast one of your shop images", "error");
    } else {
      //send the form to backend
      await axios
        .post(`${process.env.REACT_APP_BACKEND}/api/sellers`, imgForm)
        .then((res) => {
          //console.log("Store RES:", res)
          if (res.data == "STORE DONE") {
            navigate.push("/admin/dashboard");
            swal(
              "Success",
              "Application Sent Successfully, we'll be in touch",
              "success"
            );
            sendEmail(
              fm.name,
              fm.telephone,
              "Store Application",
              "Congrats, a new user has just aplied for a shop"
            );
            setLoading(false);
            fm.name = "";
            fm.facebook = "";
            fm.instagram = "";
            fm.whatsapp = "";
            fm.telephone = "";
            fm.website = "";
            fm.email = "";
            fm.location = "";
            fm.motto = "";
            fm.description = "";
            setHero(null);
            setImage(null);
            setCert(null);
          } else if (res.data.err.name == "SequelizeUniqueConstraintError") {
            swal(
              "Error",
              "",
              "Another shop with similar name is already registered",
              "error"
            );
            setLoading(false);
          }
        })
        .catch((err) => {
          //console.log("Store Err:", err)
          swal("Error", "Duplicate records or another error occured", "error");
          setLoading(false);
        });
      // console.log("SENT DATA:", fm)
      // console.log("FILES:", image, hero, cert)
    }
  };

  const storeSchema = Yup.object().shape({
    name: Yup.string()
      .required("Store Name is required.")
      .min(4, "Field should be at least 4 characters.")
      .max(60, "Oops that's too long maximum is 60 letters"),
    website: Yup.string()
      .required("Shop Link is required.")
      .min(4, "Field should be at least 4 characters.")
      .max(50, "Oops this field only accepts up to 50 characters"),
    telephone: Yup.string()
      .matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
        "Invalid phone number, use numbers please: 07..."
      )
      .required("This phone Number is required please")
      .min(10, "Please type at least 10 characters. Use 07... format")
      .max(10, "Sorry, thats too long. Please use 07... format"),
    facebook: Yup.string()
      .min(4, "Field should be at least 4 characters.")
      .max(100, "Oops this field only accepts up to 100 characters"),
    instagram: Yup.string()
      .min(4, "Field should be at least 4 characters.")
      .max(100, "Oops this field only accepts up to 100 characters"),
    email: Yup.string().email(),
    location: Yup.string()
      .min(4, "Field should be at least 4 characters.")
      .max(50, "Oops this field only accepts up to 50 characters"),
    motto: Yup.string()
      .min(4, "Field should be at least 4 characters.")
      .max(100, "Oops this field only accepts up to 100 characters"),
    description: Yup.string()
      .min(4, "Field should be at least 4 characters.")
      .max(100, "Oops this field only accepts up to 100 characters"),
    whatsapp: Yup.string()
      .required("Whatsapp Link is required.")
      .min(4, "Field should be at least 4 characters.")
      .max(100, "Oops this field only accepts up to 100 characters"),
  });

  const searchCategs = (e) => {};

  return (
    <div>
      {user ? (
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="dashboard-left">
                  <div className="collection-mobile-back">
                    <span className="filter-back">
                      <i className="" aria-hidden="true"></i> back
                    </span>
                  </div>
                  <div className="block-content">
                    <div className="theme-card">
                      <Formik
                        initialValues={{
                          name: "",
                          facebook: "",
                          instagram: "",
                          whatsapp: "",
                          telephone: "",
                          website: "",
                          email: "",
                          location: "",
                          motto: "",
                          description: "",
                        }}
                        onSubmit={submitApplication}
                        validationSchema={storeSchema}
                        validateOnChange
                      >
                        <Form className="theme-form">
                          <div className="form-row">
                            <div className="form-row">
                              <div className="col-md-6">
                                <label htmlFor="name"> Your Shop Name</label>
                                <ErrorMessage
                                  name="name"
                                  className="validator"
                                  component="div"
                                />
                                <Field
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  name="name"
                                  required
                                />
                              </div>
                              <div className="col-md-6">
                                <i
                                  className="icon-image"
                                  onClick={() =>
                                    uploadFile(setImage, "Upload shop logo")
                                  }
                                >
                                  {image ? "LOGO UPLOADED" : "Upload Shop Logo"}
                                </i>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="facebook">Facebook Link</label>
                                <ErrorMessage
                                  name="facebook"
                                  className="validator"
                                  component="div"
                                />
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="facebook"
                                  required
                                />
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="instagram">
                                  Instagram Link
                                </label>
                                <ErrorMessage
                                  name="instagram"
                                  className="validator"
                                  component="div"
                                />
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="instagram"
                                  required
                                />
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="instagram">Whatsapp</label>
                                <ErrorMessage
                                  name="whatsapp"
                                  className="validator"
                                  component="div"
                                />
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="whatsapp"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <label htmlFor="phone">
                                Your Shop Link/Website
                              </label>
                              <ErrorMessage
                                name="website"
                                className="validator"
                                component="div"
                              />
                              <Field
                                type="text"
                                className="form-control"
                                name="website"
                                required
                              />
                            </div>

                            <CategoriesSelector getSelected={setCategories} />
                            <div className="col-md-6">
                              <label htmlFor="email">Shop Phone</label>
                              <ErrorMessage
                                name="telephone"
                                className="validator"
                                component="div"
                              />
                              <Field
                                type="text"
                                className="form-control"
                                id="telephone"
                                name="telephone"
                              />
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="email">Shop Email</label>
                              <ErrorMessage
                                name="email"
                                className="validator"
                                component="div"
                              />
                              <Field
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                              />
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="email">Shop Location</label>
                              <ErrorMessage
                                name="location"
                                className="validator"
                                component="div"
                              />
                              <Field
                                type="text"
                                className="form-control"
                                id="location"
                                name="location"
                              />
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="email">Shop Bio</label>
                              <ErrorMessage
                                name="motto"
                                className="validator"
                                component="div"
                              />
                              <Field
                                type="text"
                                className="form-control"
                                id="motto"
                                name="motto"
                              />
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="email">Shop Description</label>
                              <ErrorMessage
                                name="description"
                                className="validator"
                                component="div"
                              />
                              <Field
                                type="text"
                                className="form-control"
                                id="description"
                                name="description"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <i
                              className="icon-image"
                              onClick={() => uploadGallery(setHero)}
                            >
                              {hero
                                ? "SHOP PICTURES UPLOADED"
                                : "Click to upload your shop images"}
                            </i>
                          </div>
                          <div className="col-md-6">
                            <i
                              className="icon-image"
                              onClick={() =>
                                uploadFile(
                                  setCert,
                                  "Upload a certificate/other business file"
                                )
                              }
                            >
                              {cert
                                ? "CERTIFICATE UPLOADED"
                                : "Click to upload your verification document"}
                            </i>
                          </div>

                          {loading ? (
                            <h3>Sending...</h3>
                          ) : (
                            <button type="submit" className="btn btn-solid">
                              Send For Approval
                            </button>
                          )}
                        </Form>
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="dashboard-left">
                  <div className="collection-mobile-back">
                    <span className="filter-back">
                      <i className="" aria-hidden="true"></i> back
                    </span>
                  </div>
                  <div className="block-content">
                    <h3>Oops Sorry Page not found.</h3>
                    <h6>You must be logged in to access this page</h6>
                    <h4>
                      <Link to="/">Go Back Home</Link>
                    </h4>
                    <h4>
                      <Link to="/pages/login">Login</Link>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Apply;
