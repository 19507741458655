import React from 'react'
import Swal from "sweetalert2";

export const Toast = Swal.mixin({
  toast: true,
  position: 'top-right',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 7500,
  timerProgressBar: true
})

export const error = async (word) => {
  await Toast.fire({
    icon: 'error',
    title: word
  })
}
export const success = async (word) => {
  await Toast.fire({
    icon: 'success',
    title: word
  })
}
export const info = async (word) => {
  await Toast.fire({
    icon: 'info',
    title: word
  })
}


