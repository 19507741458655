import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import Header from "../minkmall/components/header";
import Footer from "../minkmall/components/footer";

class App extends Component {
  render() {
    return (
      <div>
        <Header />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

export default withTranslate(App);
