import React from "react";
import { useParams, Redirect, Link } from "react-router-dom";
import "../../components/common/index.scss";
import Breadcrumb from "../components/breadcrumb";
import {
  getSeller,
  buildSellerCategoriesNamesAndLinks,
} from "../utils/extract.js";
import { connect } from "react-redux";
import ListedProducts from "../components/listedProducts";
import { Helmet } from "react-helmet";
import SellerCardSlider from "../components/sellerCardSlider";
import "./seller.css";
import { useMediaQuery } from "react-responsive";

function Seller(props) {
  const { sellers, dCategories, dSubCategories, dMainCategories } = props;
  const { sellerId } = useParams();

  const seller = getSeller(sellerId, sellers);

  if (!seller || !seller.is_approved) {
    return <Redirect to="/pages/404" />;
  }

  const centerAlign = useMediaQuery({ maxWidth: 767 });

  const {
    logoImageSrc,
    heroImageSrcs,
    telephone,
    email,
    location,
    socials,
    name,
    website,
    motto,
    description,
    id,
    categories,
  } = seller;

  const sellerCategories = buildSellerCategoriesNamesAndLinks(
    categories,
    dCategories,
    dSubCategories,
    dMainCategories
  );

  return (
    <div>
      <Helmet>
        <title>{`Minkmall - Sellers: ${name}`}</title>
      </Helmet>

      <Breadcrumb title={name} parent="Sellers" />

      <section className="section-b-space">
        <div className="container">
          <div className="row">
            <div
              className="col-sm-12 sellerCardSliderParent"
              style={{ marginBottom: "2em" }}
            >
              <SellerCardSlider
                imageSrcs={heroImageSrcs}
                showDots={true}
                imageMaxHeight={"25em"}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="top-banner-wrapper">
                <div style={centerAlign ? { textAlign: "center" } : null}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "2em",
                      gap: "2em",
                      justifyContent: centerAlign ? "center" : "unset",
                      flexWrap: "wrap",
                    }}
                  >
                    <img
                      style={{ maxHeight: "8em", maxWidth: "6em" }}
                      src={`${process.env.PUBLIC_URL}${logoImageSrc}`}
                      alt="seller logo"
                    />
                    <h1
                      style={{
                        fontSize: "2rem",
                        fontWeight: "500",
                        maxWidth: useMediaQuery({ minWidth: 992 })
                          ? "40ch"
                          : "unset",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: useMediaQuery({ minWidth: 992 })
                          ? "nowrap"
                          : "unset",
                        margin: 0,
                      }}
                    >
                      {name}
                    </h1>

                    <div
                      className="shopNow"
                      style={
                        useMediaQuery({ minWidth: 992 })
                          ? { position: "absolute", right: 0 }
                          : null
                      }
                    >
                      <a
                        href={website}
                        className="btn btn-solid"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Shop now
                      </a>
                    </div>
                  </div>
                  <h4>{motto}</h4>
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row" style={{ rowGap: "1em" }}>
            <div
              className="col-sm-12"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: centerAlign ? "center" : "unset",
              }}
            >
              <i
                style={{
                  color: "#ff4c3b",
                  fontSize: "1.5em",
                  marginRight: "2.5ch",
                }}
                className="fa fa-phone"
                aria-hidden="true"
              />

              <span style={{ fontSize: "1em" }}>{telephone}</span>
            </div>
            <div
              className="col-sm-12"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: centerAlign ? "center" : "unset",
              }}
            >
              <i
                style={{
                  color: "#ff4c3b",
                  fontSize: "1.5em",
                  marginRight: "3ch",
                }}
                className="fa fa-map-marker"
                aria-hidden="true"
              />

              <span style={{ fontSize: "1em" }}>{location}</span>
            </div>
            <div
              className="col-sm-12"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: centerAlign ? "center" : "unset",
              }}
            >
              <i
                style={{
                  color: "#ff4c3b",
                  fontSize: "1.5em",
                  marginRight: "2.1ch",
                }}
                className="fa fa-envelope"
                aria-hidden="true"
              />

              <span style={{ fontSize: "1em" }}>{email}</span>
            </div>
            <div
              className="col-sm-12"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: centerAlign ? "center" : "unset",
              }}
            >
              <i
                style={{
                  color: "#ff4c3b",
                  fontSize: "1.5em",
                  marginRight: "2.1ch",
                }}
                className="fa fa-globe"
                aria-hidden="true"
              />

              <span style={{ fontSize: "1em" }}>
                <a
                  href={website}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#222222" }}
                >
                  {website}
                </a>
              </span>
            </div>
          </div>

          {Object.keys(socials).length ? (
            <>
              <hr />
              <div className="row">
                <div
                  className="col-sm-12 sellerProductIcons"
                  style={{
                    display: "flex",
                    gap: "2em",
                    justifyContent: centerAlign ? "center" : "unset",
                  }}
                >
                  {Object.keys(socials).map((socialMedia, idx) => (
                    <a
                      key={idx}
                      href={socials[socialMedia]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        style={{ fontSize: "2em" }}
                        className={`fa fa-${socialMedia}`}
                      />
                    </a>
                  ))}
                </div>
              </div>
            </>
          ) : null}

          <hr />

          <div
            className="row"
            style={!centerAlign ? { marginTop: "2em" } : null}
          >
            <div className="col-12 product-related">
              <h2
                style={{
                  textAlign: centerAlign ? "center" : "unset",
                  fontWeight: "500",
                  fontSize: "1.5em",
                }}
              >
                seller categories
              </h2>
            </div>
          </div>

          {sellerCategories.map((sellerCategory, idx) => {
            const { categoryLink, categoryProductImageSrcs } = sellerCategory;
            return (
              <React.Fragment key={idx}>
                <div
                  className="row"
                  style={{ rowGap: "2em", marginBottom: "2em" }}
                >
                  <div className="col-12">
                    <h3
                      style={{
                        margin: 0,
                        textAlign: centerAlign ? "center" : "unset",
                        fontSize: "1.2em",
                      }}
                    >
                      <Link
                        to={categoryLink}
                        style={{
                          textTransform: "uppercase",
                          color: "#ff4c3b",
                        }}
                      >
                        {categoryLink
                          .split("/")
                          .slice(2)
                          .map((part) => decodeURIComponent(part))
                          .join(" / ")}
                      </Link>
                    </h3>
                  </div>
                  {categoryProductImageSrcs.map((imageSrc, idx) => (
                    <div className="col-md-2" key={idx}>
                      <img className="img-fluid" alt="" src={imageSrc} />
                    </div>
                  ))}
                </div>
                <hr />
              </React.Fragment>
            );
          })}

          <div
            className="row"
            style={!centerAlign ? { marginTop: "2em" } : null}
          >
            <div className="col-12 product-related">
              <h2
                style={{
                  fontSize: "1.5em",
                  textAlign: centerAlign ? "center" : "unset",
                  fontWeight: "500",
                }}
              >
                featured products
              </h2>
            </div>
          </div>

          <ListedProducts increaseBy={12} seller={id} />
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sellers: state.data.sellers,
  dCategories: state.data.categories,
  dSubCategories: state.data.subCategories,
  dMainCategories: state.data.mainCategories,
});

export default connect(mapStateToProps)(Seller);
