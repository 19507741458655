import React from "react";
import { Link } from "react-router-dom";
import "./sellerItem.css";

export default function SellerItem(props) {
  const { seller, search } = props;

  return (
    <Link to={`${process.env.PUBLIC_URL}/sellers/${seller.id}`}>
      <div
        className="sellerItem"
        style={{
          height: "15em",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "black",
          padding: "1em",
          rowGap: "1em",
          boxShadow: "0 0 4px -1px black",
          transition: "transform 300ms ease-in-out",
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}${seller.logoImageSrc}`}
          alt="logo"
          style={{ maxHeight: "10em", maxWidth: "100%" }}
        />
        <h4
          style={
            search
              ? {
                  fontSize: "1em",
                }
              : null
          }
        >
          {seller.name}
        </h4>
      </div>
    </Link>
  );
}
