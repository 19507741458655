import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  getAllStores,
  getAllUsers,
  getTheUser,
  getUserById,
} from "../../api/handleData";
import axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { AuthContext } from "../contexts";

const Approvals = () => {
  const { user, token } = useContext(AuthContext);
  const navigate = useHistory();
  const [sellers, setSellers] = useState("");
  const [selectedRows, setSelectedRows] = useState(false);

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  if (selectedRows.length === 1) {
    swal({
      title: "Confirmation",
      text: "Do you wish to edit this store?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willEdit) => {
      if (willEdit) {
        const usrID = Object.assign({}, selectedRows);
        const theID = usrID[0].id.toString();
        navigate.push(`/stores/${theID}`);
      } else {
        console.log("Cancelled");
      }
    });
  }

  const seller = [
    {
      name: "Store Name",
      selector: (row) => row.name,
    },
    {
      name: "Shop Link",
      selector: (row) => row.website,
    },
    {
      name: "Approval State",
      selector: (row) => (row.is_approved ? "APPROVED" : "NOT APPROVED"),
    },
    {
      name: "Ranking",
      selector: (row) => (row.is_featured ? "FEATURED" : "NOT FEATURED"),
    },
  ];
  const getSell = async () => {
    if (token) {
      const sellData = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/sellers`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      //console.log("THE USER:", usrData.data[0])
      let data1 = sellData.data;
      let info = data1.filter((one) => one.is_approved == 0);
      let result = info.reverse();
      setSellers(result);
    }
  };
  useEffect(() => {
    getSell();

    //getAllStores(setSellers)
    // getAllUsers(setUsers)
  }, []);

  return user.is_admin ? (
    <DataTable
      direction="auto"
      columns={seller}
      data={sellers}
      onSelectedRowsChange={handleChange}
      fixedHeaderScrollHeight="300px"
      pagination
      selectableRows
      responsive
      subHeaderAlign="right"
      subHeaderWrap
    />
  ) : (
    <div>
      <h4>Sorry, you must be logged in as an admin to view this..</h4>
    </div>
  );
};

export default Approvals;
