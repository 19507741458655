export function singleLevelFuzzySearch(
  query,
  data,
  levelType,
  similarityThreshold = 0.3,
  searchDescription = false
) {
  const searchQuery = query.toLowerCase().trim();

  const results = searchLevel(
    searchQuery,
    data,
    levelType,
    similarityThreshold,
    searchDescription
  );

  const sortedResults = results.sort((a, b) => b.similarity - a.similarity);

  return sortedResults;
}

function levenshteinDistance(a, b) {
  const dp = Array(a.length + 1)
    .fill(null)
    .map(() => Array(b.length + 1).fill(null));

  for (let i = 0; i <= a.length; i++) {
    dp[i][0] = i;
  }

  for (let j = 0; j <= b.length; j++) {
    dp[0][j] = j;
  }

  for (let i = 1; i <= a.length; i++) {
    for (let j = 1; j <= b.length; j++) {
      const cost = a[i - 1] === b[j - 1] ? 0 : 1;
      dp[i][j] = Math.min(
        dp[i - 1][j] + 1,
        dp[i][j - 1] + 1,
        dp[i - 1][j - 1] + cost
      );
    }
  }

  return dp[a.length][b.length];
}

function searchLevel(
  searchQuery,
  levelData,
  itemType,
  similarityThreshold,
  searchDescription = false
) {
  const allResults = [];

  levelData.forEach((item) => {
    const includes =
      searchQuery.includes(item.name.toLowerCase()) ||
      item.name.toLowerCase().includes(searchQuery);

    if (includes) {
      allResults.push({ type: itemType, item: item, similarity: null });
    }

    // const nameSimilarityScore = levenshteinDistance(
    //   item.name.toLowerCase(),
    //   searchQuery
    // );
    // const nameMaxLength = Math.max(item.name.length, searchQuery.length);
    // const nameNormalizedSimilarity = 1 - nameSimilarityScore / nameMaxLength;

    // let similarity = nameNormalizedSimilarity;
    // if (searchDescription && item.description) {
    //   const descriptionSimilarityScore = levenshteinDistance(
    //     item.description.toLowerCase(),
    //     searchQuery
    //   );
    //   const descriptionMaxLength = Math.max(
    //     item.description.length,
    //     searchQuery.length
    //   );
    //   const descriptionNormalizedSimilarity =
    //     1 - descriptionSimilarityScore / descriptionMaxLength;
    //   similarity = Math.max(similarity, descriptionNormalizedSimilarity);
    // }

    // if (similarity >= similarityThreshold) {
    //   allResults.push({ type: itemType, item: item, similarity: similarity });
    // }
  });

  return allResults;
}

export function fuzzySearch(query, data, similarityThreshold = 0.3) {
  const { mainCategories, subCategories, categories, sellers, products } = data;
  const searchQuery = query.toLowerCase().trim();

  const mainCategoryResults = searchLevel(
    searchQuery,
    mainCategories,
    "mainCategory",
    similarityThreshold
  );
  const subCategoryResults = searchLevel(
    searchQuery,
    subCategories,
    "subCategory",
    similarityThreshold
  );
  const categoryResults = searchLevel(
    searchQuery,
    categories,
    "category",
    similarityThreshold
  );
  const sellerResults = searchLevel(
    searchQuery,
    sellers,
    "seller",
    similarityThreshold,
    true
  );
  const productResults = searchLevel(
    searchQuery,
    products,
    "product",
    similarityThreshold
  );

  const allResults = [
    ...mainCategoryResults,
    ...subCategoryResults,
    ...categoryResults,
    ...sellerResults,
    ...productResults,
  ];
  const sortedResults = allResults.sort((a, b) => b.similarity - a.similarity);

  return sortedResults;
}
