import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import Breadcrumb from "../components/breadcrumb";
import SearchResults from "../components/searchResults";

function Search(props) {
  const { data } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("q");

  if (searchQuery === null) return <Redirect to="/pages/404" />;

  return (
    <div>
      <Helmet>
        <title>Minkmall - Search Results</title>
      </Helmet>

      <Breadcrumb title={"Search Results"} />

      <section
        style={{
          paddingLeft: "2em",
          paddingRight: "2em",
          paddingBottom: 0,
          marginBottom: 0,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h4>
                Search Query:{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    color: "#ff4c3b",
                  }}
                >
                  {searchQuery}
                </span>{" "}
              </h4>
            </div>
          </div>
          <hr />
        </div>
      </section>

      <section
        className="section-b-space"
        style={{
          paddingLeft: "2em",
          paddingRight: "2em",
          paddingTop: 0,
          marginTop: 0,
        }}
      >
        <div className="container">
          <SearchResults searchQuery={searchQuery} data={data} />
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps)(Search);
