import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getCategoryObjectFromSellerByName } from "../utils/extract.js";
import { connect } from "react-redux";
import SellerCardSlider from "./sellerCardSlider";

function SellerCard(props) {
  const {
    logoImageSrc,
    socials,
    name,
    category,
    website,
    id,
    dCategories,
  } = props;

  const {
    categoryProductImageSrcs,
    categoryLink,
  } = getCategoryObjectFromSellerByName(category, dCategories, props);

  const isTablet = useMediaQuery({ maxWidth: 768 });
  const stack = useMediaQuery({ maxWidth: 1024 });

  return (
    <div
      className="col-md-5"
      style={{
        display: "flex",
        flexWrap: "wrap",
        boxShadow: "0 0 4px -1px #333",
        padding: "1em",
      }}
    >
      <div
        className="col-md-6 product-thumbnail sellerCardSliderParent"
        style={stack ? { flex: "0 0 100%", maxWidth: "100%" } : null}
      >
        <SellerCardSlider
          imageSrcs={categoryProductImageSrcs}
          showDots={true}
        />
      </div>

      <div
        className="col-md-6 rtl-text"
        style={stack ? { flex: "0 0 100%", maxWidth: "100%" } : null}
      >
        <div
          className="product-right"
          style={
            stack ? { textAlign: "center", margin: "10px 0 10px 0" } : null
          }
        >
          <img
            style={{
              maxHeight: "5em",
              maxWidth: "10em",
              marginBottom: "0.25em",
            }}
            src={`${process.env.PUBLIC_URL}${logoImageSrc}`}
            alt="seller logo"
          />
          <h2 style={{ fontSize: "1em" }}>{name}</h2>
          <div className="border-product">
            <div
              className="product-icon"
              style={stack ? { justifyContent: "center" } : null}
            >
              <ul className="product-social">
                {Object.keys(socials).map((socialMedia, idx) => (
                  <li key={idx}>
                    <a
                      href={socials[socialMedia]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        style={{ fontSize: "2em" }}
                        className={`fa fa-${socialMedia}`}
                      />
                    </a>
                  </li>
                ))}
                <li>
                  <a href={website} target="_blank" rel="noopener noreferrer">
                    <i style={{ fontSize: "2em" }} className={"fa fa-globe"} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="border-product">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1em",
                alignItems: isTablet || stack ? "center" : "normal",
              }}
            >
              <a
                style={{
                  maxWidth: "fit-content",
                  padding: ".75em",
                  fontSize: ".75rem",
                }}
                href={categoryLink}
                className="btn btn-solid"
                target="_blank"
                rel="noopener noreferrer"
              >
                Shop now
              </a>
              <Link
                style={{
                  maxWidth: "fit-content",
                  padding: ".75em",
                  fontSize: ".75rem",
                }}
                to={`/sellers/${id}`}
                className="btn btn-solid"
              >
                Visit Seller Profile
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({ dCategories: state.data.categories });

export default connect(mapStateToProps)(SellerCard);
