import React from "react";
import Breadcrumb from "../components/breadcrumb";
import { Helmet } from "react-helmet";

export default function ForgotPassword(props) {
  return (
    <div>
      <Helmet>
        <title>Minkmall - Forgot Password</title>
      </Helmet>

      <Breadcrumb title={"forgot password"} />

      {/*Forget Password section*/}
      <section className="pwd-page section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <h2>Forgot Your Password?</h2>
              <form className="theme-form">
                <div className="form-row">
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="Enter Your Email"
                      required=""
                    />
                  </div>
                  <a href="#" className="btn btn-solid">
                    Submit
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
