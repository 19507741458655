import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import ProductItem from "./productItem";
import { getSellerProducts, getFeaturedProducts } from "../utils/extract";
import { singleLevelFuzzySearch } from "../utils/search";
import EmptyResult from "./emptyResult";
import "./listedProducts.css";

function ListedProducts(props) {
  const { products, increaseBy } = props;
  const [lProducts, setLProducts] = useState(products);

  useEffect(() => {
    setLProducts(products);
  }, [products]);

  const [limit, setLimit] = useState(increaseBy);

  const handleLoadMore = () => {
    setLimit((prevLimit) => Math.min(prevLimit + increaseBy, products.length));
  };

  const searchQueryInput = useRef(null);
  const [showCloseSearchButton, setShowCloseSearchButton] = useState(false);

  const handleSearch = (event) => {
    event.preventDefault();
    setShowCloseSearchButton(true);
    const results = singleLevelFuzzySearch(
      searchQueryInput.current.value,
      products,
      "product"
    );
    setLProducts(results);
  };

  const closeSearch = () => {
    searchQueryInput.current.value = "";
    setShowCloseSearchButton(false);
    setLProducts(products);
  };

  return (
    <>
      {products.length > 0 && (
        <div className="row" style={{ rowGap: "1em", marginBottom: "2em" }}>
          <div className="col-12">
            <form
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onSubmit={handleSearch}
            >
              <div
                className="form-group searchListedProducts"
                style={{ margin: 0 }}
              >
                <input
                  ref={searchQueryInput}
                  type="text"
                  className="form-control"
                  placeholder="Search featured products..."
                  style={{ margin: 0 }}
                />
              </div>
              <button
                style={{
                  padding: "0.65em 1.5em",
                  border: "none",
                  background: "#ff4c3b",
                }}
                type="submit"
                className="btn btn-solid"
              >
                <i className="fa fa-search" />
              </button>
            </form>
          </div>

          {showCloseSearchButton && (
            <div className="col-12" style={{ textAlign: "right", padding: 0 }}>
              <i
                style={{ fontSize: "2em", color: "#ff4c3b", cursor: "pointer" }}
                onClick={closeSearch}
                className="fa fa-window-close"
                aria-hidden="true"
              ></i>
            </div>
          )}
        </div>
      )}

      {products.length ? (
        lProducts.length ? (
          <div className="row search-product" style={{ rowGap: "3em" }}>
            {lProducts.slice(0, limit).map((product, index) => {
              const searchProduct = Object.hasOwnProperty.call(
                product,
                "similarity"
              );
              return (
                <div key={index} className="col-xl-2 col-md-4 col-sm-6">
                  <ProductItem
                    product={searchProduct ? product.item : product}
                    key={index}
                  />
                </div>
              );
            })}
            {limit < lProducts.length && (
              <div className="col-12" style={{ textAlign: "center" }}>
                {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a className="btn btn-solid" onClick={handleLoadMore}>
                  <i
                    className="fa fa-refresh"
                    aria-hidden="true"
                    style={{ marginRight: "2ch" }}
                  ></i>
                  load more
                </a>
              </div>
            )}
          </div>
        ) : (
          <EmptyResult />
        )
      ) : (
        <div className="row">
          <div className="col-sm-12">
            <div style={{ padding: 0 }} className="error-section">
              <h2>please check back later</h2>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { featured, seller } = ownProps;

  if (featured) {
    return { products: getFeaturedProducts(state.data.products) };
  } else if (seller) {
    return { products: getSellerProducts(seller, state.data.products) };
  }

  return { products: state.data.products };
};

export default connect(mapStateToProps)(ListedProducts);
