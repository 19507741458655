import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import "./index.scss";
import "./style.css";

// Features
import Layout from "./components/app";

// Extra Pages
import aboutUs from "./minkmall/pages/about-us";
import PageNotFound from "./minkmall/pages/404";
import Login from "./minkmall/pages/login";
import Register from "./minkmall/pages/register";
import ForgotPassword from "./minkmall/pages/forgot-password";
import Contact from "./minkmall/pages/contact";

// Minkmall
import Minkmall from "./minkmall/App";
import BecomeASeller from "./minkmall/pages/becomeASeller/index.jsx";
import Seller from "./minkmall/pages/seller";
import Dashboard from "./minkmall/admin/Dashboard";
import Category from "./minkmall/pages/category";
import { fetchData } from "./minkmall/redux/dataActions";
import store from "./minkmall/redux/store";
import Search from "./minkmall/pages/search";
import AddCategory from "./minkmall/pages/addCategory";
import EditCategories from "./minkmall/pages/editCategories";

import Apply from "./minkmall/pages/Apply";
import Allproducts from "./minkmall/pages/Allproducts";
import EditSeller from "./minkmall/admin/EditSeller";
import EditUser from "./minkmall/admin/EditUser";
import EditProduct from "./minkmall/admin/EditProduct";
import EditUsrAdmin from "./minkmall/admin/EditUsrAdmin";
import ApplyForSeller from "./minkmall/pages/ApplyForSeller";

import { getTheUser } from "./api/handleData";
import { AuthContext } from "./minkmall/contexts";
import Cookies from "js-cookie";

function Root() {
  useEffect(() => {
    const { dispatch } = store;

    dispatch(fetchData("mainCategories"));
    dispatch(fetchData("subCategories"));
    dispatch(fetchData("categories"));
    dispatch(fetchData("sellers"));
    dispatch(fetchData("products"));
  }, []);

  const [user, setUser] = useState(null);

  useEffect(() => {
    getTheUser(setUser);
  }, []);

  const [token, setToken] = useState("");

  useEffect(() => {
    const checkforCookie = Cookies.get("LOGGEDIN");
    if (checkforCookie) {
      const details = JSON.parse(checkforCookie);
      setToken(details.token);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, token, setToken }}>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollContext>
            <Layout>
              <Switch>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  component={Minkmall}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/sellers/:sellerId`}
                  component={Seller}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/users/:id`}
                  component={EditUser}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user/edit/:id`}
                  component={EditUsrAdmin}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/seller2`}
                  component={ApplyForSeller}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/stores/:id`}
                  component={EditSeller}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/products/:id`}
                  component={EditProduct}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/pages/404`}
                  component={PageNotFound}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/become-a-seller`}
                  component={BecomeASeller}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/apply`}
                  component={Apply}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/products`}
                  component={Allproducts}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/about-us`}
                  component={aboutUs}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/login`}
                  component={Login}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/register`}
                  component={Register}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/search`}
                  component={Search}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/forgot-password`}
                  component={ForgotPassword}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/contact`}
                  component={Contact}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin/add-category`}
                  component={AddCategory}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin/edit-categories`}
                  component={EditCategories}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/c/:mainCategory`}
                  component={Category}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/c/:mainCategory/:subCategory`}
                  component={Category}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/c/:mainCategory/:subCategory/:category`}
                  component={Category}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin/dashboard`}
                  component={Dashboard}
                />
                <Route path="*" component={PageNotFound} />
              </Switch>
            </Layout>
          </ScrollContext>
        </BrowserRouter>
      </Provider>
    </AuthContext.Provider>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
