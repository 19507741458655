import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { getAllProducts } from '../../api/handleData';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';


const Allproducts = () => {
  const [products, setProducts] = useState('');
  const [selectedRows, setSelectedRows] = useState(false);
  const navigate = useHistory();

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  if (selectedRows.length === 1) {
    swal({
      title: "Confirmation",
      text: "Do you wish to edit this product?",
      icon: "warning",
      buttons: true,
      dangerMode: true,

    })
      .then((willEdit) => {
        if (willEdit) {
          const usrID = Object.assign({}, selectedRows)
          const theID = usrID[0].id.toString();
          navigate.push(`/products/${theID}`)
        }
        else {
          console.log("Cancelled")
        }
      });
  }


  const columns = [
    {
      name: 'Product Name',
      selector: row => row.name,
    },
    {
      name: 'Price',
      selector: row => row.price,
    },
    {
      name: 'Category',
      selector: row => row.category,
    },
    {
      name: 'Feature Status',
      selector: row => row.is_featured ? "FEATURED" : "NOT FEATURED",
    }
  ];

  useEffect(() => {
    getAllProducts(setProducts)
  }, [])


  console.log("PRODS:", products)

  return (
    <div>
      <DataTable
        direction='auto'
        columns={columns}
        data={products}
        onSelectedRowsChange={handleChange}
        fixedHeaderScrollHeight="300px"
        pagination
        selectableRows
        responsive
        subHeaderAlign="right"
        subHeaderWrap
      />
      <h3>Total Products: {products.length}</h3>
    </div>
  )
}

export default Allproducts