import React, { useState, useContext, createContext, useRef } from "react";
import { connect } from "react-redux";
import { buildMainCategoriesTrees } from "../utils/extract";
import { Helmet } from "react-helmet";
import Breadcrumb from "../components/breadcrumb";
import Modal from "react-responsive-modal";
import "../components/loader.css";
import axios from "axios";
import { fetchData } from "../redux/dataActions.js";
import store from "../redux/store.js";
import { AuthContext } from "../contexts";
import Unauthorized from "./unauthorized";

const CategoryModalContext = createContext(null);

function MainCategoryDropDown({ mainCategory, cnt }) {
  const [show, setShow] = useState(false);
  const {
    setModalOpen,
    setCategoryLevel,
    setCategoryTree,
    setCategoryParentId,
  } = useContext(CategoryModalContext);

  const toggleShow = () => {
    setShow((prevShow) => !prevShow);
  };

  return (
    <li style={{ textTransform: "uppercase" }}>
      <span className="categoryDropDown" onClick={toggleShow}>
        {mainCategory.name}
        <i
          style={{ paddingLeft: "1ch" }}
          className={`fa fa-caret-${show ? "up" : "down"}`}
          aria-hidden="true"
        ></i>
      </span>
      <ul
        style={{
          marginLeft: "2ch",
          display: show ? "flex" : "none",
          flexDirection: "column",
          gap: "0.5em",
        }}
      >
        <li style={{ marginTop: "0.5em" }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <a
            className="btn btn-solid"
            style={{
              fontSize: "0.8em",
              maxWidth: "fit-content",
              padding: "2px 1ch",
            }}
            onClick={() => {
              setModalOpen(true);
              setCategoryLevel("sub category");
              setCategoryTree(mainCategory.name);
              setCategoryParentId({
                mainCategory: mainCategory.id,
              });
            }}
          >
            <i
              className="fa fa-plus"
              aria-hidden="true"
              style={{ marginRight: "1ch" }}
            ></i>
            sub category
          </a>
        </li>

        {mainCategory.builtSubCategories.map((subCategory, idx) => (
          <SubCategoryDropDown
            key={idx}
            subCategory={subCategory}
            mainCategory={mainCategory}
            cnt={idx}
          />
        ))}
      </ul>
    </li>
  );
}

function SubCategoryDropDown({ subCategory, mainCategory, cnt }) {
  const [show, setShow] = useState(false);
  const {
    setModalOpen,
    setCategoryLevel,
    setCategoryTree,
    setCategoryParentId,
  } = useContext(CategoryModalContext);

  const toggleShow = () => {
    setShow((prevShow) => !prevShow);
  };

  return (
    <li style={{ textTransform: "uppercase" }}>
      <span className="categoryDropDown" onClick={toggleShow}>
        {subCategory.name}
        <i
          style={{ paddingLeft: "1ch" }}
          className={`fa fa-caret-${show ? "up" : "down"}`}
          aria-hidden="true"
        ></i>
      </span>
      <ul
        style={{
          marginLeft: "2ch",
          display: show ? "flex" : "none",
          flexDirection: "column",
          gap: "0.5em",
        }}
      >
        <li style={{ marginTop: "0.5em" }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <a
            className="btn btn-solid"
            style={{
              fontSize: "0.8em",
              maxWidth: "fit-content",
              padding: "2px 1ch",
            }}
            onClick={() => {
              setModalOpen(true);
              setCategoryLevel("category");
              setCategoryTree(`${mainCategory.name} / ${subCategory.name}`);
              setCategoryParentId({
                mainCategory: mainCategory.id,
                subCategory: subCategory.id,
              });
            }}
          >
            <i
              className="fa fa-plus"
              aria-hidden="true"
              style={{ marginRight: "1ch" }}
            ></i>
            category
          </a>
        </li>

        {subCategory.builtCategories.map((category, idx) => (
          <li key={idx} style={{ textTransform: "uppercase" }}>
            {category.name}
          </li>
        ))}
      </ul>
    </li>
  );
}

function CategoriesSelector(props) {
  const { mainCategories } = props;

  const { setModalOpen, setCategoryLevel, setCategoryParentId } = useContext(
    CategoryModalContext
  );

  return (
    <div className="col-12">
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
          padding: "1em 1em 0",
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a
          className="btn btn-solid"
          style={{
            fontSize: "0.8em",
            maxWidth: "fit-content",
            padding: "2px 1ch",
          }}
          onClick={() => {
            setModalOpen(true);
            setCategoryLevel("main category");
            setCategoryParentId(null);
          }}
        >
          <i
            className="fa fa-plus"
            aria-hidden="true"
            style={{ marginRight: "1ch" }}
          ></i>
          main category
        </a>

        {mainCategories.map((mainCategory, idx) => (
          <MainCategoryDropDown
            key={idx}
            mainCategory={mainCategory}
            cnt={idx}
          />
        ))}
      </ul>
    </div>
  );
}

function Banner({ imageSrc }) {
  return (
    <div className="collection-banner">
      <div className="img-part">
        <img
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            aspectRatio: "2.1678135432602677",
          }}
          src={imageSrc}
          alt=""
          className="img-fluid blur-up lazyload bg-img"
        />
      </div>
    </div>
  );
}

function CategoryModal({ mainCategories }) {
  const [loading, setLoading] = useState(false);
  const {
    modalOpen,
    setModalOpen,
    categoryLevel,
    categoryTree,
    setCategoryTree,
    categoryParentId,
  } = useContext(CategoryModalContext);
  const { token } = useContext(AuthContext);

  const [errors, setErrors] = useState([]);

  const onCloseModal = () => {
    setModalOpen(false);
    resetForm();
  };

  const nameRef = useRef();
  const imageRef = useRef();

  const resetForm = () => {
    setLoading(false);
    nameRef.current.value = "";
    imageRef.current.value = "";
    setErrors([]);
    setCategoryTree("");
    setImagePreview("");
  };

  const cleanName = (str) =>
    str
      .trim()
      .split(" ")
      .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join(" ");

  const validateForm = () => {
    const categoryName = nameRef.current.value,
      categoryBackgroundImage = imageRef.current.value;

    const _errors = [];

    if (
      !categoryName ||
      (typeof categoryName === "string" && categoryName.trim().length === 0)
    )
      _errors.push(`${categoryLevel} name not provided`);

    // if (!categoryBackgroundImage)
    //   _errors.push(`${categoryLevel} background image not provided`);

    if (categoryLevel === "main category") {
      const alreadyPresent = mainCategories.some((mainCategory) => {
        return (
          mainCategory.name.toLowerCase() === categoryName.trim().toLowerCase()
        );
      });

      if (alreadyPresent)
        _errors.push(`${categoryLevel} with same name already exists`);
    } else if (categoryLevel === "sub category") {
      const mainCategory = mainCategories.find(
        (mainCategory) => mainCategory.id === categoryParentId["mainCategory"]
      );

      const alreadyPresent = mainCategory.builtSubCategories.some(
        (subCategory) => {
          return (
            subCategory.name.toLowerCase() === categoryName.trim().toLowerCase()
          );
        }
      );

      if (alreadyPresent)
        _errors.push(`${categoryLevel} with same name already exists`);
    } else if (categoryLevel === "category") {
      const mainCategory = mainCategories.find(
        (mainCategory) => mainCategory.id === categoryParentId["mainCategory"]
      );

      const subCategory = mainCategory.builtSubCategories.find(
        (subCategory) => subCategory.id === categoryParentId["subCategory"]
      );

      const alreadyPresent = subCategory.builtCategories.some((category) => {
        return (
          category.name.toLowerCase() === categoryName.trim().toLowerCase()
        );
      });

      if (alreadyPresent)
        _errors.push(`${categoryLevel} with same name already exists`);
    }

    return _errors;
  };

  const handleSubmit = async () => {
    setErrors([]);
    const _errors = validateForm();
    if (_errors.length > 0) {
      setErrors(_errors);
      return;
    }

    setLoading(true);

    const categoryName = cleanName(nameRef.current.value),
      categoryBackgroundImage = imageRef.current.files[0];

    const form = new FormData();
    form.append("categoryName", categoryName);

    if (categoryBackgroundImage)
      form.append("categoryBackgroundImage", categoryBackgroundImage);

    form.append("categoryLevel", categoryLevel);
    form.append("categoryParentId", JSON.stringify(categoryParentId));

    await axios
      .post(`${process.env.REACT_APP_BACKEND}/api/admin/add-category`, form, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const { dispatch } = store;
        switch (categoryLevel) {
          case "main category":
            dispatch(fetchData("mainCategories"));
            break;
          case "sub category":
            dispatch(fetchData("subCategories"));
            break;
          case "category":
            dispatch(fetchData("categories"));
            break;
          default:
            dispatch(fetchData("mainCategories"));
            dispatch(fetchData("subCategories"));
            dispatch(fetchData("categories"));
        }

        resetForm();
      })
      .catch((err) => {
        console.error(err);
        setErrors(["form upload failed, please try again later"]);
        setLoading(false);
      });
  };

  const [imagePreview, setImagePreview] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal open={modalOpen} onClose={onCloseModal} center>
      <div>
        <h4
          style={{ textTransform: "capitalize" }}
        >{`${categoryLevel} details`}</h4>
        <hr />
        {categoryTree && (
          <>
            <p style={{ textTransform: "uppercase" }}>{categoryTree}</p>
            <hr />
          </>
        )}

        {errors.length > 0
          ? errors.map((error, idx) => (
            <p
              key={idx}
              style={{ color: "red", textTransform: "capitalize" }}
            >
              {error}
            </p>
          ))
          : null}

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="form-group">
            <label
              htmlFor="category-name"
              style={{ fontSize: "1.2em", textTransform: "capitalize" }}
            >
              {`${categoryLevel} name`}
            </label>
            <input
              type="text"
              name="category-name"
              className="form-control"
              placeholder={`Enter ${categoryLevel} name`}
              ref={nameRef}
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="category-background-image"
              style={{ fontSize: "1.2em", textTransform: "capitalize" }}
            >
              {`${categoryLevel} background picture`}
            </label>
            <p>
              <small>
                <span>&#9432;</span> Dimensions: 672x310
              </small>
            </p>
            <input
              type="file"
              name="category-background-image"
              className="form-control"
              aria-label="category background image"
              ref={imageRef}
              onChange={handleImageUpload}
            />
            {imagePreview && <Banner imageSrc={imagePreview} />}
          </div>

          <div
            style={{
              display: "flex",
              columnGap: "1em",
              alignItems: "center",
            }}
          >
            <button
              type="button"
              className="btn btn-solid"
              style={{ padding: "6px 12px" }}
              onClick={handleSubmit}
              disabled={loading}
            >
              submit
            </button>

            {loading && <div className="minkmall-spinner-loader" />}
          </div>
        </form>
      </div>
    </Modal>
  );
}

function AddCategory(props) {
  const { user } = useContext(AuthContext);

  const { mainCategories } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [categoryLevel, setCategoryLevel] = useState("category");
  const [categoryTree, setCategoryTree] = useState("");
  const [categoryParentId, setCategoryParentId] = useState(null);

  return !(user && (user.is_admin || user.role === "ADMINISTRATOR")) ? (
    <Unauthorized />
  ) : (
    <div>
      <Helmet>
        <title>{`Minkall - Admin: Add Category`}</title>
      </Helmet>

      <Breadcrumb title="add category" parent="admin" />

      <section className="section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p style={{ color: "#222222" }}>
                Use the buttons in the drop down below to add a category in the
                different levels (main category, sub category or category)
              </p>
            </div>
          </div>

          <div className="row">
            <CategoryModalContext.Provider
              value={{
                modalOpen,
                setModalOpen,
                categoryLevel,
                setCategoryLevel,
                categoryTree,
                setCategoryTree,
                categoryParentId,
                setCategoryParentId,
              }}
            >
              <CategoryModal mainCategories={mainCategories} />
              <CategoriesSelector mainCategories={mainCategories} />
            </CategoryModalContext.Provider>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  mainCategories: buildMainCategoriesTrees(
    state.data.mainCategories,
    state.data.subCategories,
    state.data.categories
  ),
});

export default connect(mapStateToProps)(AddCategory);
