import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import { getAllStores, getAllUsers } from "../../api/handleData";
import axios from "axios";
import swal from "sweetalert";
import { AuthContext } from "../contexts";

const Allstores = () => {
  const { user } = useContext(AuthContext);
  const [sellers, setSellers] = useState("");
  const [selectedRows, setSelectedRows] = useState(false);
  const navigate = useHistory();

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };
  if (selectedRows.length === 1) {
    swal({
      title: "Confirmation",
      text: "Do you wish to edit this store?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willEdit) => {
      if (willEdit) {
        const usrID = Object.assign({}, selectedRows);
        const theID = usrID[0].id.toString();
        navigate.push(`/stores/${theID}`);
      } else {
        console.log("Cancelled");
      }
    });
  }

  const columns = [
    {
      name: "Seller Name",
      selector: (row) => row.name,
    },
    {
      name: "Shop Link",
      selector: (row) => row.website,
    },
    {
      name: "Approval State",
      selector: (row) => (row.is_approved ? "APPROVED" : "NOT APPROVED"),
    },
    {
      name: "Date Created",
      selector: (row) => row.created,
    },
  ];

  useEffect(() => {
    getAllStores(setSellers);
  }, []);

  //console.log("SELLERS:", sellers)
  console.log("THE THE USER:", user);

  return user.is_admin ? (
    <div>
      <DataTable
        direction="auto"
        columns={columns}
        data={sellers}
        onSelectedRowsChange={handleChange}
        fixedHeaderScrollHeight="300px"
        pagination
        selectableRows
        responsive
        subHeaderAlign="right"
        subHeaderWrap
      />
      <h3>Total Shops: {sellers.length}</h3>
    </div>
  ) : (
    <div>
      <h4>Sorry, you must be logged in as an admin to view this..</h4>
    </div>
  );
};

export default Allstores;
