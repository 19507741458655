import Cookies from "js-cookie";
import axios from "axios";
import swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import { error } from "./toast";
import emailkey from "../services/emailkey";
var checkforCookie = Cookies.get("LOGGEDIN");

export const getTheUser = async (state) => {
  const checkforCookie = Cookies.get("LOGGEDIN");
  if (checkforCookie) {
    const details = JSON.parse(checkforCookie);
    const usrData = await axios.get(
      `${process.env.REACT_APP_BACKEND}/users/${details.userID}`,
      { headers: { Authorization: `Bearer ${details.token}` } }
    );
    //console.log("THE USER:", usrData.data[0])
    state(usrData.data[0]);
  }
};
export const verifyEmail = async () => {
  let number = Math.floor(100000 + Math.random() * 900000);
  console.log("WHATS NUMBER:", number);

  var message = {
    from_name: `Verify Your Email through one time pin`,
    message: `Greetings, your Verification Code is: ${number}`,
  };

  await emailjs
    .send(emailkey.SERVICE_ID, emailkey.TEMPLATE_ID, message, emailkey.USER_ID)
    .then(
      function(response) {
        //swal.fire("Success", "Thanks for contacting us", "success")
        console.log("EMAIL SUCCESS!", response.status, response.text);
      },
      function(error) {
        //swal.fire("Error", "An error occured", "error")
        console.log("EMAIL FAILED...", error);
      }
    );
};
export const colors = [
  { value: "black", label: "black" },
  { value: "white", label: "white" },
  { value: "red", label: "red" },
  { value: "maroon", label: "maroon" },
  { value: "blue", label: "blue" },
  { value: "yellow", label: "yellow" },
  { value: "pink", label: "pink" },
  { value: "green", label: "green" },
  { value: "brown", label: "brown" },
  { value: "orange", label: "orange" },
  { value: "silver", label: "silver" },
  { value: "gold", label: "gold" },
  { value: "indigo", label: "indigo" },
  { value: "violet", label: "violet" },
  { value: "teal", label: "teal" },
  { value: "cyan", label: "cyan" },
];
export const categories = [
  {
    label: "fashion",
    value: "fashion",
    children: [
      {
        label: "men",
        value: "men",
        children: [
          {
            label: "shirts",
            value: "shirts",
          },
          {
            label: "khaki",
            value: "khaki",
          },
          {
            label: "jeans",
            value: "jeans",
          },
          {
            label: "trousers",
            value: "trousers",
          },
          {
            label: "sweaters",
            value: "sweaters",
          },
        ],
      },
      {
        label: "women",
        value: "women",
        children: [
          {
            label: "blouses",
            value: "blouses",
          },
          {
            label: "skirts",
            value: "skirts",
          },
          {
            label: "jeans",
            value: "jeans",
          },
          {
            label: "dresses",
            value: "dresses",
          },
          {
            label: "sweaters",
            value: "sweaters",
          },
        ],
      },
    ],
  },
  {
    label: "appliances",
    value: "appliances",
    children: [
      {
        label: "large appliances",
        value: "large appliances",
        children: [
          {
            label: "refridgerators",
            value: "refridgerators",
          },
          {
            label: "washing machines",
            value: "washing machines",
          },
          {
            label: "freezers",
            value: "freezers",
          },
          {
            label: "cookers",
            value: "cookers",
          },
          {
            label: "ovens",
            value: "ovens",
          },
        ],
      },
    ],
  },
];
export const maincategs = [{ label: "fashion", options: subcategs }];
export const subcategs = [{ label: "clothing", options: categs }];
export const categs = [
  { value: "dresses", label: "dresses" },
  { value: "jackets", label: "jackets" },
];
export const getUserById = async (id) => {
  const checkforCookie = Cookies.get("LOGGEDIN");
  if (checkforCookie) {
    const details = JSON.parse(checkforCookie);
    var usrData = await axios.get(
      `${process.env.REACT_APP_BACKEND}/users/${id}`,
      { headers: { Authorization: `Bearer ${details.token}` } }
    );
    console.log("THE USER:", usrData);
  }
  return JSON.stringify(usrData.data[0].phone);
};
export const getStoreCategory = async (state, userID) => {
  const checkforCookie = Cookies.get("LOGGEDIN");
  if (checkforCookie) {
    const details = JSON.parse(checkforCookie);
    const storeData = await axios.get(
      `${process.env.REACT_APP_BACKEND}/api/sellers`,
      { headers: { Authorization: `Bearer ${details.token}` } }
    );
    if (storeData.data)
      var singleCateg = storeData.data.filter(
        (str) => str.posted_by === userID.id
      );
    //let catResponse = JSON.parse(singleCateg)

    var looking = singleCateg
      .map((e) => e.categories)
      .filter((r) => r !== null);

    // var seek = looking.values()

    // for (var value of seek) {
    //   //console.log(value);
    // }

    let almost = looking ? looking[0] : "No category";

    // let mwisho = almost.map(e => e.split("/"))

    let final = almost.map((r) => r.category);

    //console.log("RETDATA:", final)

    //console.log("STORE CATEG:", looking.values())
    //console.log("USER ID:", userID)
    state(final);
  }
};
export const getSwalUserById = async (id) => {
  const checkforCookie = Cookies.get("LOGGEDIN");
  if (checkforCookie) {
    const details = JSON.parse(checkforCookie);
    var usrData = await axios.get(
      `${process.env.REACT_APP_BACKEND}/users/${id}`,
      { headers: { Authorization: `Bearer ${details.token}` } }
    );
    //console.log("GETTING SWAL USER:", usrData.data)
    // if (usrData.data.length !== 0) {
    //   console.log("USR DATA USER", usrData.data)
    // }
    // else {
    //   console.log("THE USER:", usrData)
    // }
  }
  return usrData.data[0].id;
};
export const getAllUsers = async (state) => {
  const checkforCookie = Cookies.get("LOGGEDIN");
  if (checkforCookie) {
    const details = JSON.parse(checkforCookie);
    const usrData = await axios.get(`${process.env.REACT_APP_BACKEND}/users`, {
      headers: { Authorization: `Bearer ${details.token}` },
    });
    //console.log("THE USER:", usrData.data[0])
    let newDt = usrData.data.reverse();
    state(newDt);
  }
};
export const getAllStores = async (state) => {
  const checkforCookie = Cookies.get("LOGGEDIN");
  if (checkforCookie) {
    const details = JSON.parse(checkforCookie);
    const usrData = await axios.get(
      `${process.env.REACT_APP_BACKEND}/api/sellers`,
      { headers: { Authorization: `Bearer ${details.token}` } }
    );
    //console.log("THE USER:", usrData.data[0])
    state(usrData.data);
  }
};
export const getAllProducts = async (state) => {
  const allProducts = await axios.get(
    `${process.env.REACT_APP_BACKEND}/api/products`
  );
  state(allProducts.data.reverse());
};
export const getProductsByUserId = async (state, userID) => {
  const allProducts = await axios.get(
    `${process.env.REACT_APP_BACKEND}/api/products`
  );
  let userProducts =
    allProducts.data && allProducts.data.filter((pr) => pr.seller == userID);
  let final = userProducts.reverse();
  state(final);
};
export const logout = (redirect, setUser, setToken) => {
  Cookies.remove("LOGGEDIN");
  setUser(null);
  setToken(null);
  redirect.push("/pages/login");
};
export const registerSwal = async (setUser, setToken) => {
  swal
    .fire({
      title: "Create your account",
      html: `<input type="text" id="fname" class="swal2-input" placeholder="First Name">
    <input type="text" id="lname" class="swal2-input" placeholder="Last Name">
    <input type="tel" id="phone" class="swal2-input" placeholder="Phone" >
    <input type="email" id="email" class="swal2-input" placeholder="Email">
    <input type="password" id="password" class="swal2-input" placeholder="Password">
    <input type="password" id="password2" class="swal2-input" placeholder="Confirm Password">`,
      confirmButtonText: "Register",
      allowOutsideClick: false,
      focusConfirm: false,
      preConfirm: () => {
        const fname = swal.getPopup().querySelector("#fname").value;
        const lname = swal.getPopup().querySelector("#lname").value;
        const phone = swal.getPopup().querySelector("#phone").value;
        const email = swal.getPopup().querySelector("#email").value;
        const password = swal.getPopup().querySelector("#password").value;
        const password2 = swal.getPopup().querySelector("#password2").value;
        //const validate = swal.showValidationMessage(`Invalid something`)
        if (!/^0(?:1|7|2)(?:\d{8})$/.test(phone)) {
          swal.showValidationMessage(
            `Invalid Phone number use 07.. | 01.. | 02..`
          );
        }
        if (!fname || !lname || !phone || !email || !password || !password2) {
          swal.showValidationMessage(`Please fill all the fields`);
        } else if (password !== password2) {
          swal.showValidationMessage(`Passwords do not match`);
        }
        return {
          fname: fname,
          lname: lname,
          phone: phone,
          email: email,
          password: password,
          password2: password2,
        };
      },
    })
    .then(async (result) => {
      //first register the user
      let usr = {
        first_name: result.value.fname,
        last_name: result.value.lname,
        phone: result.value.phone,
        email: result.value.email,
        password: result.value.password,
      };

      let loginDetails = {
        email: result.value.email,
        password: result.value.password,
      };
      //login the user & change state
      let reg = await axios.post(`${process.env.REACT_APP_BACKEND}/users`, usr);

      if (reg.data == "User created successfully") {
        let loggedIn = await axios.post(
          `${process.env.REACT_APP_BACKEND}/login`,
          loginDetails
        );
        // console.log("CREATED SWAL USR:", reg.data)
        // console.log("LOGGED IN OR NOt:", loggedIn)

        if (loggedIn.data.userID !== 0) {
          Cookies.set("LOGGEDIN", JSON.stringify(loggedIn.data), {
            expires: 1,
          });

          let user,
            token = loggedIn.data.token;
          try {
            const usrData = await axios.get(
              `${process.env.REACT_APP_BACKEND}/users/${loggedIn.data.userID}`,
              { headers: { Authorization: `Bearer ${loggedIn.data.token}` } }
            );
            user = usrData.data[0];
          } catch (e) {
            user = {
              ...JSON.parse(window.atob(token.split(".")[1])),
              id: loggedIn.data.userID,
            };
          }

          setUser(user);
          setToken(token);

          swal.fire(
            "Success!",
            "Account details saved, now send the application",
            "success"
          );
          sendEmail(
            result.value.fname + " " + result.value.lname,
            result.value.phone,
            "User Registration"
          );
        } else {
          error("ERR on reg SWAL");
        }
      } else {
        error("An error occured");
        console.log("POP REG", reg.data);
      }
    });
};
export const loginSwal = async (setUser, setToken) => {
  swal
    .fire({
      title: "Login to your account",
      html: `<input type="email" id="login" class="swal2-input" placeholder="Email">
    <input type="password" id="password" class="swal2-input" placeholder="Password">`,
      confirmButtonText: "Login",
      allowOutsideClick: false,
      focusConfirm: false,
      preConfirm: () => {
        const email = swal.getPopup().querySelector("#login").value;
        const password = swal.getPopup().querySelector("#password").value;
        if (!email || !password) {
          swal.showValidationMessage(`Please enter login and password`);
        }
        return { email: email, password: password };
      },
    })
    .then(async (result) => {
      let loginDetails = {
        email: result.value.email,
        password: result.value.password,
      };

      let loggedIn = await axios.post(
        `${process.env.REACT_APP_BACKEND}/login`,
        loginDetails
      );
      if (loggedIn.data.userID !== 0) {
        Cookies.set("LOGGEDIN", JSON.stringify(loggedIn.data), { expires: 1 });

        let user,
          token = loggedIn.data.token;
        try {
          const usrData = await axios.get(
            `${process.env.REACT_APP_BACKEND}/users/${loggedIn.data.userID}`,
            { headers: { Authorization: `Bearer ${loggedIn.data.token}` } }
          );
          user = usrData.data[0];
        } catch (e) {
          user = {
            ...JSON.parse(window.atob(token.split(".")[1])),
            id: loggedIn.data.userID,
          };
        }

        setUser(user);
        setToken(token);

        swal.fire("Done", "Now send your application", "success");
      } else error("ERR on loginSWAL");
    });
};

export const sendEmail = async (name, phone, title, meso) => {
  var message = {
    from_name: `New, ${title} from ${name} Phone: ${phone}`,
    message: meso ? meso : `Login to your dashboard for more information`,
  };

  await emailjs
    .send(emailkey.SERVICE_ID, emailkey.TEMPLATE_ID, message, emailkey.USER_ID)
    .then(
      function(response) {
        //swal.fire("Success", "Thanks for contacting us", "success")
        console.log("EMAIL SUCCESS!", response.status, response.text);
      },
      function(error) {
        //swal.fire("Error", "An error occured", "error")
        console.log("EMAIL FAILED...", error);
      }
    );
};
