import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CategoriesModalContext } from "../contexts";
import { buildMainCategoryTreeByName } from "../utils/extract";
import { connect } from "react-redux";

function Banner({ imageSrc, name, search }) {
  return (
    <div className="collection-banner p-right text-right">
      <div className="img-part">
        <img
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            aspectRatio: "2.1678135432602677",
          }}
          src={`${process.env.PUBLIC_URL}${imageSrc}`}
          alt=""
          className="img-fluid blur-up lazyload bg-img"
        />
      </div>
      <div className="contain-banner banner-3">
        <div>
          <h2
            style={
              search
                ? {
                    color: "white",
                    textShadow: "1px 1px black",
                    fontSize: "1.5em",
                  }
                : {
                    color: "white",
                    textShadow: "1px 1px black",
                  }
            }
          >
            {name}
          </h2>
        </div>
      </div>
    </div>
  );
}

function CategoryBanner(props) {
  const {
    category,
    home,
    mainCategories,
    subCategories,
    categories,
    search,
  } = props;
  const { imageSrc, name, link } = category;

  const mainCategoryTree = home
    ? buildMainCategoryTreeByName(
        name,
        mainCategories,
        subCategories,
        categories
      )
    : null;

  const modalSettings = useContext(CategoriesModalContext);

  const handleBannerClick = () => {
    modalSettings.setCategoryName(name);
    modalSettings.setCategoryTree(mainCategoryTree);
    modalSettings.setCategoriesModalOpen(true);
  };

  return home ? (
    <div style={{ cursor: "pointer" }} onClick={handleBannerClick}>
      <Banner imageSrc={imageSrc} name={name} />
    </div>
  ) : (
    <Link to={link}>
      <Banner imageSrc={imageSrc} name={name} search={search} />
    </Link>
  );
}

const mapStateToProps = (state) => ({
  categories: state.data.categories,
  subCategories: state.data.subCategories,
  mainCategories: state.data.mainCategories,
});

export default connect(mapStateToProps)(CategoryBanner);
