import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { buildMainCategoriesTrees } from "../utils/extract";
import { useMediaQuery } from "react-responsive";
import { logout } from "../../api/handleData";
import "./navbar.css";
import { AuthContext } from "../contexts";

function MainCategoryDropDown({ mainCategory, hamburgerMenuMode }) {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((prevShow) => !prevShow);
  };

  return (
    <li style={{ margin: hamburgerMenuMode ? "1ch 0 0 2ch" : "1ch 0" }}>
      <span
        className="categoryDropDown"
        onClick={toggleShow}
        style={hamburgerMenuMode ? { textTransform: "uppercase" } : null}
      >
        {mainCategory.name}
        <i
          style={{ paddingLeft: "1ch" }}
          className={`fa fa-caret-${show ? "up" : "down"}`}
          aria-hidden="true"
        ></i>
      </span>
      <ul style={{ margin: "1ch 0 0 2ch", display: show ? "block" : "none" }}>
        {mainCategory.builtSubCategories.map((subCategory, idx) => (
          <SubCategoryDropDown
            key={idx}
            subCategory={subCategory}
            hamburgerMenuMode={hamburgerMenuMode}
          />
        ))}
      </ul>
    </li>
  );
}

function SubCategoryDropDown({ subCategory, hamburgerMenuMode }) {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((prevShow) => !prevShow);
  };

  return (
    <li style={{ margin: "1ch 0" }}>
      <span
        className="categoryDropDown"
        onClick={toggleShow}
        style={hamburgerMenuMode ? { textTransform: "uppercase" } : null}
      >
        {subCategory.name}
        <i
          style={{ paddingLeft: "1ch" }}
          className={`fa fa-caret-${show ? "up" : "down"}`}
          aria-hidden="true"
        ></i>
      </span>
      <ul style={{ marginLeft: "2ch", display: show ? "block" : "none" }}>
        {subCategory.builtCategories.map((category, idx) => (
          <li key={idx}>
            <Link style={{ padding: 0 }} to={category.link}>
              {category.name}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
}

function NavBar(props) {
  const { mainCategories } = props;
  const [navClose, setNavClose] = useState({ right: "0px" });
  const { user, setUser, setToken } = useContext(AuthContext);
  const navigate = useHistory();

  useEffect(() => {
    if (window.innerWidth < 750) {
      setNavClose({ right: "-410px" });
    } else if (window.innerWidth < 1199) {
      setNavClose({ right: "-300px" });
    }
  }, []);

  const openNav = () => {
    setNavClose({ right: "0px" });
  };

  const closeNav = () => {
    setNavClose({ right: "-410px" });
  };

  const handleSubmenu = (event) => {
    event.currentTarget.nextElementSibling.classList.toggle("opensubmenu");
  };

  const hamburgerMenuMode = useMediaQuery({ maxWidth: 1199 });

  return (
    <div>
      <div className="main-navbar">
        <div id="mainnav">
          <div className="toggle-nav" onClick={openNav}>
            <i className="fa fa-bars sidebar-bar" />
          </div>
          <ul className="nav-menu" style={navClose}>
            <li className="back-btn" onClick={closeNav}>
              <div className="mobile-back text-right">
                <span>Back</span>
                <i className="fa fa-angle-right pl-2" aria-hidden="true" />
              </div>
            </li>
            {hamburgerMenuMode ? (
              user ? (
                <li>
                  <div style={{ backgroundColor: "#333", padding: "1em" }}>
                    <h4
                      style={{
                        color: "white",
                        padding: 0,
                        margin: 0,
                        textTransform: "capitalize",
                        fontWeight: "500",
                      }}
                    >
                      <i
                        style={{ paddingRight: "1ch" }}
                        className="fa fa-user"
                        aria-hidden="true"
                      />
                      {`Welcome ${user.first_name}`}
                    </h4>
                  </div>
                </li>
              ) : null
            ) : null}
            <li>
              <Link to={`${process.env.PUBLIC_URL}/`} className="nav-link">
                home
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className="nav-link"
                onClick={(e) => handleSubmenu(e)}
              >
                e-shop
                <i
                  style={{ paddingLeft: "1ch" }}
                  className="fa fa-caret-down"
                  aria-hidden="true"
                ></i>
              </Link>
              <ul
                style={{
                  padding: hamburgerMenuMode ? "0 15px" : "15px",
                  maxHeight: hamburgerMenuMode ? "unset" : "60vh",
                  overflowY: "auto",
                  width: "max-content",
                }}
                className="nav-submenu"
              >
                {mainCategories.map((mainCategory, idx) => (
                  <MainCategoryDropDown
                    key={idx}
                    mainCategory={mainCategory}
                    hamburgerMenuMode={hamburgerMenuMode}
                  />
                ))}
              </ul>
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/pages/about-us`}
                className="nav-link"
              >
                about
              </Link>
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/pages/contact`}
                className="nav-link"
              >
                contact
              </Link>
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/pages/become-a-seller`}
                className="nav-link"
              >
                become a seller
              </Link>
            </li>
            {hamburgerMenuMode ? (
              <li>
                <Link
                  to="#"
                  className="nav-link"
                  onClick={(e) => handleSubmenu(e)}
                >
                  my account
                  <i
                    style={{ paddingLeft: "1ch" }}
                    className="fa fa-caret-down"
                    aria-hidden="true"
                  ></i>
                </Link>
                <ul className="nav-submenu">
                  {user ? (
                    <>
                      <li>
                        <Link className="myAccount" to="/admin/dashboard">
                          Dashboard
                        </Link>
                      </li>
                      {(user.is_admin || user.role === "ADMINISTRATOR") && (
                        <>
                          <li>
                            <Link
                              className="myAccount"
                              to="/admin/add-category"
                            >
                              Add Category
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="myAccount"
                              to="/admin/edit-categories"
                            >
                              Edit Categories
                            </Link>
                          </li>
                        </>
                      )}
                      <li onClick={() => logout(navigate, setUser, setToken)}>
                        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                        <a className="myAccount">Logout</a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link className="myAccount" to="/pages/login">
                          Log In
                        </Link>
                      </li>
                      <li>
                        <Link className="myAccount" to="/pages/register">
                          Register
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  mainCategories: buildMainCategoriesTrees(
    state.data.mainCategories,
    state.data.subCategories,
    state.data.categories
  ),
});

export default connect(mapStateToProps)(NavBar);
