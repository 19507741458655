import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { storeSchema } from '../../api/fromSchemas';
import { uploadFile, uploadGallery } from '../../api/uploadFiles'
import CategoriesSelector from '../components/categoriesSelector'

const ApplyForSeller = () => {
  const [image, setImage] = useState(null);
  const [hero, setHero] = useState(null);
  const [cert, setCert] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const submitApplication = (fm) => {
    setLoading(true);
    console.log("WHAT IS FORM RETURNING?::", fm)
    let cats = categories && categories.map((e) => {
      return (
        {
          category: e.category,
          categoryProductImageSrcs: [
            "/assets/minkmall/products/1.jpg",
            "/assets/minkmall/products/2.jpg",
            "/assets/minkmall/products/3.jpg",
            "/assets/minkmall/products/4.jpg"
          ],
          categoryLink: fm.cat_link
        }
      )
    }
    )

    console.log("CATEGORIES:", JSON.stringify(cats))
    setLoading(false);
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <Formik
            initialValues={{
              name: "",
              facebook: "",
              socials: {
                instagram: "",
                whatsapp: "",
                telephone: ""
              },

              website: "",
              email: "",
              location: "",
              motto: "",
              description: "",
              cat_link: ""
            }}
            onSubmit={submitApplication}
            validationSchema={storeSchema}
            validateOnChange
          >
            <Form className="theme-form">
              <div className="form-row">
                <div className="form-row">
                  <div className="col-md-6">
                    <label htmlFor="name"> Your Shop Name</label>
                    <ErrorMessage
                      name='name'
                      className='validator'
                      component='div' />
                    <Field
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    {/* <i className='icon-image' onClick={() => uploadFile(setImage, "Upload shop logo")}>{image ? "LOGO UPLOADED" : "Upload Shop Logo"}</i> */}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="facebook">Facebook Link</label>
                    <ErrorMessage
                      name='facebook'
                      className='validator'
                      component='div' />
                    <Field
                      type="text"
                      className="form-control"
                      name="facebook"
                      required
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="instagram">Instagram Link</label>
                    <ErrorMessage
                      name='instagram'
                      className='validator'
                      component='div' />
                    <Field
                      type="text"
                      className="form-control"
                      name="instagram"
                      required
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="instagram">Whatsapp</label>
                    <ErrorMessage
                      name='whatsapp'
                      className='validator'
                      component='div' />
                    <Field
                      type="text"
                      className="form-control"
                      name="whatsapp"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label htmlFor="phone">Your Shop Link/Website</label>
                  <ErrorMessage
                    name='website'
                    className='validator'
                    component='div' />
                  <Field
                    type="text"
                    className="form-control"
                    name="website"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="phone">Choose your Categories</label>
                  {/* <CreatableSelect isMulti options={categs} /> */}
                  {/* <DropdownTreeSelect data={categories} onChange={onChange} onAction={onAction} onNodeToggle={onNodeToggle} /> */}
                  <CategoriesSelector getSelectedIds={setCategories} />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email">Shop Phone</label>
                  <ErrorMessage
                    name='telephone'
                    className='validator'
                    component='div' />
                  <Field
                    type="text"
                    className="form-control"
                    id="telephone"
                    name="telephone"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email">Shop Email</label>
                  <ErrorMessage
                    name='email'
                    className='validator'
                    component='div' />
                  <Field
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email">Shop Location</label>
                  <ErrorMessage
                    name='location'
                    className='validator'
                    component='div' />
                  <Field
                    type="text"
                    className="form-control"
                    id="location"
                    name="location"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email">Shop Bio</label>
                  <ErrorMessage
                    name='motto'
                    className='validator'
                    component='div' />
                  <Field
                    type="text"
                    className="form-control"
                    id="motto"
                    name="motto"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email">Shop Description</label>
                  <ErrorMessage
                    name='description'
                    className='validator'
                    component='div' />
                  <Field
                    type="text"
                    className="form-control"
                    id="description"
                    name="description"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email">Type your best selling product category (from your website)</label>
                  <Field
                    type="url"
                    className="form-control"
                    id="cat_link"
                    name="cat_link"
                  />
                </div>
              </div>
              <div className="col-md-6">
                {/* <i className='icon-image' onClick={() => uploadGallery(setHero)}>{hero ? "SHOP PICTURES UPLOADED" : "Click to upload your shop images"}</i> */}
              </div>
              <div className="col-md-6">
                {/* <i className='icon-image' onClick={() => uploadFile(setCert, "Upload a certificate/other business file")}>{cert ? "CERTIFICATE UPLOADED" : "Click to upload your verification document"}</i> */}
              </div>

              {loading ?
                <h3>Sending...</h3>
                :
                <button type="submit" className="btn btn-solid">
                  Send For Approval
                </button>
              }
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default ApplyForSeller