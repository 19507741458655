import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { getProductsByUserId } from "../../../api/handleData";
import { connect } from "react-redux";

const SingleProduct = ({ userID }) => {
  const [selectedRows, setSelectedRows] = useState(false);
  const [prods, setProds] = useState();
  const navigate = useHistory();

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const columns = [
    {
      name: "Product Name",
      selector: (row) => row.name,
    },
    {
      name: "Price",
      selector: (row) => row.price,
    },
    {
      name: "Ranking",
      selector: (row) => (row.is_featured ? "FEATURED" : "NOT FEATURED"),
    },
    {
      name: "Date Created",
      selector: (row) => row.created_at,
    },
  ];

  if (selectedRows.length === 1) {
    swal({
      title: "Confirmation",
      text: "Do you wish to edit your product?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willEdit) => {
      if (willEdit) {
        const usrID = Object.assign({}, selectedRows);
        const theID = usrID[0].id.toString();
        navigate.push(`/products/${theID}`);
      } else {
        console.log("Cancelled");
      }
    });
  }

  useEffect(() => {
    getProductsByUserId(setProds, userID);
  }, []);

  return (
    <div>
      <DataTable
        direction="auto"
        columns={columns}
        data={prods}
        onSelectedRowsChange={handleChange}
        fixedHeaderScrollHeight="300px"
        pagination
        selectableRows
        responsive
        subHeaderAlign="right"
        subHeaderWrap
      />
    </div>
  );
};

export default connect((state) => ({ categories: state.data.categories }))(
  SingleProduct
);
