import React, { useContext } from "react";
// import TabPanel from '../components/TabsPanel';
// import Tab from '../components/Tab'
import { Tabs, Tab } from "react-bootstrap-tabs";
import { useHistory, Link } from "react-router-dom";
import { logout } from "../../../api/handleData";
import Allproducts from "../../pages/Allproducts";
import Allstores from "../../pages/Allstores";
import Approvals from "../../pages/Approvals";
import UploadProduct from "../../pages/UploadProduct";
import Users from "../../pages/users";
import { AuthContext } from "../../contexts";

const Admin = () => {
  const { user, setUser, setToken } = useContext(AuthContext);
  const navigate = useHistory();
  return (
    <Tabs
      onSelect={(index, label) => console.log(label + "selected")}
      selected={1}
    >
      <Tab label="Account Info">
        <div>
          <div className="dashboard-right">
            <div className="dashboard">
              <div className="page-title">
                <h2>My Dashboard</h2>
              </div>
              <div className="welcome-msg">
                <p>Hello, {user ? user.first_name : "Loading.."}</p>
                <p>
                  From your My Account Dashboard you have the ability to view a
                  snapshot of your recent account activity and update your
                  account information. Select a link below to view or edit
                  information.
                </p>
              </div>
              <div className="box-account box-info">
                <div className="box-head">
                  <h2>Account Information</h2>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="box">
                      <div className="box-title">
                        <h3>Contact Information</h3>
                      </div>
                      <div className="box-content">
                        <h6>
                          {user
                            ? user.first_name + " " + user.last_name
                            : "Loading.."}
                        </h6>
                        <h6>{user ? user.email : "Loading.."}</h6>
                        <h6 className="validator">
                          {user ? user.phone : "Loading.."}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="box">
                      <div className="box-title">
                        <h3>Profile Picture</h3>
                      </div>
                      <div className="box-content">
                        <img src={user ? user.profile_image : ""} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box">
                    <div className="box-title">
                      {/* <Link to="#">My Addresses</Link> */}
                    </div>
                    <div className="row">
                      {/* <div className="col-sm-6">
                        <h6>Change Your Password</h6>
                        <address>
                          <Link to="#">Edit Password</Link>
                        </address>
                      </div> */}
                      {/* <div className="col-sm-6">
                        <h6>My Address</h6>
                        <address>
                          <br />
                        </address>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tab>
      <Tab label="Approvals">
        <Approvals />
      </Tab>
      <Tab label="All Products">
        <Allproducts />
      </Tab>
      <Tab label="All Users">
        <Users />
      </Tab>
      <Tab label="All Shops">
        <Allstores />
      </Tab>
      <Tab label="Upload Product">
        <UploadProduct />
      </Tab>
      <Tab label="Logout">
        <div>
          <button onClick={() => logout(navigate, setUser, setToken)}>
            Press to Logout
          </button>
        </div>
      </Tab>
    </Tabs>
  );
};

export default Admin;
