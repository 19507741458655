import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import TabPanel from "./components/TabsPanel";
import * as Yup from "yup";
import { Field, Form, Formik, ErrorMessage, useField } from "formik";
import axios from "axios";
import Tab from "./components/Tab";
import { getAllProducts, getAllStores } from "../../api/handleData";
import swal from "sweetalert";
import Upload from "./components/Upload";
import Allproducts from "../pages/Allproducts";
import Users from "../pages/users";
import Allstores from "../pages/Allstores";
import Approvals from "../pages/Approvals";
import Admin from "./UserAccounts/Admin";
import Supplier from "./UserAccounts/Supplier";
import Customer from "./UserAccounts/Customer";
import { useMediaQuery } from "react-responsive";
import { AuthContext } from "../contexts";

export default function Dashboard() {
  const { user } = useContext(AuthContext);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState({ data: "" });
  const [products, setProducts] = useState();
  const navigate = useHistory();
  const fileRef = useRef(null);

  const isPhone = useMediaQuery({ maxWidth: 577 });
  const isTablet = useMediaQuery({ maxWidth: 768 });

  const storeSchema = Yup.object().shape({
    name: Yup.string()
      .required("Store Name is required.")
      .min(4, "Field should be at least 4 characters.")
      .max(60, "Oops that's too long maximum is 60 letters"),
    shop_link: Yup.string()
      .required("Shop Link is required.")
      .min(4, "Field should be at least 4 characters."),
    categories: Yup.string()
      .required("Category is required.")
      .min(10, "Minimum of 10characters"),
    facebook: Yup.string().min(4, "Field should be at least 4 characters."),
    instagram: Yup.string().min(4, "Field should be at least 4 characters."),
    tiktok: Yup.string().min(4, "Field should be at least 4 characters."),
    whatsapp: Yup.string()
      .required("Whatsapp Link is required.")
      .min(4, "Field should be at least 4 characters."),
  });

  const submitApplication = async (form, e) => {
    setLoading(true);
    //collect form data
    let submission = {
      name: form.name,
      store_logo: image.data,
      //product_image:"Later",
      socials: {
        facebook: form.facebook,
        instagram: form.instagram,
        whatsapp: form.whatsapp,
      },
      shop_link: form.shop_link,
      categories: form.categories,
      //verification_docs: "Collect from state"
    };
    //validate and get images here

    //send the form to backend
    // await axios.post(`${process.env.REACT_APP_BACKEND}/api/sellers`, submission)
    //   .then(res => {
    //     console.log("Store RES:", res)
    //     swal("Success", "Form Sent Successfully", "success")
    //   }
    //   )
    //   .catch(err => console.log("Store Err:", err))
    console.log("SHOP APPLICATION:", submission);
    setLoading(false);
  };

  const handleLogo = (e) => {
    let img = { data: e.target.files[0] };
    setImage(img);
  };

  useEffect(() => {
    getAllProducts(setProducts);
    getAllStores(setStores);
  }, []);

  //console.log("DATAA:", user)
  return (
    <div>
      {user ? (
        <div>
          {/*Dashboard section*/}
          <section className="section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="account-sidebar">
                    <a className="popup-btn" />
                  </div>
                  {isPhone ? (
                    <div>
                      {user.is_admin && <Admin />}
                      {user.is_supplier && <Supplier stores={stores} />}
                      {!user.is_admin && !user.is_supplier && (
                        <Customer stores={stores} />
                      )}
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="dashboard-left">
                    <div className="collection-mobile-back">
                      <span className="filter-back">
                        <i className="" aria-hidden="true"></i> back
                      </span>
                    </div>
                    <div className="block-content">
                      {!isPhone && !isTablet ? (
                        <div>
                          {user.is_admin && <Admin />}
                          {user.is_supplier && <Supplier stores={stores} />}
                          {!user.is_admin && !user.is_supplier && (
                            <Customer stores={stores} />
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <section className="p-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="error-section">
                  <h1>404</h1>
                  <h2>page not found</h2>
                  <Link to="/" className="btn btn-solid">
                    back to home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
