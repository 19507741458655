import React, { useState } from "react";
import axios from "axios";
import { Field, Form, Formik, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import Breadcrumb from "../components/breadcrumb";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { verifyEmail } from "../../api/handleData";
import { regSchema } from "../../api/fromSchemas";

export default function Register() {
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");

  const [secondPass, setSecondPass] = useState("");
  const [image, setImage] = useState({ preview: "", data: "" });
  const navigate = useHistory();

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };

  const handleSecondPass = (e) => {
    setSecondPass(e.target.value);
  };

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(img);
  };

  const handleRegister = async (form) => {
    setLoading(true);

    if (passwordInput === secondPass) {
      //console.log("WHERE IS IMAGE:", image.data);
      const regDetails = {
        //profile_image: image.data,
        first_name: form.first_name,
        last_name: form.last_name,
        phone: form.phone,
        email: form.email,
        password: passwordInput,
      };
      //console.log("REG DETAILS:", regDetails)
      await axios
        .post(`${process.env.REACT_APP_BACKEND}/users`, regDetails)
        .then((res) => {
          if (res.data == "User created successfully") {
            form.first_name = "";
            form.last_name = "";
            form.phone = "";
            form.email = "";
            setLoading(false);
            swal("Karibu", "Registration Successful, now login", "success");
            navigate.push("/pages/login");
          } else {
            swal("Sorry", "Internal Server Error", "error");
            console.log("SERVER MONITOR:", res);
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err) {
            swal("Sorry", "Internal Server Error", "error");
            console.log("WHATS THE PROBLEM:", err);
            setLoading(false);
          }
        });

      //console.log("REGISTRATION:", sendForm)
    } else if (passwordInput !== secondPass) {
      swal("Oops", "Passwords don't match", "error");
      setLoading(false);
    }
    // else {
    //   swal("Sorry", "Your profile image is required", "error");
    //   setLoading(false)
    // }
  };

  return (
    <div>
      <Helmet>
        <title>Minkmall - Register for Account</title>
      </Helmet>

      <Breadcrumb title={"create account"} />

      {/* Register section */}
      <section className="register-page section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3>create account</h3>
              {/* <button onClick={() => verifyEmail()}>Verify Email</button> */}
              <div className="theme-card">
                <Formik
                  initialValues={{
                    first_name: "",
                    last_name: "",
                    phone: "",
                    email: "",
                    password: "",
                  }}
                  validateOnChange
                  onSubmit={handleRegister}
                  validationSchema={regSchema}
                >
                  <Form className="theme-form">
                    <div className="form-row">
                      {/* <div className="col-md-9">
                        <label htmlFor="fname">Profile Image</label>
                        <input
                          type="file"
                          name="profile_image"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </div> */}
                      <div className="col-md-6">
                        <label htmlFor="fname">First Name</label>
                        <ErrorMessage
                          className="validator"
                          name="first_name"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="fname"
                          name="first_name"
                          placeholder="First Name"
                          required=""
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lname">Last Name</label>
                        <ErrorMessage
                          className="validator"
                          name="last_name"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="lname"
                          name="last_name"
                          placeholder="Last Name"
                          required=""
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="email">Email</label>
                        <ErrorMessage
                          className="validator"
                          name="email"
                          component="div"
                        />
                        <Field
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email"
                          required=""
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phone">Phone Number</label>
                        <ErrorMessage
                          className="validator"
                          name="phone"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          required=""
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="password">Password</label>
                        <div
                          className="col-12"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                        >
                          <div
                            className="col-11"
                            style={{
                              padding: 0,
                            }}
                          >
                            <input
                              type={passwordType}
                              className="form-control"
                              id="password"
                              name="password"
                              onChange={handlePasswordChange}
                              placeholder="Password"
                              required=""
                            />
                          </div>
                          <div
                            className="col-1"
                            style={{
                              padding: 0,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {passwordType === "password" ? (
                              <i
                                onClick={togglePassword}
                                className="fa fa-eye"
                                style={{
                                  transform: "translateY(-100%)",
                                }}
                              />
                            ) : (
                              <i
                                onClick={togglePassword}
                                style={{
                                  transform: "translateY(-100%)",
                                }}
                                className="fa fa-lock"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="password2">Repeat Password</label>
                        <div
                          className="col-12"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                        >
                          <div
                            className="col-11"
                            style={{
                              padding: 0,
                            }}
                          >
                            <input
                              type={passwordType}
                              className="form-control"
                              id="password2"
                              name="password2"
                              onChange={handleSecondPass}
                              placeholder="Repeat password"
                              required=""
                            />
                          </div>
                          <div
                            className="col-1"
                            style={{
                              padding: 0,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {passwordType === "password" ? (
                              <i
                                onClick={togglePassword}
                                className="fa fa-eye"
                                style={{
                                  transform: "translateY(-100%)",
                                }}
                              />
                            ) : (
                              <i
                                onClick={togglePassword}
                                style={{
                                  transform: "translateY(-100%)",
                                }}
                                className="fa fa-lock"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {loading ? (
                      <h3>Creating...</h3>
                    ) : (
                      <button type="submit" className="btn btn-solid">
                        Create Account
                      </button>
                    )}
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
