import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { SlideUpDown } from "../../services/script";
import LogoImage from "./logo";

export default function Footer(props) {
  useEffect(() => {
    var contentwidth = window.innerWidth;
    if (contentwidth < 750) {
      SlideUpDown("footer-title");
    } else {
      var elems = document.querySelectorAll(".footer-title");
      [].forEach.call(elems, function(elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: block";
      });
    }
  }, []);

  return (
    <div className="footer-white">
      <footer className="footer-light">
        <div className="light-layout">
          <div className="container">
            <section className="small-section border-section border-top-0">
              <div className="row">
                <div className="col-lg-6">
                  <div className="subscribe">
                    <div>
                      <h4>KNOW IT ALL FIRST!</h4>
                      <p>
                        Never Miss Anything From Minkmall By Signing Up To Our
                        Newsletter.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <form className="form-inline subscribe-form">
                    <div className="form-group mx-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter your email"
                      />
                    </div>
                    <button type="submit" className="btn btn-solid">
                      subscribe
                    </button>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section className="section-b-space light-layout">
          <div className="container">
            <div className="row footer-theme partition-f">
              <div className="col-lg-4 col-md-6">
                <div className="footer-title footer-mobile-title">
                  <h4>about</h4>
                </div>
                <div className="footer-contant">
                  <div className="footer-logo">
                    <LogoImage logo="/assets/minkmall/logo.webp" />
                  </div>
                  <p>
                    We are your most preferred online directory for all
                    made-in-Kenya products.
                  </p>
                  <div className="footer-social">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com">
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com">
                          <i className="fa fa-twitter" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://instagram.com">
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col offset-xl-1">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>my account</h4>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/pages/login`}>
                          Login
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/pages/register`}>
                          Register
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/pages/forgot-password`}
                        >
                          Forgot Password
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>quick access</h4>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/pages/about-us`}>
                          About
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/pages/contact`}>
                          Contact Us
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/pages/become-a-seller`}
                        >
                          Become a Seller
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>store information</h4>
                  </div>
                  <div className="footer-contant">
                    <ul className="contact-list">
                      <li>
                        <i className="fa fa-map-marker" />
                        Minkmall Store
                      </li>
                      <li>
                        <i className="fa fa-phone" />
                        Reach Out To Us
                      </li>
                      <li>
                        <i className="fa fa-envelope-o" />
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                        Email Us: <a href="#">support@minkmall.co.ke</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="sub-footer ">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-md-6 col-sm-12">
                <div className="footer-end">
                  <p>
                    <i className="fa fa-copyright" aria-hidden="true" /> 2023
                    Minkmall. By{" "}
                    <a href="https://techrahisi.co.ke">Techrahisi</a>
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <div className="payment-card-bottom">
                  <ul>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a href="#">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/visa.png`}
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a href="#">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/mastercard.png`}
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a href="#">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/paypal.png`}
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a href="#">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/american-express.png`}
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a href="#">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/discover.png`}
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
