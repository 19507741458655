import React from "react";
import { Link } from "react-router-dom";
import "./breadcrumb.css";

function CategoryPageLinked({ parent }) {
  const links = parent.split("/").reduce((accum, curr, idx) => {
    let link,
      name = curr;

    if (idx === 0) link = `/c/${encodeURIComponent(curr)}`;
    else {
      const [_, prevLink] = accum[idx - 1];
      link = `${prevLink}/${encodeURIComponent(curr)}`;
    }

    accum.push([name, link]);
    return accum;
  }, []);

  return links.map((parentPortion, idx) => {
    const [name, link] = parentPortion;
    return (
      <li key={idx} className="breadcrumb-item" aria-current="page">
        <Link className="breadcrumbLinkedPortion" to={link}>
          {name}
        </Link>
      </li>
    );
  });
}

function Breadcrumb(props) {
  const { title, parent, categoryPage } = props;

  return (
    <div className="breadcrumb-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="page-title">
              <h2>{title}</h2>
            </div>
          </div>
          <div className="col-md-6">
            <nav aria-label="breadcrumb" className="theme-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="breadcrumbLinkedPortion" to="/">
                    Home
                  </Link>
                </li>
                {parent ? (
                  categoryPage ? (
                    <CategoryPageLinked parent={parent} />
                  ) : (
                    parent.split("/").map((parentPortion, idx) => (
                      <li
                        key={idx}
                        className="breadcrumb-item"
                        aria-current="page"
                      >
                        {parentPortion}
                      </li>
                    ))
                  )
                ) : null}
                <li className="breadcrumb-item active" aria-current="page">
                  {title}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Breadcrumb;
