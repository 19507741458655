import React, { useState, useContext, createContext, useRef } from "react";
import { connect } from "react-redux";
import { buildMainCategoriesTrees } from "../utils/extract";
import { Helmet } from "react-helmet";
import Breadcrumb from "../components/breadcrumb";
import Modal from "react-responsive-modal";
import "../components/loader.css";
import axios from "axios";
import { fetchData } from "../redux/dataActions.js";
import store from "../redux/store.js";
import Unauthorized from "./unauthorized";
import { AuthContext } from "../contexts";

const CategoryModalContext = createContext(null);

function MainCategoryDropDown({ mainCategory, cnt }) {
  const [show, setShow] = useState(false);
  const {
    setModalOpen,
    setCategoryLevel,
    setCategoryTree,
    setCategoryId,
    setModalMode,
    setCategoryName,
    setCategoryImageSrc,
  } = useContext(CategoryModalContext);

  const toggleShow = () => {
    setShow((prevShow) => !prevShow);
  };

  return (
    <li
      style={{
        textTransform: "uppercase",
        marginTop: cnt === 0 ? "0.5em" : "unset",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className="categoryDropDown" onClick={toggleShow}>
          {mainCategory.name}
          <i
            style={{ paddingLeft: "1ch" }}
            className={`fa fa-caret-${show ? "up" : "down"}`}
            aria-hidden="true"
          ></i>
        </span>
        <i
          className="fa fa-pencil"
          aria-hidden="true"
          title="Edit"
          style={{ marginLeft: "2.5ch", color: "#ff4c3b", cursor: "pointer" }}
          onClick={() => {
            setModalOpen(true);
            setCategoryLevel("main category");
            setCategoryTree(mainCategory.name);
            setCategoryId({
              mainCategory: mainCategory.id,
            });
            setModalMode("edit");
            setCategoryName(mainCategory.name);
            setCategoryImageSrc(mainCategory.imageSrc);
          }}
        />
        {mainCategory.builtSubCategories.length === 0 && (
          <i
            className="fa fa-trash"
            aria-hidden="true"
            title="Delete"
            style={{ marginLeft: "2.5ch", color: "#ff4c3b", cursor: "pointer" }}
            onClick={() => {
              setModalOpen(true);
              setCategoryLevel("main category");
              setCategoryTree(mainCategory.name);
              setCategoryId({
                mainCategory: mainCategory.id,
              });
              setModalMode("delete");
              setCategoryName(mainCategory.name);
              setCategoryImageSrc(mainCategory.imageSrc);
            }}
          />
        )}
      </div>

      <ul
        style={{
          marginLeft: "2ch",
          display: show ? "flex" : "none",
          flexDirection: "column",
          gap: "0.5em",
        }}
      >
        {mainCategory.builtSubCategories.map((subCategory, idx) => (
          <SubCategoryDropDown
            key={idx}
            subCategory={subCategory}
            mainCategory={mainCategory}
            cnt={idx}
          />
        ))}
      </ul>
    </li>
  );
}

function SubCategoryDropDown({ subCategory, mainCategory, cnt }) {
  const [show, setShow] = useState(false);
  const {
    setModalOpen,
    setCategoryLevel,
    setCategoryTree,
    setCategoryId,
    setModalMode,
    setCategoryName,
    setCategoryImageSrc,
  } = useContext(CategoryModalContext);

  const toggleShow = () => {
    setShow((prevShow) => !prevShow);
  };

  return (
    <li
      style={{
        textTransform: "uppercase",
        marginTop: cnt === 0 ? "0.5em" : "unset",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className="categoryDropDown" onClick={toggleShow}>
          {subCategory.name}
          <i
            style={{ paddingLeft: "1ch" }}
            className={`fa fa-caret-${show ? "up" : "down"}`}
            aria-hidden="true"
          ></i>
        </span>
        <i
          className="fa fa-pencil"
          aria-hidden="true"
          title="Edit"
          style={{ marginLeft: "2.5ch", color: "#ff4c3b", cursor: "pointer" }}
          onClick={() => {
            setModalOpen(true);
            setCategoryLevel("sub category");
            setCategoryTree(`${mainCategory.name} / ${subCategory.name}`);
            setCategoryId({
              mainCategory: mainCategory.id,
              subCategory: subCategory.id,
            });
            setModalMode("edit");
            setCategoryName(subCategory.name);
            setCategoryImageSrc(subCategory.imageSrc);
          }}
        />
        {subCategory.builtCategories.length === 0 && (
          <i
            className="fa fa-trash"
            aria-hidden="true"
            title="Delete"
            style={{ marginLeft: "2.5ch", color: "#ff4c3b", cursor: "pointer" }}
            onClick={() => {
              setModalOpen(true);
              setCategoryLevel("sub category");
              setCategoryTree(`${mainCategory.name} / ${subCategory.name}`);
              setCategoryId({
                mainCategory: mainCategory.id,
                subCategory: subCategory.id,
              });
              setModalMode("delete");
              setCategoryName(subCategory.name);
              setCategoryImageSrc(subCategory.imageSrc);
            }}
          />
        )}
      </div>
      <ul
        style={{
          marginLeft: "2ch",
          display: show ? "flex" : "none",
          flexDirection: "column",
          gap: "0.5em",
        }}
      >
        {subCategory.builtCategories.map((category, idx) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: idx === 0 ? "0.5em" : "unset",
            }}
            key={idx}
          >
            <li style={{ textTransform: "uppercase" }}>{category.name}</li>
            <i
              className="fa fa-pencil"
              aria-hidden="true"
              title="Edit"
              style={{
                marginLeft: "2.5ch",
                color: "#ff4c3b",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalOpen(true);
                setCategoryLevel("category");
                setCategoryTree(
                  `${mainCategory.name} / ${subCategory.name} / ${category.name}`
                );
                setCategoryId({
                  mainCategory: mainCategory.id,
                  subCategory: subCategory.id,
                  category: category.id,
                });
                setModalMode("edit");
                setCategoryName(category.name);
                setCategoryImageSrc(category.imageSrc);
              }}
            />
            <i
              className="fa fa-trash"
              aria-hidden="true"
              title="Delete"
              style={{
                marginLeft: "2.5ch",
                color: "#ff4c3b",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalOpen(true);
                setCategoryLevel("category");
                setCategoryTree(
                  `${mainCategory.name} / ${subCategory.name} / ${category.name}`
                );
                setCategoryId({
                  mainCategory: mainCategory.id,
                  subCategory: subCategory.id,
                  category: category.id,
                });
                setModalMode("delete");
                setCategoryName(category.name);
                setCategoryImageSrc(category.imageSrc);
              }}
            />
          </div>
        ))}
      </ul>
    </li>
  );
}

function CategoriesSelector(props) {
  const { mainCategories } = props;

  return (
    <div className="col-12">
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
          padding: "1em 1em 0",
        }}
      >
        {mainCategories.map((mainCategory, idx) => (
          <MainCategoryDropDown
            key={idx}
            mainCategory={mainCategory}
            cnt={idx}
          />
        ))}
      </ul>
    </div>
  );
}

function Banner({ imageSrc }) {
  return (
    <div className="collection-banner">
      <div className="img-part">
        <img
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            aspectRatio: "2.1678135432602677",
          }}
          src={imageSrc}
          alt=""
          className="img-fluid blur-up lazyload bg-img"
        />
      </div>
    </div>
  );
}

function CategoryModal({ mainCategories }) {
  const [loading, setLoading] = useState(false);
  const {
    modalOpen,
    setModalOpen,
    categoryLevel,
    categoryTree,
    setCategoryTree,
    categoryId,
    modalMode,
    categoryName,
    categoryImageSrc,
    setCategoryName,
    setCategoryImageSrc,
  } = useContext(CategoryModalContext);
  const { token } = useContext(AuthContext);

  const [errors, setErrors] = useState([]);

  const onCloseModal = () => {
    setModalOpen(false);
    resetForm();
  };

  const nameRef = useRef();
  const imageRef = useRef();

  const resetForm = () => {
    setLoading(false);
    nameRef.current.value = "";
    imageRef.current.value = "";
    setErrors([]);
    setCategoryTree("");
    setShowEditName(false);
    setShowEditImage(false);
    setCategoryName("");
    setCategoryImageSrc("");
    setImagePreview("");
  };

  const cleanName = (str) =>
    str
      .trim()
      .split(" ")
      .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join(" ");

  const validateForm = () => {
    const newCategoryName = nameRef.current.value,
      newCategoryBackgroundImage = imageRef.current.value;

    const _errors = [];

    if (
      showEditName &&
      (!newCategoryName ||
        (typeof newCategoryName === "string" &&
          newCategoryName.trim().length === 0))
    )
      _errors.push(`${categoryLevel} name not provided`);

    if (showEditImage && !newCategoryBackgroundImage)
      _errors.push(`${categoryLevel} background image not provided`);

    if (showEditName) {
      if (categoryLevel === "main category") {
        const alreadyPresent = mainCategories.some((mainCategory) => {
          return (
            mainCategory.name.toLowerCase() ===
            newCategoryName.trim().toLowerCase()
          );
        });

        if (alreadyPresent)
          _errors.push(`${categoryLevel} with same name already exists`);
      } else if (categoryLevel === "sub category") {
        const mainCategory = mainCategories.find(
          (mainCategory) => mainCategory.id === categoryId["mainCategory"]
        );

        const alreadyPresent = mainCategory.builtSubCategories.some(
          (subCategory) => {
            return (
              subCategory.name.toLowerCase() ===
              newCategoryName.trim().toLowerCase()
            );
          }
        );

        if (alreadyPresent)
          _errors.push(`${categoryLevel} with same name already exists`);
      } else if (categoryLevel === "category") {
        const mainCategory = mainCategories.find(
          (mainCategory) => mainCategory.id === categoryId["mainCategory"]
        );

        const subCategory = mainCategory.builtSubCategories.find(
          (subCategory) => subCategory.id === categoryId["subCategory"]
        );

        const alreadyPresent = subCategory.builtCategories.some((category) => {
          return (
            category.name.toLowerCase() === newCategoryName.trim().toLowerCase()
          );
        });

        if (alreadyPresent)
          _errors.push(`${categoryLevel} with same name already exists`);
      }
    }

    return _errors;
  };

  const handleDeleteSubmit = async () => {
    setLoading(true);

    const form = {
      categoryLevel: categoryLevel,
      categoryId: JSON.stringify(categoryId),
      categoryImageSrc: categoryImageSrc,
    };

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND}/api/admin/delete-category`,
        form,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        const { dispatch } = store;
        switch (categoryLevel) {
          case "main category":
            dispatch(fetchData("mainCategories"));
            break;
          case "sub category":
            dispatch(fetchData("subCategories"));
            break;
          case "category":
            dispatch(fetchData("categories"));
            break;
          default:
            dispatch(fetchData("mainCategories"));
            dispatch(fetchData("subCategories"));
            dispatch(fetchData("categories"));
        }

        resetForm();
        setModalOpen(false);
      })
      .catch((err) => {
        console.error(err);
        setErrors(["form upload failed, please try again later"]);
        setLoading(false);
      });
  };

  const handleEditSubmit = async () => {
    if (!showEditName && !showEditImage) return;

    setErrors([]);
    const _errors = validateForm();
    if (_errors.length > 0) {
      setErrors(_errors);
      return;
    }

    setLoading(true);

    const newCategoryName = showEditName && cleanName(nameRef.current.value),
      newCategoryBackgroundImage = showEditImage && imageRef.current.files[0];

    const form = new FormData();
    if (showEditName) form.append("newCategoryName", newCategoryName);
    if (showEditImage) {
      form.append("newCategoryBackgroundImage", newCategoryBackgroundImage);
      form.append("oldCategoryBackgroundImage", categoryImageSrc);
    }
    form.append("categoryLevel", categoryLevel);
    form.append("categoryId", JSON.stringify(categoryId));
    form.append(
      "edit",
      showEditName && showEditImage ? "all" : showEditName ? "name" : "image"
    );

    await axios
      .post(`${process.env.REACT_APP_BACKEND}/api/admin/edit-category`, form, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const { dispatch } = store;
        switch (categoryLevel) {
          case "main category":
            dispatch(fetchData("mainCategories"));
            break;
          case "sub category":
            dispatch(fetchData("subCategories"));
            break;
          case "category":
            dispatch(fetchData("categories"));
            break;
          default:
            dispatch(fetchData("mainCategories"));
            dispatch(fetchData("subCategories"));
            dispatch(fetchData("categories"));
        }

        resetForm();
        setModalOpen(false);
      })
      .catch((err) => {
        console.error(err);
        setErrors(["form upload failed, please try again later"]);
        setLoading(false);
      });
  };

  const [showEditName, setShowEditName] = useState(false);
  const [showEditImage, setShowEditImage] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal open={modalOpen} onClose={onCloseModal} center>
      <div>
        <h4
          style={{ textTransform: "capitalize" }}
        >{`${categoryLevel} details`}</h4>
        <hr />
        {categoryTree && (
          <>
            <p style={{ textTransform: "uppercase" }}>{categoryTree}</p>
            <hr />
          </>
        )}

        {errors.length > 0
          ? errors.map((error, idx) => (
            <p
              key={idx}
              style={{ color: "red", textTransform: "capitalize" }}
            >
              {error}
            </p>
          ))
          : null}

        {modalMode === "edit" ? (
          <>
            <p
              style={{
                display: showEditName ? "none" : "block",
                fontSize: "1.2em",
                textTransform: "capitalize",
                color: "#222222",
              }}
            >
              Name:{"  "}
              <span
                style={{
                  color: "#ff4c3b",
                  textTransform: "uppercase",
                  fontSize: ".9em",
                }}
              >
                {categoryName}
              </span>
              <i
                className="fa fa-pencil"
                aria-hidden="true"
                title="Edit"
                style={{
                  marginLeft: "2ch",
                  color: "#ff4c3b",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowEditName(true);
                }}
              />
            </p>

            <div
              style={{
                display: showEditImage ? "none" : "block",
              }}
            >
              <p
                style={{
                  fontSize: "1.2em",
                  textTransform: "capitalize",
                  color: "#222222",
                }}
              >
                background image
                <i
                  className="fa fa-pencil"
                  aria-hidden="true"
                  title="Edit"
                  style={{
                    marginLeft: "2ch",
                    color: "#ff4c3b",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowEditImage(true);
                  }}
                />
              </p>
              <Banner imageSrc={categoryImageSrc} />
            </div>

            {showEditName && showEditImage ? null : showEditName ||
              showEditImage ? (
              <hr />
            ) : null}

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleEditSubmit();
              }}
            >
              <div
                className="form-group"
                style={{
                  display: showEditName ? "block" : "none",
                }}
              >
                <label
                  htmlFor="category-name"
                  style={{ fontSize: "1.2em", textTransform: "capitalize" }}
                >
                  {`new ${categoryLevel} name`}

                  <i
                    className="fa fa-trash"
                    aria-hidden="true"
                    title="Delete"
                    style={{
                      marginLeft: "2.5ch",
                      color: "#ff4c3b",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowEditName(false);
                      nameRef.current.value = "";
                      setErrors([]);
                    }}
                  />
                </label>
                <input
                  type="text"
                  name="category-name"
                  className="form-control"
                  placeholder={`Enter new ${categoryLevel} name`}
                  ref={nameRef}
                />
              </div>

              <div
                className="form-group"
                style={{ display: showEditImage ? "block" : "none" }}
              >
                <label
                  htmlFor="category-background-image"
                  style={{ fontSize: "1.2em", textTransform: "capitalize" }}
                >
                  {`new ${categoryLevel} background picture`}
                  <i
                    className="fa fa-trash"
                    aria-hidden="true"
                    title="Delete"
                    style={{
                      marginLeft: "2.5ch",
                      color: "#ff4c3b",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowEditImage(false);
                      imageRef.current.value = "";
                      setErrors([]);
                      setImagePreview("");
                    }}
                  />
                </label>
                <p>
                  <small>
                    <span>&#9432;</span> Dimensions: 672x310
                  </small>
                </p>
                <input
                  type="file"
                  name="category-background-image"
                  className="form-control"
                  aria-label="category background image"
                  ref={imageRef}
                  onChange={handleImageUpload}
                />
                {imagePreview && <Banner imageSrc={imagePreview} />}
              </div>

              <div
                style={{
                  display: showEditName || showEditImage ? "flex" : "none",
                  columnGap: "1em",
                  alignItems: "center",
                }}
              >
                <button
                  type="button"
                  className="btn btn-solid"
                  style={{ padding: "6px 12px" }}
                  onClick={handleEditSubmit}
                  disabled={loading}
                >
                  submit
                </button>

                {loading && <div className="minkmall-spinner-loader" />}
              </div>
            </form>
          </>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleDeleteSubmit();
            }}
          >
            <input type="input" ref={nameRef} style={{ display: "none" }} />
            <input type="file" ref={imageRef} style={{ display: "none" }} />
            <p
              style={{
                fontSize: "1.2em",
                textTransform: "capitalize",
                color: "#222222",
              }}
            >
              Delete{" "}
              <span
                style={{
                  color: "#ff4c3b",
                  textTransform: "uppercase",
                  fontSize: ".9em",
                }}
              >
                {categoryName}
              </span>
              {" ?"}
            </p>

            <div
              style={{
                display: "flex",
                columnGap: "1em",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                className="btn btn-solid"
                style={{ padding: "6px 12px" }}
                onClick={handleDeleteSubmit}
                disabled={loading}
              >
                submit
              </button>

              {loading && <div className="minkmall-spinner-loader" />}
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
}

function EditCategories(props) {
  const { user } = useContext(AuthContext);

  const { mainCategories } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [categoryLevel, setCategoryLevel] = useState("category");
  const [categoryTree, setCategoryTree] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [modalMode, setModalMode] = useState("edit");
  const [categoryName, setCategoryName] = useState("");
  const [categoryImageSrc, setCategoryImageSrc] = useState("");

  return !(user && (user.is_admin || user.role === "ADMINISTRATOR")) ? (
    <Unauthorized />
  ) : (
    <div>
      <Helmet>
        <title>{`Minkall - Admin: Edit Categories`}</title>
      </Helmet>

      <Breadcrumb title="edit categories" parent="admin" />

      <section className="section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p style={{ color: "#222222" }}>
                Use the buttons in the drop down below to edit or delete a
                category in the different levels (main category, sub category or
                category)
              </p>
            </div>
          </div>

          <div className="row">
            <CategoryModalContext.Provider
              value={{
                modalOpen,
                setModalOpen,
                categoryLevel,
                setCategoryLevel,
                categoryTree,
                setCategoryTree,
                categoryId,
                setCategoryId,
                modalMode,
                setModalMode,
                categoryName,
                setCategoryName,
                categoryImageSrc,
                setCategoryImageSrc,
              }}
            >
              <CategoryModal mainCategories={mainCategories} />
              <CategoriesSelector mainCategories={mainCategories} />
            </CategoryModalContext.Provider>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  mainCategories: buildMainCategoriesTrees(
    state.data.mainCategories,
    state.data.subCategories,
    state.data.categories
  ),
});

export default connect(mapStateToProps)(EditCategories);
