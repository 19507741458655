import React, { useContext, useState, useEffect } from "react";
import { getAllStores, sendEmail } from "../../api/handleData";
import { Link, useParams, useHistory } from "react-router-dom";
import { Field, Form, Formik, ErrorMessage, useField } from "formik";
import axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
import Thumb from "./components/Thumb";
import { error } from "../../api/toast";
import { AuthContext } from "../contexts";

const EditUsrAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState("");
  const { user, token } = useContext(AuthContext);

  const navigate = useHistory();
  const { id } = useParams();

  //if(!getTheUser.is_admin) var user = getTheUser.filter(us=>us.id === user.id)
  // if (!user.is_admin && user.id !== user.id) {
  //   error("Sorry you don't have rights to view this page")
  // }

  //submit the form
  const submitForm = async (form) => {
    //set loader
    setLoading(true);
    //send to backend
    await axios
      .put(`${process.env.REACT_APP_BACKEND}/users/${id}`, form, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data == "User Updated") {
          sendEmail(
            user.first_name,
            user.phone,
            "User update request",
            "If you did not authorise this, please login to your account to correct.."
          );
          swal("Success", "User updated Successfully", "success");
        } else {
          swal("Error", "Oops an error occured updating the user", "error");
        }
        //console.log("EDIT SELL RES:", res)
      })
      .catch((err) => {
        console.log("EDIT USR ERR:", err);
      });
    //update the user too
    // await axios.put(`${process.env.REACT_APP_BACKEND}/users/${user.id}`, usr, { headers: { Authorization: `Bearer ${token}` } })
    //   .then(res => {
    //     console.log("RESULTTT:", res)
    //   })
    //   .catch(err => {
    //     console.log("ERROR EDIT:", err)
    //   })
    //console.log("EDIT FORM:", form);

    setLoading(false);
  };

  const deleteUser = async () => {
    swal({
      title: "Confirmation",
      text: "Are you sure? This action is permanent",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let del = await axios.delete(
          `${process.env.REACT_APP_BACKEND}/users/${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (del.data == "DEL") {
          swal("Success", "Deleted Successfully", "success");
        }
        navigate.push("/admin/dashboard");
      } else {
        console.log("Cancelled");
      }
    });
  };

  return user ? (
    <section className="register-page section-b-space">
      <div className="container">
        <Link to="/admin/dashboard">
          <i className="icon-back-left" /> Back to Dashboard
        </Link>
        <div className="row">
          <div className="col-lg-12">
            <div className="md-20" />
            <h3>Edit User Account Details</h3>
            <button
              onClick={() => deleteUser()}
              className="btn btn-solid"
              type="submit"
            >
              Delete This User
            </button>
            <div className="theme-card">
              <Formik
                initialValues={{
                  profile_image: user.profile_image,
                  first_name: user.first_name,
                  last_name: user.last_name,
                  phone: user.phone,
                  email: user.email,
                  is_supplier: user.is_supplier,
                  company: user.company,
                  is_admin: user.is_admin,
                }}
                onSubmit={submitForm}
                validateOnChange
              >
                {({ values }) => (
                  <Form className="theme-form">
                    <div className="col-md-6">
                      <label htmlFor="business_name">First Name</label>
                      <ErrorMessage
                        className="validator"
                        name="first_name"
                        component="div"
                      />
                      <Field
                        type="text"
                        className="form-control"
                        id="first_name"
                        name="first_name"
                      />
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="review">Last Name</label>
                      <ErrorMessage
                        className="validator"
                        name="last_name"
                        component="div"
                      />
                      <Field
                        type="text"
                        className="form-control"
                        id="last_name"
                        name="last_name"
                      />
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="email">Phone Number</label>
                        <ErrorMessage
                          className="validator"
                          name="phone"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="phone"
                          name="phone"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="review">Email</label>
                        <ErrorMessage
                          className="validator"
                          name="email"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="review">Company</label>
                        <ErrorMessage
                          className="validator"
                          name="company"
                          component="div"
                        />
                        <Field
                          type="text"
                          className="form-control"
                          id="company"
                          name="company"
                        />
                      </div>
                      <div className="col-md-6">
                        <h4>Set User As Admin </h4>
                        <Field type="checkbox" id="is_admin" name="is_admin" />
                        <label htmlFor="review">{`${
                          values.is_admin ? "ADMIN" : "NOT ADMIN"
                        }`}</label>
                      </div>
                      <div className="col-md-6">
                        <h4>Set User As Supplier </h4>
                        <Field
                          type="checkbox"
                          id="is_supplier"
                          name="is_supplier"
                        />
                        <label htmlFor="review">{`${
                          values.is_supplier ? "SUPPLIER" : "NOT SUPPLIER"
                        }`}</label>
                      </div>
                      <div className="col-md-6">
                        <h4>Your Profile Image </h4>
                        <Thumb link={values.profile_image} />
                      </div>
                      <div>
                        {loading ? (
                          <h3>Submitting...</h3>
                        ) : (
                          <button className="btn btn-solid" type="submit">
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div>
      <h3>Sorry you don't have permission to view this page</h3>
    </div>
  );
};

export default EditUsrAdmin;
