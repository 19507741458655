import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getAllUsers } from "../../api/handleData";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../contexts";

const users = () => {
  const [users, setUsers] = useState("");
  const [selectedRows, setSelectedRows] = useState(false);
  const navigate = useHistory();
  const { user: login } = useContext(AuthContext);

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const columns = [
    {
      name: "First Name",
      selector: (row) => row.first_name,
    },
    {
      name: "Last Name",
      selector: (row) => row.last_name,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Role",
      selector: (row) =>
        row.is_admin ? "ADMIN" : row.is_supplier ? "SUPPLIER" : "USER",
    },
    {
      name: "Company",
      selector: (row) => row.company,
    },
  ];

  if (selectedRows.length === 1) {
    swal({
      title: "Confirmation",
      text: "Do you wish to edit this user?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willEdit) => {
      if (willEdit) {
        const usrID = Object.assign({}, selectedRows);
        const theID = usrID[0].id.toString();
        navigate.push(
          login.is_admin ? `/user/edit/${theID}` : `/users/${theID}`
        );
      } else {
        console.log("Cancelled");
      }
    });
  }

  useEffect(() => {
    getAllUsers(setUsers);
  }, []);
  console.log("THE THE USER:", login);

  return login.is_admin ? (
    <div>
      <DataTable
        direction="auto"
        columns={columns}
        data={users}
        onSelectedRowsChange={handleChange}
        fixedHeaderScrollHeight="300px"
        pagination
        selectableRows
        responsive
        subHeaderAlign="right"
        subHeaderWrap
      />
      <h3>Total Users: {users.length}</h3>
    </div>
  ) : (
    <div>
      <h4>Sorry, you must be logged in as an admin to view this..</h4>
    </div>
  );
};

export default users;
