import React from "react";
import { fuzzySearch } from "../utils/search";
import { buildSubCategoryLink, buildCategoryLink } from "../utils/extract";
import EmptyResult from "./emptyResult";
import CategoryBanner from "./categoryBanner";
import ProductItem from "./productItem";
import SellerItem from "./sellerItem";

export default function SearchResults(props) {
  const { searchQuery, data } = props;

  if (searchQuery === "") return <EmptyResult showBackToHomeButton />;

  const resultsArray = fuzzySearch(searchQuery, data);
  if (resultsArray.length === 0) return <EmptyResult showBackToHomeButton />;

  return (
    <div
      className="row"
      style={{
        rowGap: "2em",
        alignItems: "center",
      }}
    >
      {resultsArray.map((result, idx) => {
        const { item, type } = result;

        let link,
          name = item.name;

        switch (type) {
          case "mainCategory":
            link = `/c/${encodeURIComponent(item.name)}`;
            break;

          case "subCategory":
            link = buildSubCategoryLink(
              item.id,
              data.subCategories,
              data.mainCategories
            );

            name = link
              .split("/")
              .slice(2)
              .reduce((acc, curr) => {
                acc.push(decodeURIComponent(curr));
                return acc;
              }, [])
              .join(" / ");
            break;

          case "category":
            link = buildCategoryLink(
              item.id,
              data.categories,
              data.subCategories,
              data.mainCategories
            );

            name = link
              .split("/")
              .slice(2)
              .reduce((acc, curr) => {
                acc.push(decodeURIComponent(curr));
                return acc;
              }, [])
              .join(" / ");
            break;

          case "seller":
            link = `/sellers/${item.id}`;
            name = `sellers / ${item.name}`;
            break;

          case "product":
            link = `/sellers/${item.seller}`;
            name = `products / ${item.name}`;
            break;

          default:
            link = "";
        }

        return (
          <React.Fragment key={idx}>
            {type === "seller" ? (
              <div className="col-xl-2 col-md-4 col-sm-6">
                <SellerItem seller={{ ...item, name }} search />
              </div>
            ) : type === "product" ? (
              <div className="col-xl-2 col-md-4 col-sm-6">
                <ProductItem product={{ ...item, name }} search />
              </div>
            ) : (
              <div className="col-md-4">
                <CategoryBanner category={{ ...item, link, name }} search />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
