import React, { useState, useContext } from "react";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import "./categoriesModal.css";
import { CategoriesModalContext } from "../contexts";

function SubCategoryTree({ subCategory }) {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((prevShow) => !prevShow);
  };

  return (
    <li>
      <h3
        onClick={toggleShow}
        className="categoriesModalSubCategoryName"
        style={{ cursor: "pointer", textTransform: "uppercase" }}
      >
        {subCategory.name}
        <i
          style={{ marginLeft: "1ch" }}
          className={`fa fa-caret-${show ? "up" : "down"}`}
        ></i>
      </h3>
      <ul style={{ display: show ? "flex" : "none", flexDirection: "column" }}>
        {subCategory.builtCategories.map((category, idx2) => (
          <li
            key={idx2}
            style={{ textTransform: "capitalize", paddingLeft: "2ch" }}
          >
            <h4>
              <Link
                className="categoriesModalCategoryName"
                to={category.link}
                style={{ color: "#222222", fontSize: "1em" }}
              >
                {category.name}
              </Link>
            </h4>
          </li>
        ))}
      </ul>
    </li>
  );
}

export default function CategoriesModal() {
  const modalSettings = useContext(CategoriesModalContext);

  const onCloseModal = () => {
    modalSettings.setCategoriesModalOpen(false);
  };

  if (!modalSettings.categoryName || !modalSettings.categoryTree) return null;

  return (
    <Modal
      open={modalSettings.categoriesModalOpen}
      onClose={onCloseModal}
      center
    >
      <div style={{ minWidth: "60vw" }}>
        <h2>{modalSettings.categoryName}</h2>
        <hr />
        <ul style={{ display: "flex", flexDirection: "column" }}>
          {modalSettings.categoryTree.builtSubCategories.map(
            (subCategory, idx) => (
              <SubCategoryTree subCategory={subCategory} key={idx} />
            )
          )}
        </ul>
      </div>
    </Modal>
  );
}
