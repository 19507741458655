import React, { useState } from "react";
import Hero from "../components/hero";
import CategoryBanners from "../components/categoryBanners";
import SectionTitle from "../components/sectionTitle";
import { connect } from "react-redux";
import ListedProducts from "../components/listedProducts";
import { Helmet } from "react-helmet";
import { buildMainCategoriesLinks } from "../utils/extract";
import ListedSellers from "../components/listedSellers";
import { CategoriesModalContext } from "../contexts";
import CategoriesModal from "../components/categoriesModal";

function MainCategories(props) {
  const { mainCategories } = props;

  const [categoriesModalOpen, setCategoriesModalOpen] = useState(false);
  const [categoryName, setCategoryName] = useState(null);
  const [categoryTree, setCategoryTree] = useState(null);

  const categoriesModalContextValue = {
    categoriesModalOpen,
    setCategoriesModalOpen,
    categoryName,
    setCategoryName,
    categoryTree,
    setCategoryTree,
  };

  return (
    <CategoriesModalContext.Provider value={categoriesModalContextValue}>
      <CategoryBanners
        bannersData={mainCategories}
        home={false}
        increaseBy={12}
      />
      <CategoriesModal />
    </CategoriesModalContext.Provider>
  );
}

function Home(props) {
  const { mainCategories } = props;

  return (
    <>
      <Helmet>
        <title>
          Minkmall - Online Business Directory For Exclusively Made-In-Kenya
          Brands
        </title>
        <meta
          name="description"
          content="Mink Mall Is An Online Business Directory For Exclusively Made-In-Kenya Brands. It Is A Platform Where Buyers Locally And Abroad Discover Unique And Authentic Kenyan-Made Products. We offer a free and uniquely designed business listing service for Kenyan-made businesses/brands. The company only has a founder, who is the concept designer. The Kenyan market is saturated with imported goods while we have quality made-in-Kenya products, and only a few are known to the locals. Mink Mall firmly believes that by creating a listing of all made-in-Kenya brands, we will achieve the big four agenda, Buy Kenya - Build Kenya."
        />
      </Helmet>
      <Hero />

      <section className="banner-furniture ratio_45 section-b-space">
        <SectionTitle
          title="Categories"
          subtitle="Pick a category and get started!"
        />

        <MainCategories mainCategories={mainCategories} />
      </section>

      <section
        className="section-b-space"
        style={{
          paddingLeft: "2em ",
          paddingRight: "2em ",
          paddingTop: "1em",
        }}
      >
        <SectionTitle
          title="Featured Products"
          subtitle="A few specially selected products..."
        />

        <div className="container">
          <ListedProducts featured increaseBy={12} />
        </div>
      </section>

      <section
        className="section-b-space"
        style={{
          paddingLeft: "2em ",
          paddingRight: "2em ",
          paddingTop: "1em",
        }}
      >
        <SectionTitle
          title="Featured Brands"
          subtitle="Some brands we'd like to bring your attention to..."
        />

        <ListedSellers increaseBy={12} />
      </section>
    </>
  );
}

const mapStateToProps = (state) => ({
  mainCategories: buildMainCategoriesLinks(state.data.mainCategories),
});

export default connect(mapStateToProps)(Home);
