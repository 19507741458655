import React, { useState, createContext, useContext } from "react";
import { connect } from "react-redux";
import { buildMainCategoriesTrees } from "../utils/extract";
import "./categoriesSelector.css";

const SelectorContext = createContext(null);

function MainCategoryDropDown({ mainCategory, cnt }) {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((prevShow) => !prevShow);
  };

  return (
    <li style={cnt === 0 ? { marginTop: "0.5em" } : null}>
      <span className="categoryDropDown" onClick={toggleShow}>
        {mainCategory.name}
        <i
          style={{ paddingLeft: "1ch" }}
          className={`fa fa-caret-${show ? "up" : "down"}`}
          aria-hidden="true"
        ></i>
      </span>
      <ul
        style={{
          marginLeft: "2ch",
          display: show ? "flex" : "none",
          flexDirection: "column",
          gap: "0.5em",
        }}
      >
        {mainCategory.builtSubCategories.map((subCategory, idx) => (
          <SubCategoryDropDown
            key={idx}
            subCategory={subCategory}
            mainCategory={mainCategory}
            cnt={idx}
          />
        ))}
      </ul>
    </li>
  );
}

function SubCategoryDropDown({ subCategory, mainCategory, cnt }) {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((prevShow) => !prevShow);
  };

  return (
    <li style={cnt === 0 ? { marginTop: "0.5em" } : null}>
      <span className="categoryDropDown" onClick={toggleShow}>
        {subCategory.name}
        <i
          style={{ paddingLeft: "1ch" }}
          className={`fa fa-caret-${show ? "up" : "down"}`}
          aria-hidden="true"
        ></i>
      </span>
      <ul
        style={{
          marginLeft: "2ch",
          display: show ? "flex" : "none",
          flexDirection: "column",
          gap: "0.5em",
        }}
      >
        {subCategory.builtCategories.map((category, idx) => (
          <li key={idx} style={idx === 0 ? { marginTop: "0.5em" } : null}>
            <CategoryCheck
              category={category}
              subCategory={subCategory}
              mainCategory={mainCategory}
            />
          </li>
        ))}
      </ul>
    </li>
  );
}

function CategoryCheck({ category, subCategory, mainCategory }) {
  const [categoryChecked, setCategoryChecked] = useState(false);
  const { setSelected, setSelectedIds } = useContext(SelectorContext);

  const handleCategoryCheck = (event) => {
    const categoryName = event.target.name,
      categoryId = event.target.value;

    setCategoryChecked((prevCheck) => {
      // const selectedCategory = `${mainCategory.name}/ ${subCategory.name} / ${categoryName}`,
      //   selectedCategoryIds = `${mainCategory.id}/ ${subCategory.id}/${categoryId}`;
      const selectedCategory = `${mainCategory.name}/ ${subCategory.name} / ${categoryName}`,
        selectedCategoryIds = `${mainCategory.id}/ ${subCategory.id}/${categoryId}`;

      setSelected((prevSelected) => {
        const newSelected = new Set(prevSelected);

        if (!prevCheck) {
          newSelected.add(selectedCategory);
        } else {
          newSelected.delete(selectedCategory);
        }

        return [...newSelected.values()];
      });

      setSelectedIds((prevSelectedIds) => {
        const redPrevSelectedIds = prevSelectedIds.reduce(
          (accumSel, currSel) => {
            const selection = Object.values(currSel).join("/");
            accumSel.push(selection);
            return accumSel;
          },
          []
        );

        const newSelectedIds = new Set(redPrevSelectedIds);

        if (!prevCheck) {
          newSelectedIds.add(selectedCategoryIds);
        } else {
          newSelectedIds.delete(selectedCategoryIds);
        }

        return [...newSelectedIds.values()].reduce((accumSel, currSel) => {
          const splitSel = currSel.split("/");

          accumSel.push({
            mainCategory: splitSel[0],
            subCategory: splitSel[1],
            category: splitSel[2],
          });
          return accumSel;
        }, []);
      });

      return !prevCheck;
    });
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input
        className="categoriesSelectorCheckbox"
        style={{ cursor: "pointer", marginRight: "1ch" }}
        type="checkbox"
        checked={categoryChecked}
        onChange={handleCategoryCheck}
        name={category.name}
        value={category.id}
      />
      <span style={{ lineHeight: "18px" }}>{category.name}</span>
    </div>
  );
}

function SelectorLabelAndInfo() {
  const { selected } = useContext(SelectorContext);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
      {!selected.length && (
        <span style={{ color: "red" }}>
          You must select at least one category
        </span>
      )}
      <span style={{ fontWeight: "bold" }}>
        Please use the drop-downs below to select the category/categories of the
        items you sell:
      </span>
      <div
        style={{ border: "1px solid rgba(0,0,0, 0.2)", padding: "1em 1em 0" }}
      >
        <small>Selected Categories:</small>
        <div style={{ padding: "1em 1em 0" }}>
          {selected.length ? (
            selected.map((selection, idx) => <p key={idx}>{selection}</p>)
          ) : (
            <p>NONE</p>
          )}
        </div>
      </div>
    </div>
  );
}

function CategoriesSelector(props) {
  const { mainCategories, getSelectedIds, getSelected } = props;
  console.log("NYEPES SELECTOR:", props)

  const [selected, setSelected] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  if (getSelectedIds) getSelectedIds(selectedIds);
  if (getSelected) getSelected(selected);

  const selectorContextValue = {
    selected,
    setSelected,
    selectedIds,
    setSelectedIds,
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <hr />
          <SelectorContext.Provider value={selectorContextValue}>
            <SelectorLabelAndInfo />
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5em",
                padding: "1em 1em 0",
              }}
            >
              {mainCategories.map((mainCategory, idx) => (
                <MainCategoryDropDown
                  key={idx}
                  mainCategory={mainCategory}
                  cnt={idx}
                />
              ))}
            </ul>
          </SelectorContext.Provider>
          <hr />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  mainCategories: buildMainCategoriesTrees(
    state.data.mainCategories,
    state.data.subCategories,
    state.data.categories
  ),
});

export default connect(mapStateToProps)(CategoriesSelector);
