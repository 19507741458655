import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../../api/handleData";
import "./topBar.css";
import { AuthContext } from "../contexts";

export default function TopBar() {
  const { user, setUser, setToken } = useContext(AuthContext);
  const navigate = useHistory();

  return (
    <div
      style={{ backgroundColor: "#333" }}
      className="top-header top-header-dark3"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="header-contact">
              <ul>
                <li style={{ color: "white" }}>Welcome to Minkmall</li>
                <li style={{ color: "white" }}>
                  <i
                    className="fa fa-envelope"
                    aria-hidden="true"
                    style={{ color: "white" }}
                  />
                  Email us:{" "}
                  <a className="topBar-email" href="mailto:info@minkmall.co.ke">info@minkmall.co.ke</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 text-right">
            <ul className="header-dropdown">
              <li className="onhover-dropdown mobile-account">
                {user ? (
                  <div style={{ color: "white" }}>
                    {`Welcome ${user.first_name}`}
                    <i
                      style={{ paddingLeft: "1ch" }}
                      className="fa fa-caret-down"
                      aria-hidden="true"
                    ></i>

                    <ul className="onhover-show-div">
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/admin/dashboard`}
                          data-lng="en"
                        >
                          Dashboard
                        </Link>
                      </li>
                      {(user.is_admin || user.role === "ADMINISTRATOR") && (
                        <>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/admin/add-category`}
                            >
                              Add Category
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/admin/edit-categories`}
                            >
                              Edit Categories
                            </Link>
                          </li>
                        </>
                      )}
                      <li
                        style={{ color: "#222222" }}
                        onClick={() => logout(navigate, setUser, setToken)}
                      >
                        Logout
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div style={{ color: "white" }}>
                    Login or Register Account
                    <i
                      style={{ paddingLeft: "1ch" }}
                      className="fa fa-caret-down"
                      aria-hidden="true"
                    ></i>
                    <ul className="onhover-show-div">
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/pages/login`}
                          data-lng="en"
                        >
                          Login
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/pages/register`}
                          data-lng="en"
                        >
                          Register
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
