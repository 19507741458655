import React from 'react';
import Swal from 'sweetalert2';
import ReactDOMServer from 'react-dom/server';

export const uploadFile = async (state, title) => {
  const { value: file } = await Swal.fire({
    title: title ? title : "Select profile Image",
    input: 'file',
    inputAttributes: {
      'accept': 'image/*',
      'aria-label': 'Upload your profile picture'
    }
  })

  if (file) {
    const reader = new FileReader()

    reader.onload = (e) => {
      Swal.fire({
        title: title,
        imageUrl: e.target.result,
        imageAlt: 'profile_image'
      })
    }
    reader.readAsDataURL(file)
    state(file)
  }
}

export const uploadGallery = async (state) => {
  const { value: file } = await Swal.fire({
    title: 'Select image Gallery (Max 5)',
    input: 'file',
    inputAttributes: {
      'accept': 'image/*',
      'aria-label': 'Upload funeral gallery',
      'multiple': 'multiple'
    }
  })

  if (file) {
    if (file.length > 5) {
      Swal.fire("Error", "Only 5 images allowed", "error")
    } else {
      state(file)
    }

  }

}

export const uploadVideo = async (state) => {
  const { value: file } = await Swal.fire({
    title: 'Select Short Video',
    input: 'file',
    inputAttributes: {
      'accept': 'video/*',
      'aria-label': 'Upload a short video'
    }
  })

  if (file) {
    // const reader = new FileReader()

    // reader.onload = (e) => {
    //   Swal.fire({
    //     title: 'Your uploaded picture',
    //     imageUrl: e.target.result,
    //     imageAlt: 'The uploaded picture'
    //   })
    // }
    // reader.readAsDataURL(file)
    state(file)
  }
}
export const uploadProgram = async (state) => {
  const { value: file } = await Swal.fire({
    title: 'Select funeral program',
    input: 'file',
    inputAttributes: {
      'accept': 'image/*',
      'aria-label': 'Upload the program'
    }
  })

  if (file) {
    const reader = new FileReader()

    reader.onload = (e) => {
      Swal.fire({
        title: 'Program',
        imageUrl: e.target.result,
        imageAlt: 'program'
      })
    }
    reader.readAsDataURL(file)
    state(file)
  }
}