import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { getAllStores, logout } from "../../../api/handleData";
import Apply from "../../pages/Apply";
import Tab from "../components/Tab";
import TabPanel from "../components/TabsPanel";
import { AuthContext } from "../../contexts";

const Customer = () => {
  const [stores, setStores] = useState("");

  const { user, setUser, setToken } = useContext(AuthContext);
  const navigate = useHistory();
  //get all the shops
  //getAllStores(setStores)

  let sortedStores = stores && stores.filter((st) => st.posted_by == user.id);

  useEffect(() => {
    getAllStores(setStores);
  }, []);

  return (
    <TabPanel>
      <Tab title="Account Info">
        <div>
          <div className="dashboard-right">
            <div className="dashboard">
              <div className="page-title">
                <h2>My Dashboard</h2>
              </div>
              <div className="welcome-msg">
                <h4>Hello, {user ? user.first_name : "Loading.."}</h4>
                <h6>
                  From your My Account Dashboard you have the ability to view a
                  snapshot of your recent account activity and update your
                  account information. Select a link below to view or edit
                  information.
                </h6>
              </div>
              <div className="box-account box-info">
                <div className="box-head">
                  <h2>Account Information</h2>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="box">
                      <div className="box-title">
                        <h3>Contact Information</h3>
                        {/* <Link to="#">Edit</Link> */}
                      </div>
                      <div className="box-content">
                        <h6>
                          {user
                            ? user.first_name + " " + user.last_name
                            : "Loading.."}
                        </h6>
                        <h6>{user ? user.email : "Loading.."}</h6>
                        <h6 className="validator">
                          {user ? user.phone : "Loading.."}
                        </h6>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                    <div className="box">
                      <div className="box-title">
                        <h3>Profile Picture</h3>
                        <Link to="#">Edit</Link>
                      </div>
                      <div className="box-content">
                        <img src={user ? user.profile_image : ""} />
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* <div>
                  <div className="box">
                    <div className="box-title">
                      <h3>Reset Password</h3>
                      <Link to="#">My Addresses</Link>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <h6>Change Your Password</h6>
                        <address>
                          <Link to="#">Edit Password</Link>
                        </address>
                      </div>
                      <div className="col-sm-6">
                        <h6>My Address</h6>
                        <address>
                          <br />
                          <Link to="#">Edit Address</Link>
                        </address>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Tab>
      <Tab
        title={
          sortedStores.length !== 0
            ? "Store Pending Approval"
            : "Apply For A Store"
        }
      >
        {sortedStores.length !== 0 ? (
          <div>
            <h4>Your Application is:</h4>
            <h5>{sortedStores.is_approved ? "APPROVED" : "PENDING"}</h5>
          </div>
        ) : (
          <div>
            <Apply />
          </div>
        )}
      </Tab>
      <Tab title="Logout">
        <button onClick={() => logout(navigate, setUser, setToken)}>
          Press to Logout
        </button>
      </Tab>
    </TabPanel>
  );
};

export default Customer;
