import React from "react";

export default function SectionTitle(props) {
  const { title, subtitle } = props;
  return (
    <div className="title1">
      <h4>{subtitle}</h4>
      <h2 className="title-inner1">{title}</h2>
    </div>
  );
}
